<template>
  <div class="fixed left-0 top-0 w-full h-full z-10 transition-all">
    <div
      @click="closeAlert()"
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
    ></div>
    <div
      class="
        absolute
        top-52
        left-2
        right-2
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div class="flex w-full justify-center py-4">
        <div
          class="
            flex
            items-center
            bg-gray-500
            justify-center
            h-16
            w-16
            rounded-full
          "
          v-if="alertType === 'error'"
        >
          <div class="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
            <!-- <svg
              class="h-8 w-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M505.403,406.394L295.389,58.102c-8.274-13.721-23.367-22.245-39.39-22.245c-16.023,0-31.116,8.524-39.391,22.246    L6.595,406.394c-8.551,14.182-8.804,31.95-0.661,46.37c8.145,14.42,23.491,23.378,40.051,23.378h420.028    c16.56,0,31.907-8.958,40.052-23.379C514.208,438.342,513.955,420.574,505.403,406.394z M477.039,436.372    c-2.242,3.969-6.467,6.436-11.026,6.436H45.985c-4.559,0-8.784-2.466-11.025-6.435c-2.242-3.97-2.172-8.862,0.181-12.765    L245.156,75.316c2.278-3.777,6.433-6.124,10.844-6.124c4.41,0,8.565,2.347,10.843,6.124l210.013,348.292    C479.211,427.512,479.281,432.403,477.039,436.372z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256.154,173.005c-12.68,0-22.576,6.804-22.576,18.866c0,36.802,4.329,89.686,4.329,126.489    c0.001,9.587,8.352,13.607,18.248,13.607c7.422,0,17.937-4.02,17.937-13.607c0-36.802,4.329-89.686,4.329-126.489    C278.421,179.81,268.216,173.005,256.154,173.005z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256.465,353.306c-13.607,0-23.814,10.824-23.814,23.814c0,12.68,10.206,23.814,23.814,23.814    c12.68,0,23.505-11.134,23.505-23.814C279.97,364.13,269.144,353.306,256.465,353.306z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg> -->
          </div>
        </div>
        <div
          class="
            flex
            items-center
            bg-yellow-300
            justify-center
            h-16
            w-16
            rounded-full
          "
          v-if="alertType === 'info'"
        >
          <div class="text-gray-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div
          class="
            flex
            items-center
            bg-green-100
            justify-center
            h-16
            w-16
            rounded-full
          "
          v-if="alertType === 'success'"
        >
          <div class="text-green-500">
            <svg fill="currentColor" viewBox="0 0 20 20" class="h-14 w-14">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        class="
          flex
          w-full
          justify-center
          font-montserrat
          text-2xl
          font-medium
          text-gray-600
          tracking-wide
        "
        v-if="alertType === 'success'"
      >
        Success!
      </div>

      <div class="font-montserrat text-xl text-gray-500 font-medium py-4 px-8">
        <div class="w-full flex justify-center px-5">
          <slot name="message"></slot>
        </div>
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  props: {
    alertType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore()
    const closeAlert = () => {
      store.dispatch('alerts/closeAlert')
    }
    return {
      closeAlert,
    }
  },
}
</script>
