<template>
  <AuthWrapper>
    <div class="block my-6 text-center">
      <HelasendSVG />
      <div class="font-montserrat text-2xl font-bold">Welcome!</div>
      <div class="text-sm text-gray-500 my-4">
        Don't have an account?
        <span @click="goToSignup()" class="text-blue-500 cursor-pointer">
          Sign up
        </span>
      </div>
    </div>

    <!-- form -->
    <form @submit.prevent="login()" class="block py-4">
      <div class="px-4 py-2 text-red-500 text-sm rounded-md">
        {{ getError() }}
      </div>
      <div class="border px-4 p-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">PHONE NUMBER</div>
        <div class="flex relative">
          <div class="pr-1">
            <CallingCode />
          </div>
          <div class="">
            <input
              type="tel"
              required
              v-model="phoneNumber"
              class="outline-none w-full appearance-none"
              placeholder="Phone number"
              autocomplete="false"
            />
          </div>
        </div>
      </div>

      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">PASSWORD</div>
        <div class="flex">
          <input
            required
            v-model="password"
            class="outline-none w-full"
            placeholder="••••••••"
            type="password"
            id="password"
            autocomplete="false"
          />
          <EyeSVG />
        </div>
      </div>

      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Login
      </button>

      <div
        @click="goToForgotPassword()"
        class="text-sm text-blue-500 py-4 inline-block mt-2 cursor-pointer"
      >
        Forgot password
      </div>
    </form>
  </AuthWrapper>
  <CustomAlert v-if="goToVerify" :alertType="'info'">
    <template v-slot:message>
      <div class="text-center">
        Activate your account.<br />
        OTP will be sent to your phone and email.
      </div>
    </template>
    <template v-slot:button>
      <div class="w-full flex justify-center my-4 gap-2">
        <AlertButton
          @click="setGoToVerifyFalse"
          class="flex-shrink bg-gray-200 text-gray-500"
          >Cancel</AlertButton
        >
        <AlertButton
          @click="resendCode()"
          class="flex-grow bg-accent text-gray-700"
          >Get OTP</AlertButton
        >
      </div>
    </template>
  </CustomAlert>
</template>
<script>
import { computed, onBeforeUnmount, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import CallingCode from '@/components/partials/CallingCode.vue'
import AuthWrapper from './AuthWrapper.vue'
import HelasendSVG from './HelasendSVG.vue'
import utils from '@/composables/utils'
import EyeSVG from '@/components/partials/EyeSVG.vue'
import CustomAlert from '@/components/partials/CustomAlert.vue'
import AlertButton from '@/components/partials/AlertButton.vue'

export default {
  components: {
    EyeSVG,
    CallingCode,
    AuthWrapper,
    HelasendSVG,
    CustomAlert,
    AlertButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const password = ref('')
    const phoneNumber = ref('')
    const token = computed(() => store.state.auth.token)
    const authenticated = computed(() => store.state.auth.authenticated)
    const error = computed(() => store.state.auth.error)
    const countryDetails = computed(() => store.state.countries.countryDetails)
    const goToVerify = computed(() => store.state.auth.goToVerify)
    const success = computed(() => store.state.auth.success)
    const verifyUserDetails = computed(() => store.state.auth.verifyUserDetails)
    const { setRequestConfig } = utils()

    if (authenticated.value) {
      store.dispatch('auth/logout')
    }

    store.dispatch('auth/fetchToken')
    const fetchExchangeRates = async () => {
      await store.dispatch('auth/fetchToken')
      const payload = {
        token: token.value,
      }
      store.dispatch('countries/fetchExchangeRates', payload)
    }
    fetchExchangeRates()

    const login = async () => {
      const phone = `+${countryDetails.value.prefix}${phoneNumber.value}`
      const payload = {
        token: token.value,
        password: password.value,
        phone,
      }
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/login', payload)
      if (authenticated.value) {
        router.push({ name: 'home' })
      }
    }

    const resendCode = async () => {
      const userID = verifyUserDetails.value.userID
      const body = {
        userID,
      }
      const config = setRequestConfig(token.value, null)
      const payload = {
        body,
        config,
      }
      store.dispatch('auth/setGoToVerifyFalse')
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/resendCode', payload)
      if (success.value !== '') {
        if (success.value.component === 'resendCode') {
          router.push({ name: 'verify' })
        }
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'login') {
          return error.value.message
        }
      }
    }

    const getVerifyError = () => {
      if (error.value !== '') {
        if (error.value.component === 'verify') {
          return error.value.data.message
        }
      }
    }

    const goToSignup = () => {
      store.dispatch('countries/closeCountryCodesDropDownMenu')
      router.push({ name: 'signup' })
    }

    const goToForgotPassword = () => {
      router.push({ name: 'otp' })
    }

    onBeforeUnmount(() => {
      store.dispatch('auth/setError', '')
      store.dispatch('auth/setSuccess', '')
    })

    const setGoToVerifyFalse = () => {
      store.dispatch('auth/setGoToVerifyFalse')
    }

    //TODO check the use of this!
    store.dispatch('paymentInfo/unsetCVC')

    return {
      login,
      getError,
      password,
      resendCode,
      goToVerify,
      goToSignup,
      phoneNumber,
      getVerifyError,
      setGoToVerifyFalse,
      goToForgotPassword,
    }
  },
}
</script>
