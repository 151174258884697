<template>
  <div></div>
  <!-- <div class="relative grid grid-cols-3 gap-4 w-full pt-2">
    <div class="bg-white bg-opacity-30 text-white rounded-md px-2 pt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="rgba(255,255,255,.7)"
        class="mb-1"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z"
        ></path>
      </svg>
      <div class="text-xs py-1">Send Airtime</div>
    </div>
    <div class="bg-white bg-opacity-30 text-white rounded-md px-2 pt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="rgba(255,255,255,.7)"
        class="mb-1"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
        ></path>
      </svg>
      <div class="text-xs py-1">Pay bill</div>
    </div>
    <div class="bg-white bg-opacity-30 text-white rounded-md px-2 pt-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="24"
        viewBox="0 0 404 512"
        class="mb-1"
      >
        <g id="XMLID_1017_" transform="translate(-54)">
          <path
            id="XMLID_854_"
            d="M350.632,427.3h-45.7V381.6A97.368,97.368,0,0,1,402.3,284.231H448v45.7A97.368,97.368,0,0,1,350.632,427.3Z"
            fill="#d8ec84"
          ></path>
          <path
            id="XMLID_853_"
            d="M161.368,341.074h45.7v-45.7A97.368,97.368,0,0,0,109.7,198H64v45.7A97.368,97.368,0,0,0,161.368,341.074Z"
            fill="#d8ec84"
          ></path>
          <circle
            id="XMLID_1541_"
            cx="97"
            cy="97"
            r="97"
            transform="translate(159 10)"
            fill="#ffcb7c"
          ></circle>
          <g id="XMLID_1278_">
            <path
              id="XMLID_1279_"
              d="M256,74.946c7.262,0,13.169,4.947,13.169,11.027a10,10,0,0,0,20,0c0-13.849-9.752-25.606-23.169-29.583V55a10,10,0,0,0-20,0v1.39c-13.417,3.978-23.169,15.734-23.169,29.583C222.831,103.081,237.711,117,256,117c7.262,0,13.169,4.946,13.169,11.026S263.262,139.052,256,139.052s-13.169-4.947-13.169-11.027a10,10,0,0,0-20,0c0,13.849,9.752,25.606,23.169,29.583V159a10,10,0,0,0,20,0v-1.39c13.417-3.978,23.169-15.734,23.169-29.583C289.169,110.918,274.289,97,256,97c-7.262,0-13.169-4.946-13.169-11.026S248.738,74.946,256,74.946Z"
              fill="#fff"
            ></path>
            <path
              id="XMLID_1303_"
              d="M448,274.23H402.3A107.489,107.489,0,0,0,294.929,381.6v41.561L266,452.088V213.529A107.076,107.076,0,0,0,357.583,73.3,10,10,0,0,0,338.6,79.6,86.9,86.9,0,1,1,281.874,23.91a10,10,0,0,0,5.942-19.1A107.011,107.011,0,1,0,246,213.529V365.86l-28.929-28.928v-41.56A107.489,107.489,0,0,0,109.7,188H64a10,10,0,0,0-10,10v45.7A107.489,107.489,0,0,0,161.368,351.075h41.561L246,394.145V492H145.839a10,10,0,0,0,0,20H366.161a10,10,0,0,0,0-20H266V480.373L309.072,437.3h41.56A107.49,107.49,0,0,0,458,329.934v-45.7A10,10,0,0,0,448,274.23ZM161.368,331.074A87.467,87.467,0,0,1,74,243.706V208h35.7a87.467,87.467,0,0,1,87.368,87.368v21.56L152.91,272.77a10,10,0,0,0-14.143,14.143l44.162,44.161ZM438,329.934A87.467,87.467,0,0,1,350.632,417.3h-21.56l44.161-44.16A10,10,0,0,0,359.09,359L314.929,403.16V381.6A87.467,87.467,0,0,1,402.3,294.231H438Z"
              fill="#fff"
            ></path>
            <path
              id="XMLID_1306_"
              d="M324.38,48.2a10.005,10.005,0,1,0-7.07-2.931A10.072,10.072,0,0,0,324.38,48.2Z"
              fill="#fff"
            ></path>
          </g>
        </g>
      </svg>
      <div class="text-xs py-1">Invest</div>
    </div>
  </div> -->
</template>

<script>
export default {}
</script>
