<template>
  <div class="relative">
    <div class="block px-3 py-6">
      <div
        class="
          block
          bg-white
          border
          mx-3
          px-6
          font-montserrat font-medium
          text-sm
          py-4
          text-gray-400
        "
      >
        All activity
      </div>
      <div
        class="block"
        @click="toggleAccordion(index)"
        v-for="(transaction, index) in transactions"
        :key="transaction.createdDate"
      >
        <div
          v-if="!showAccordion(index)"
          class="
            flex
            border
            py-5
            mx-3
            px-6
            bg-white bg-opacity-80
            border-b
            items-top
            cursor-pointer
          "
        >
          <div class="flex-grow">
            <div class="text-gray-700 font-medium">
              {{ transaction.benefitiaryName }}
            </div>
            <div class="text-xs mt-1">
              <span class="text-gray-500 mr-6">{{
                transaction.transactionStatus
              }}</span>
              <span class="text-gray-400">{{
                formatDateTime(transaction.createdDate, 'MMM Do YY')
              }}</span>
            </div>
          </div>
          <div class="flex-shrink text-right">
            <div class="text-gray-700">
              {{ transaction.senderCurrency }} {{ transaction.sendAmount }}
            </div>
            <div class="text-xs text-gray-400 mt-1">
              {{ transaction.recipientCurrency }} {{ transaction.payoutAmount }}
            </div>
          </div>
        </div>
        <div
          v-if="showAccordion(index)"
          class="
            border
            py-5
            mx-3
            px-6
            bg-white bg-opacity-100
            -mt-1
            border-b
            items-top
            cursor-pointer
            shadow-lg
            block
            z-10
          "
          :class="{
            'border-yellow-400': transaction.transactionStatus === 'Pending',
            'border-green-400': transaction.transactionStatus === 'Delivered',
          }"
        >
          <div class="flex">
            <div class="flex-grow">
              <div class="text-gray-700 font-medium">
                {{ transaction.benefitiaryName }}
              </div>
              <div class="text-xs mt-1">
                <span class="text-gray-500 mr-6">{{
                  transaction.transactionStatus
                }}</span>
                <span class="text-gray-400">{{
                  formatDateTime(transaction.createdDate, 'MMM Do YY')
                }}</span>
              </div>
            </div>
            <div class="flex-shrink text-right">
              <div class="text-gray-700">
                {{ transaction.senderCurrency }} {{ transaction.sendAmount }}
              </div>
              <div class="text-xs text-gray-400 mt-1">
                {{ transaction.recipientCurrency }}
                {{ transaction.payoutAmount }}
              </div>
            </div>
          </div>
          <div class="block border-t mt-5 pt-5">
            <div class="text-xs font-montserrat font-medium mb-2 text-gray-700">
              Transaction details
            </div>
            <table width="100%" class="w-full block text-xs">
              <tr class="flex w-full text-gray-500 py-1">
                <td class="flex-grow">Paid</td>
                <td class="flex-shrink items-end font-medium">
                  {{ transaction.senderCurrency }} {{ transaction.sendAmount }}
                </td>
              </tr>
              <tr class="flex w-full text-gray-500 py-1">
                <td class="flex-grow">Our fee</td>
                <td class="text-right font-medium">{{ transaction.fee }}</td>
              </tr>
              <tr class="flex w-full text-gray-500 py-1">
                <td class="flex-grow">Amount converted</td>
                <td class="text-right font-medium">
                  {{ transaction.senderCurrency }} {{ transaction.sendAmount }}
                </td>
              </tr>
              <tr class="flex w-full text-gray-500 py-1">
                <td class="flex-grow">{{ transaction.exchangeRate }}</td>
                <td class="text-right font-medium">
                  {{ transaction.exchangeRate }}
                </td>
              </tr>
              <tr class="flex w-full text-gray-500 py-3 border-t mt-2">
                <td class="flex-grow">
                  Amount
                  <span v-if="transaction.transactionStatus === 'Pending'"
                    >to be</span
                  >
                  delivered
                </td>
                <td class="text-right font-medium">
                  {{ transaction.payoutAmount }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
export default {
  setup() {
    const store = useStore()
    const transactions = computed(() => store.state.transactions.transactions)
    const { formatDateTime } = utils()
    const accordionIsOpenArray = computed(
      () => store.state.transactions.accordionIsOpenArray,
    )

    const showAccordion = (index) => {
      if (accordionIsOpenArray.value.length) {
        if (accordionIsOpenArray.value[index] !== undefined) {
          if (
            accordionIsOpenArray.value[index]['accordionIsOpen'] !== undefined
          ) {
            return accordionIsOpenArray.value[index].accordionIsOpen
          }
        }
      }
      return false
    }

    const toggleAccordion = (index) => {
      const openAccordion = !showAccordion(index)
      //reset first
      store.dispatch('transactions/setAccordionIsOpenArray', transactions.value)
      //then toggle
      store.dispatch('transactions/toggleAccordion', { index, openAccordion })
    }

    return {
      transactions,
      showAccordion,
      formatDateTime,
      toggleAccordion,
    }
  },
}
</script>
