<template>
  <div
    class="
      fixed
      flex
      z-10
      top-0
      left-0
      w-full
      h-full
      items-center items-middle
      bg-white bg-opacity-75
      text-center
      animate__animated animate__fadeIn
    "
  >
    <div class="flex-grow">
      <div class="flex justify-center items-center">
        <div
          class="
            animate-spin
            rounded-full
            h-32
            w-32
            border-t-3 border-b-2 border-gray-900
          "
        ></div>
      </div>
      <!-- <div class="p-6 rounded-md inline-block">
        <svg
          class="animate-spin -ml-1 mr-3 h-12 w-12"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div> -->
    </div>
  </div>
</template>
