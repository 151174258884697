<template>
  <AuthWrapper>
    <div class="block my-6 text-center">
      <div class="font-montserrat text-2xl font-bold">Verify account</div>
      <div class="text-sm my-4 font-bold">
        Please check your email or phone to get OTP code

        <!-- Enter the code you received on your<br />email or phone number to
        verify. -->
      </div>
    </div>
    <!-- <div
      v-if="verifyUserDetails !== null && getRtosto() === 1"
      class="block my-4 text-center"
    >
      <div class="text-sm font-medium text-gray-700">Phone ending</div>
      <div class="text-xs text-gray-500">
        ***********{{ getLastDigits(verifyUserDetails.phone, 2) }}
      </div>
    </div> -->
    <!-- form -->
    <form @submit.prevent="verifyCustomer()" class="block mb-4">
      <div class="text-red-500 text-sm">{{ getError() }}</div>
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">OTP CODE</div>
        <input
          required
          v-model="code"
          class="outline-none w-full"
          placeholder="XXXX"
          type="text"
        />
      </div>
      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Verify account
      </button>
    </form>
    <!-- <router-link to="/resend/code" class="text-blue-500"
      >Resend Code</router-link
    > -->
    <div @click="resendCode" class="text-blue-500 cursor-pointer">
      Resend Code
    </div>
  </AuthWrapper>
  <!-- <CustomAlert v-if="showAlert" :alertType="'success'">
    <template v-slot:message>
      <div class="text-center">Account activated. Welcome to Helasend</div>
    </template>
    <template v-slot:button>
      <AlertButton @click="goToLogin()" />
    </template>
  </CustomAlert> -->
  <CustomAlert v-if="showResendCode" :alertType="'success'">
    <template v-slot:message>
      <div class="text-center">OTP has been send to your phone and email</div>
    </template>
    <template v-slot:button>
      <AlertButton
        @click="setShowResendCodeFalse()"
        class="block w-full bg-accent text-gray-700"
      />
    </template>
  </CustomAlert>
</template>

<script type="text/javascript">
import { computed, onBeforeUnmount, ref } from '@vue/runtime-core'
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AuthWrapper from './AuthWrapper.vue'
import AlertButton from '@/components/partials/AlertButton.vue'
import CustomAlert from '@/components/partials/CustomAlert.vue'

export default {
  components: { AuthWrapper, AlertButton, CustomAlert },
  setup() {
    const store = useStore()
    const router = useRouter()
    const showResendCode = ref(false)
    const code = ref('')
    const token = computed(() => store.state.auth.token)
    const verifyUserDetails = computed(() => store.state.auth.verifyUserDetails)
    const error = computed(() => store.state.auth.error)
    const success = computed(() => store.state.auth.success)
    const showAlert = computed(() => store.state.alerts.showAlert)

    if (token.value === null) {
      store.dispatch('auth/fetchToken')
    }

    const goToLogin = () => {
      router.push({ name: 'login' })
      store.dispatch('auth/setSuccess', '')
    }

    const { setRequestConfig } = utils()
    const config = setRequestConfig(token.value, null)
    const userID = verifyUserDetails.value.userID
    const verifyCustomer = async () => {
      const phone = verifyUserDetails.value.phone
      const body = {
        code: code.value,
        userID,
        phone,
      }
      const payload = {
        body,
        config,
      }
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/verifyCustomer', payload)
      if (success.value !== '') {
        if (success.value.component === 'verify') {
          goToLogin()
        }
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'verify') {
          return error.value.message
        }
      }
    }

    const getLastDigits = (str, end) => {
      if (str !== undefined) {
        return str.substring(str.length - end)
      }
    }

    const resendCode = async () => {
      store.dispatch('auth/setSuccess', '')

      store.dispatch('auth/setLoadingTrue')
      const body = {
        userID,
      }

      const payload = {
        body,
        config,
      }
      await store.dispatch('auth/resendCode', payload)
      if (success.value !== '') {
        if (success.value.component === 'resendCode') {
          showResendCode.value = true
        }
      }
    }

    const setShowResendCodeFalse = () => {
      showResendCode.value = false
    }

    onBeforeUnmount(() => {
      store.dispatch('auth/setError', '')
      store.dispatch('auth/setSuccess', '')
    })

    return {
      code,
      getError,
      showAlert,
      goToLogin,
      resendCode,
      getLastDigits,
      verifyCustomer,
      showResendCode,
      verifyUserDetails,
      setShowResendCodeFalse,
    }
  },
}
</script>
