<template>
  <div class="md:hidden block">
    <TransactionComponent />
  </div>
  <div class="hidden md:block">
    <DashboardWrapper><TransactionComponent /></DashboardWrapper>
  </div>
</template>

<script>
import DashboardWrapper from '../desktop/DashboadWrapper.vue'
import TransactionComponent from '@/components/dashboard/Transaction'
export default {
  components: { DashboardWrapper, TransactionComponent },
}
</script>
