const state = () => ({
  showReferralModal: false,
})
const actions = {
  openReferralModal({ commit }) {
    commit('openReferralModal')
  },
  closeReferralModal({ commit }) {
    commit('closeReferralModal')
  },
}
const mutations = {
  openReferralModal(state) {
    state.showReferralModal = true
  },
  closeReferralModal(state) {
    state.showReferralModal = false
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
