<template>
  <div
    class="fixed left-0 top-0 bottom-0 right-0 w-full h-full transition-all"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeCountryCodesDropDownMenu()"
    ></div>
    <div
      class="
        absolute
        left-1
        right-1
        bottom-0
        bg-white
        rounded-t-lg
        overflow-auto
        shadow-lg
        max-w-xl
        mx-auto
        animate__animated animate__fadeInUp
      "
    >
      <!-- <div class="text-sm w-full text-left border-t p-2 bg-gray-50">
        <input
          autofocus
          type="text"
          autocomplete="false"
          @input="(evt) => (search = evt.target.value)"
          @keyup="sourceCurrencies()"
          class="outline-none border-none px-3 py-3 w-full"
          placeholder="Search country"
        />
      </div> -->
      <div class="py-2 text-xs border-t bg-gray-50">
        <TinySpin v-if="loading" />
        <ul
          v-else
          class="block text-sm text-left max-h-72 sm:max-h-56 overflow-auto"
        >
          <li v-if="sourceCurrencies === null" class="py-2 px-3">No options</li>
          <li
            @click="setCountryDetails(country)"
            v-for="(country, index) in sourceCurrencies"
            class="py-2 px-3 cursor-pointer border-b hover:bg-white flex"
            :key="index"
          >
            <div
              class="
                relative
                w-6
                h-4
                pb-2/3
                overflow-hidden
                mr-2
                rounded-sm
                shadow-md
                align-baseline
              "
            >
              <img
                :src="setCountryFlagURL(country.isoCode)"
                class="absolute h-full w-full border border-gray-200"
              />
            </div>
            <div
              class="
                flex
                w-full
                text-xs
                tracking-wider
                font-semibold
                text-gray-500
                sm:text-gray-800
              "
            >
              <div class="flex justify-start w-11/12 pr-4">
                <div>{{ country.name }}</div>
              </div>
              <div class="flex justify-end w-1/12">
                <div>{{ country.currency }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import TinySpin from '@/components/partials/TinySpin'
import utils from '@/composables/utils'

export default {
  components: { TinySpin },
  setup() {
    const store = useStore()
    const search = ref('')
    const country = ref('')
    const isFocused = ref(false)

    const token = computed(() => store.state.auth.token)

    const sourceCurrencies = computed(
      () => store.state.countries.sourceCurrencies,
    )
    const loading = computed(() => store.state.countries.loading)

    const closeCountryCodesDropDownMenu = () => {
      store.dispatch('countries/closeCountryCodesDropDownMenu')
    }

    const setCountryDetails = (country) => {
      store.dispatch('countries/setCountryDetails', country)
      closeCountryCodesDropDownMenu()
    }

    const fetchExchangeRates = async () => {
      await store.dispatch('auth/fetchToken')
      const payload = {
        token: token.value,
      }
      if (sourceCurrencies.value === null) {
        store.dispatch('countries/setLoadingTrue', payload)
        store.dispatch('countries/fetchExchangeRates', payload)
      }
    }

    fetchExchangeRates()

    const { setCountryFlagURL } = utils()

    return {
      search,
      loading,
      country,
      isFocused,
      sourceCurrencies,
      setCountryDetails,
      setCountryFlagURL,
      closeCountryCodesDropDownMenu,
    }
  },
}
</script>
