<template>
  <form @submit.prevent="addBeneficiary()" class="block p-4">
    <div class="grid grid-cols-2 gap-4">
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">FIRST NAME</div>
        <input
          required
          v-model="firstName"
          class="outline-none w-full text-gray-700"
          type="text"
          autocomplete="off"
        />
      </div>
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">LAST NAME</div>
        <input
          required
          v-model="lastName"
          class="outline-none w-full text-gray-700"
          type=""
          autocomplete="off"
        />
      </div>
    </div>

    <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">COUNTRY OF RESIDENCE</div>
      <select
        required
        disabled
        v-model="country"
        @change="handleSelectCountry($event)"
        class="w-full text-gray-700 m-0 outline-none appearance-none"
      >
        <option
          v-for="country in currenciesWithPayoutOptionData"
          :key="country.isoCode"
          :data-isocode="country.isoCode"
          :data-prefix="country.prefix"
        >
          {{ country.name }}
        </option>
      </select>
    </div>

    <div v-if="error" class="text-sm text-red-500">
      {{ error }}
    </div>

    <div v-if="banksPayments !== null || mobilePayments !== null" class="mb-4">
      <div
        class="text-xs text-gray-400 ml-4 mb-2"
        :class="{
          'text-red-500': showDeliveryMethodError,
        }"
      >
        CHOOSE DELIVERY METHOD
      </div>
      <div class="grid grid-flow-col gap-2 text-sm">
        <div
          v-if="mobilePayments.providers.length === 1"
          @click="changeSelectedPayout(mobilePayments.name)"
          class="py-2 rounded-md border text-center"
          :class="{
            'border-blue-400': payoutType === 'Mobile Payments',
          }"
        >
          {{ mobilePayments.providers[0].name }}
        </div>
        <div
          v-if="banksPayments.providers.length"
          @click="changeSelectedPayout(banksPayments.name)"
          class="py-2 rounded-md border text-center"
          :class="{ 'border-blue-400': payoutType === banksPayments.name }"
        >
          {{ banksPayments.name }}
        </div>
      </div>
    </div>

    <div
      v-if="banksPayments !== null && payoutType === banksPayments.name"
      class="border px-4 pt-2 pb-2 rounded-md mb-4"
    >
      <div class="text-xs text-gray-400">SELECT BANK</div>

      <select
        required
        v-model="bankName"
        class="w-full text-gray-700 m-0 outline-none appearance-none"
      >
        <option v-for="bank in banksPayments.providers" :key="bank.name">
          {{ bank.name }}
        </option>
      </select>
    </div>

    <div
      v-if="banksPayments !== null && payoutType === banksPayments.name"
      class="border px-4 pt-2 pb-2 rounded-md mb-4"
    >
      <div class="text-xs text-gray-400">ACCOUNT NUMBER</div>
      <input
        required
        v-model="bankAccount"
        class="outline-none w-full text-gray-700"
        placeholder="11111111111"
        type="tel"
        autocomplete="off"
      />
    </div>

    <div v-if="payoutType" class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">PHONE NUMBER</div>
      <div class="flex">
        <div class="flex-shrink pr-2">
          <div class="text-md text-gray-700">+{{ prefix }}</div>
        </div>
        <div class="flex-grow">
          <input
            required
            v-model="phone"
            class="outline-none w-full"
            placeholder="7xxxxxxxx"
            type="tel"
            autocomplete="off"
          />
        </div>
      </div>
    </div>
    <div class="border bg-gray-100 p-3 mb-4 text-xs text-gray-600 rounded-md">
      Please make sure the details you have provided for this recipient is
      correct.
    </div>
    <div v-if="beneficiaryError" class="text-sm text-red-500">
      {{ getError() }}
    </div>
    <div class="block mt-12 text-right">
      <button
        @click="closeAddBeneficiaryModal"
        class="
          mr-4
          hidden
          md:inline-block
          px-4
          py-2
          rounded-md
          bg-gray-100
          text-gray-500 text-center
          font-montserrat
        "
      >
        Cancel
      </button>
      <button
        type="submit"
        class="
          inline-block
          md:hidden
          w-full
          px-4
          py-2
          rounded-md
          bg-yellow-300
          text-gray-900
          font-medium
          text-center
          font-montserrat
        "
      >
        Save details
      </button>
      <button
        type="submit"
        class="
          hidden
          md:inline-block
          px-4
          py-2
          rounded-md
          bg-yellow-300
          text-gray-900
          font-medium
          text-center
          font-montserrat
        "
      >
        Save details
      </button>
    </div>
  </form>
  <Spin v-if="loading" />
</template>

<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onBeforeUnmount } from '@vue/runtime-core'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'
import Spin from '@/components/partials/Spin.vue'
import { useRoute, useRouter } from 'vue-router'
export default {
  components: { Spin },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const firstName = ref('')
    const lastName = ref('')
    const country = ref('Select Country')
    const phone = ref('')
    const mobileMoneyOperator = ref('')
    const bankName = ref('')
    const bankAccount = ref('')
    const selectedCountryIsoCode = ref('')
    const prefix = ref('')
    const payoutType = ref('')
    const mobilePayments = computed(() => store.state.payouts.mobilePayments)
    const banksPayments = computed(() => store.state.payouts.banksPayments)
    const error = computed(() => store.state.payouts.error)
    const showDeliveryMethodError = ref(false)
    const beneficiaryError = computed(() => store.state.beneficiary.error)
    const currencies = computed(() => store.state.countries.currencies)
    const payoutIsLoading = computed(
      () => store.state.beneficiary.payoutIsLoading,
    )
    const loading = computed(() => store.state.beneficiary.loading)
    const success = computed(() => store.state.beneficiary.success)

    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )

    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )
    const selectedBeneficiary = computed(
      () => store.state.beneficiary.selectedBeneficiary,
    )

    const { setCountryFlagURL, getBeneficiaryExchangeRate, isSameCountry } =
      utils()

    const setBeneficiaryCurrencyDetails = (country, currencies) => {
      const payload = getBeneficiaryExchangeRate(country, currencies)
      store.dispatch('beneficiary/setBeneficiaryCurrencyDetails', payload)
    }

    const closeAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/closeAddBeneficiaryModal')
    }

    const buildTransactionObject = (
      userID,
      benefitiaryID,
      sourceExchangeRate,
      destinationExchangeRate,
      exchangeRate,
    ) => {
      const transactionOnCreation = {
        userID,
        benefitiaryID,
        sourceExchangeRate,
        destinationExchangeRate,
        exchangeRate,
        purposeforFunds: 'Test',
        sourceOfFunds: 'Test',
        remarks: 'Test',
      }
      if (benefitiaryID !== '') {
        store.dispatch(
          'transactions/buildTransactionObject',
          transactionOnCreation,
        )
      }
    }

    const fetchPayouts = async (isoCode) => {
      const payload = {
        userID: userDetails.value.userID,
        jwt: userDetails.value.jwt,
        token: token.value,
        isoCode,
      }
      store.dispatch('payouts/setPayoutsIsLoadingTrue')
      await store.dispatch('payouts/fetchPayouts', payload)
    }

    const handleSelectCountry = (event) => {
      const isoCode =
        event.target.options[event.target.options.selectedIndex].dataset.isocode
      prefix.value =
        event.target.options[event.target.options.selectedIndex].dataset.prefix
      selectedCountryIsoCode.value = isoCode
      fetchPayouts(isoCode)
    }

    const changeSelectedPayout = (payout) => {
      payoutType.value = payout
      if (payoutType.value) {
        showDeliveryMethodError.value = false
      }
    }

    const addBeneficiary = async () => {
      if (!payoutType.value) {
        return (showDeliveryMethodError.value = true)
      }
      const names = `${firstName.value} ${lastName.value}`
      let phoneNumber = `${prefix.value}${phone.value}`
      let bankAccountNumber = bankAccount.value
      if (payoutType.value == 'Mobile Payments') {
        bankAccountNumber = ''
      }
      const body = {
        names,
        payoutType: payoutType.value,
        country: selectedCountryIsoCode.value,
        bankName: bankName.value,
        bankAccount: bankAccountNumber,
        mobileMoneyOperator: mobileMoneyOperator.value,
        phone: phoneNumber,
        userID: userDetails.value.userID,
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token.value}`,
          JWTToken: userDetails.value.jwt,
          Accept: 'application/json',
        },
      }

      const flag = setCountryFlagURL(selectedCountryIsoCode.value)
      let account = ''
      if (payoutType.value === 'Mobile Payments') {
        account = phone.value
      } else {
        account = bankAccount.value
      }
      const beneficiaryOnSelectionPhone = `${prefix.value}${account}`
      const beneficiaryOnSelection = {
        account: beneficiaryOnSelectionPhone,
        benefitiaryID: '',
        country: selectedCountryIsoCode.value,
        flag,
        names,
        payoutType: payoutType.value,
      }
      const payload = {
        body,
        config,
        beneficiaryOnSelection,
      }
      setBeneficiaryCurrencyDetails(
        selectedCountryIsoCode.value,
        currencies.value,
      )
      store.dispatch('beneficiary/setLoadingTrue')
      await store.dispatch('beneficiary/addBeneficiary', payload)
      if (success.value !== '') {
        if (success.value.component === 'addBeneficiary') {
          closeAddBeneficiaryModal()
        }
      }
      let benefitiaryID = ''
      if (selectedBeneficiary.value !== null) {
        benefitiaryID = selectedBeneficiary.value.benefitiaryID
      }
      const { calculateExchangeRate } = createTransaction()
      const countryIsSame = isSameCountry(
        senderCurrencyDetails.value.name,
        beneficiaryCurrencyDetails.value.name,
      )

      const exchangeRate = calculateExchangeRate(
        countryIsSame,
        senderCurrencyDetails.value.marketRates,
        beneficiaryCurrencyDetails.value.rates,
      )

      const senderMarketRates = countryIsSame
        ? senderCurrencyDetails.value.rates
        : senderCurrencyDetails.value.marketRates

      buildTransactionObject(
        userDetails.value.userID,
        benefitiaryID,
        senderMarketRates,
        beneficiaryCurrencyDetails.value.rates,
        exchangeRate,
      )
      if (success.value !== '') {
        if (success.value.component === 'addBeneficiary') {
          router.push({ name: route.params.returnUrl })
        }
      }
    }

    const currenciesWithPayoutOptionData = ref([])

    const getCurrenciesWithPayoutOptions = () => {
      currenciesWithPayoutOptionData.value = currencies.value.filter(
        (currency) => {
          return currency.payoutOptionsData !== null
        },
      )
      if (currenciesWithPayoutOptionData.value.length === 1) {
        const isoCode = currenciesWithPayoutOptionData.value[0].isoCode
        prefix.value = currenciesWithPayoutOptionData.value[0].prefix
        selectedCountryIsoCode.value = isoCode
        fetchPayouts(isoCode)
        country.value = currenciesWithPayoutOptionData.value[0].name
      }
    }

    getCurrenciesWithPayoutOptions()

    const getError = () => {
      if (beneficiaryError.value !== '') {
        if (beneficiaryError.value.component === 'addBeneficiary') {
          return beneficiaryError.value.data.message
        }
      }
    }

    onBeforeUnmount(() => {
      store.dispatch('beneficiary/setError', '')
    })

    return {
      error,
      phone,
      prefix,
      loading,
      country,
      getError,
      lastName,
      bankName,
      firstName,
      currencies,
      payoutType,
      bankAccount,
      banksPayments,
      mobilePayments,
      addBeneficiary,
      payoutIsLoading,
      beneficiaryError,
      handleSelectCountry,
      changeSelectedPayout,
      showDeliveryMethodError,
      closeAddBeneficiaryModal,
      currenciesWithPayoutOptionData,
    }
  },
}
</script>
