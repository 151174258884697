<template>
  <!-- Recipients -->
  <div
    class="fixed left-0 top-0 w-full h-full z-10 transition-all overflow-auto"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeBeneficiariesModal()"
    ></div>
    <div
      class="
        absolute
        bottom-0
        md:top-10 md:bottom-auto
        left-0
        right-0
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div
        class="
          block
          py-2
          px-4
          font-montserrat font-medium
          text-lg text-gray-600
        "
      >
        Recipients
      </div>
      <div
        @click="openAddBeneficiaryModal('mobile')"
        class="
          flex
          md:hidden
          px-4
          py-3
          border-b
          gap-4
          items-center
          bg-blue-50
          cursor-pointer
        "
      >
        <div class="px-">
          <svg
            class="text-blue-500"
            height="16"
            viewBox="0 0 426.66667 426.66667"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="rgba(59, 130, 246, 1)"
              d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"
            />
          </svg>
        </div>
        <div class="flex-grow text-md text-blue-500 font-montserrat">
          Add recipient
        </div>
      </div>
      <div
        @click="openAddBeneficiaryModal('desktop')"
        class="
          hidden
          md:flex
          px-4
          py-3
          border-b
          gap-4
          items-center
          bg-blue-50
          cursor-pointer
        "
      >
        <div class="px-">
          <svg
            class="text-blue-500"
            height="16"
            viewBox="0 0 426.66667 426.66667"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="rgba(59, 130, 246, 1)"
              d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"
            />
          </svg>
        </div>
        <div class="flex-grow text-md text-blue-500 font-montserrat">
          Add recipient
        </div>
      </div>
      <div class="py-2 px-4 bg-gray-50 text-xs font-bold text-gray-400">
        FREQUENT RECIPIENTS
      </div>

      <Recipients />
    </div>
  </div>
</template>

<script type="text/javascript">
import { useStore } from 'vuex'
import Recipients from '@/components/Recipients.vue'
import { useRouter } from 'vue-router'

export default {
  components: { Recipients },
  setup() {
    const store = useStore()
    const route = useRouter()
    const router = useRouter()
    const closeBeneficiariesModal = () => {
      store.dispatch('beneficiary/closeBeneficiariesModal')
    }

    const openAddBeneficiaryModal = (screen) => {
      store.dispatch('beneficiary/closeBeneficiariesModal')
      if (screen === 'desktop') {
        store.dispatch('beneficiary/openAddBeneficiaryModal')
      } else if (screen === 'mobile') {
        const currentRouteName = route.currentRoute.value.name
        return router.push({
          name: 'add-recipient',
          params: { returnUrl: currentRouteName },
        })
      }
    }
    return {
      closeBeneficiariesModal,
      openAddBeneficiaryModal,
    }
  },
}
</script>
