<template>
  <form
    v-if="showCardForm"
    @submit.prevent="addPaymentInfo()"
    class="block p-4"
  >
    <div class="text-red-500 text-sm">{{ getError() }}</div>

    <div class="grid grid-cols-2 gap-4">
      <div class="border px-4 pt-2 pb-2 rounded-md">
        <div class="text-xs text-gray-400">FIRST NAME</div>
        <input
          required
          v-model="lastName"
          class="outline-none w-full text-gray-700"
          placeholder=""
          type="text"
          autocomplete="off"
        />
      </div>
      <div class="border px-4 pt-2 pb-2 rounded-md">
        <div class="text-xs text-gray-400">LAST NAME</div>
        <input
          required
          v-model="firstName"
          class="outline-none w-full text-gray-700"
          placeholder=""
          type="text"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="text-xs mt-1 text-gray-400 mb-6">
      Enter name as it appears on the card
    </div>

    <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">Card Number</div>
      <div class="flex">
        <input
          type="text"
          v-model="cardNumber"
          class="outline-none w-full text-gray-700 pr-4"
          placeholder="0000 0000 0000 0000"
          maxlength="19"
          pattern="[0-9 ]+"
          title="Must contain numbers only"
          inputmode="numeric"
          autocomplete="off"
          required
        />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">Expiry</div>
        <div class="flex">
          <input
            v-model="expireMonth"
            class="outline-none text-gray-700 w-10 pr-2"
            placeholder="MM"
            @keyup="moveOnMax(expireMonth, 'expireyear', 2)"
            maxlength="2"
            minlength="1"
            type="text"
            autocomplete="off"
            required
          />
          <div class="px-1">/</div>
          <input
            v-model="expireyear"
            id="expireyear"
            class="outline-none w-full text-gray-700 pl-3"
            placeholder="YY"
            maxlength="2"
            minlength="2"
            type="text"
            autocomplete="off"
            required
          />
        </div>
      </div>
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">CVC</div>
        <input
          v-model="cvc"
          class="outline-none w-full text-gray-700"
          placeholder="000"
          type="text"
          maxlength="3"
          minlength="3"
          autocomplete="off"
          required
        />
      </div>
    </div>

    <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">COUNTRY</div>
      <select
        v-model="country"
        required
        @change="setCurrency($event)"
        class="w-full text-gray-700 m-0 outline-none appearance-none"
      >
        <option
          v-for="country in sourceCurrencies"
          :key="country.isoCode"
          :data-currency="country.currency"
        >
          {{ country.name }}
        </option>
      </select>
    </div>
    <div class="block md:hidden mt-12">
      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          text-gray-900
          font-medium
          text-center
          font-montserrat
        "
      >
        Save details
      </button>
      <div class="mt-12 flex justify-center">
        <PCIDSSCompliantSVG />
      </div>
    </div>
    <div class="hidden md:flex justify-between text-right mt-12">
      <span class="inline-block float-left">
        <PCIDSSCompliantSVG />
      </span>
      <div class="inline-block">
        <div
          @click="closeAddCardModal()"
          class="
            cursor-pointer
            inline-block
            px-4
            py-2
            mr-4
            rounded-md
            bg-gray-100
            text-gray-500 text-center
            font-montserrat
          "
        >
          Cancel
        </div>
        <button
          type="submit"
          class="
            inline-block
            px-4
            py-2
            rounded-md
            bg-yellow-300
            text-gray-900
            font-medium
            text-center
            font-montserrat
          "
        >
          Save details
        </button>
      </div>
    </div>
  </form>
  <Spin v-if="loading" />
</template>

<script>
import { useStore } from 'vuex'
import Spin from '@/components/partials/Spin.vue'
import PCIDSSCompliantSVG from '@/components/partials/PCIDSSCompliantSVG.vue'
import utils from '@/composables/utils'
import { computed, ref, watch } from '@vue/runtime-core'

export default {
  props: {
    redirectPath: {
      type: String,
      required: true,
    },
  },
  components: { Spin, PCIDSSCompliantSVG },
  setup(props) {
    const store = useStore()
    const showCardForm = ref(true)
    const token = computed(() => store.state.auth.token)
    const userDetails = computed(() => store.state.auth.userDetails)
    const sourceCurrencies = computed(
      () => store.state.countries.sourceCurrencies,
    )
    const error = computed(() => store.state.paymentInfo.error)
    const success = computed(() => store.state.paymentInfo.success)
    const loading = computed(() => store.state.paymentInfo.loading)

    const firstName = ref('')
    const lastName = ref('')

    const cardNumber = ref()
    const expireMonth = ref('')
    const expireyear = ref('')
    const country = ref('')
    const cvc = ref('')
    const currency = ref('')

    const setCurrency = (event) => {
      const selectedCurrency =
        event.target.options[event.target.options.selectedIndex].dataset
          .currency
      currency.value = selectedCurrency
    }

    const { setRequestConfig, getAddCardRedirectUrl } = utils()
    const goToAddCard = (data) => {
      document.write(data)
      if (document.getElementsByName('frmLaunch') !== undefined) {
        document.frmLaunch.submit()
        showCardForm.value = false
      }
    }

    const addPaymentInfo = async () => {
      const userID = userDetails.value.userID
      const jwt = userDetails.value.jwt
      const card = Number(cardNumber.value.replace(/ /g, ''))
      let expireM = expireMonth.value
      if (expireM.length < 2) {
        expireM = `0${expireM}`
      }
      const expireDate = `${expireM}${expireyear.value}`
      const names = `${firstName.value} ${lastName.value}`
      const redirectPath = props.redirectPath

      const redirectUrl = getAddCardRedirectUrl(redirectPath)
      const body = {
        names,
        cardNumber: card,
        expireDate,
        cvc: cvc.value,
        country: country.value,
        currency: currency.value,
        userID: userID,
        redirectUrl,
      }
      const config = setRequestConfig(token.value, jwt)
      const payload = {
        body,
        config,
      }

      store.dispatch('paymentInfo/setLoadingTrue')
      await store.dispatch('paymentInfo/addPaymentInfo', payload)
      if (success.value) {
        store.dispatch('paymentInfo/closeAddCardModal')
        goToAddCard(success.value.data.urlHtml)
      }
      // window.location.href = redirectUrl
      if (props.redirectPath === '/sendmoney/review') {
        store.dispatch('paymentInfo/setCVC', cvc.value)
      }
    }

    const moveOnMax = (field, nextFieldID, length) => {
      if (field.length >= length) {
        document.getElementById(nextFieldID).focus()
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'addPaymentInfo') {
          return error.value.message
        }
      }
    }

    watch(
      () => cardNumber.value,
      () => {
        if (cardNumber.value !== '') {
          // Card number without space (' ')
          let realNumber = cardNumber.value.replace(/ /gi, '')

          // Generate spaced number
          let spacedNumber = realNumber.match(/.{1,4}/g)

          // Replace the spaced number with the real one
          cardNumber.value = spacedNumber.join(' ')
        }
      },
    )

    const closeAddCardModal = () => {
      store.dispatch('setError', '')
      store.dispatch('paymentInfo/closeAddCardModal')
    }

    return {
      cvc,
      success,
      loading,
      country,
      getError,
      lastName,
      firstName,
      expireyear,
      moveOnMax,
      cardNumber,
      setCurrency,
      userDetails,
      expireMonth,
      showCardForm,
      addPaymentInfo,
      sourceCurrencies,
      closeAddCardModal,
    }
  },
}
</script>
