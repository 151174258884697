<template>
  <div
    class="
      flex
      z-10
      items-center items-middle
      bg-white bg-opacity-75
      text-center
      animate__animated animate__fadeInDown
    "
  >
    <div class="w-full">
      <div class="p-0 bg-accent rounded-md inline-block w-full">
        <div
          class="
            alert
            flex flex-row
            items-center
            bg-red-100
            px-4
            p-2
            rounded
            border-b-2 border-red-300
          "
        >
          <div
            class="
              alert-icon
              flex
              items-center
              bg-red-50
              border-2 border-red-400
              justify-center
              h-6
              w-6
              flex-shrink-0
              rounded-full
            "
          >
            <span class="text-red-400">
              <svg fill="currentColor" viewBox="0 0 20 20" class="h-4 w-4">
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
          </div>
          <div class="alert-content ml-2">
            <div class="alert-description text-sm text-red-400">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
