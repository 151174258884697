<template>
  <div class="relative">
    <div class="block border-b px-6 py-4">
      <div class="max-w-3xl flex m-auto items-center">
        <router-link :to="{ name: 'home' }" class="w-24 pr-6">
          <svg
            class="inline-block rounded-full"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 512 512"
          >
            <rect
              id="Rectangle_1"
              data-name="Rectangle 1"
              width="512"
              height="512"
              fill="#252424"
            />
            <g id="Group_2" data-name="Group 2" transform="translate(130 156)">
              <g id="Group_1" data-name="Group 1" transform="translate(0)">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M275.329,290.132l31.705,42.829,4.766-83.235Z"
                  transform="translate(-275.329 -191.604)"
                  fill="#ffd200"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M434.795,312.582H343.51l4.978-79.075H301.975L289.357,434.063H335.87l5.168-82.232h91.3l-5.179,82.232h46.513l12.607-200.556H439.769Z"
                  transform="translate(-239.087 -233.507)"
                  fill="#ffd200"
                />
              </g>
            </g>
          </svg>
        </router-link>

        <div class="flex-grow w-full mx-4 text-center text-sm text-gray-400">
          <div class="relative md:mx-20 mb-3 mt-3 md:mt-0">
            <div class="bg-gray-200 pt-1 md:pt-0.5 rounded-full"></div>
            <div
              class="bg-black pt-1 md:pt-0.5 rounded-full absolute top-0 left-0"
              :class="{
                'w-0': $route.name == 'sendmoney-recipient',
                'w-1/3': $route.name == 'sendmoney-amount',
                'w-2/3': $route.name == 'sendmoney-review',
                'w-full': $route.name == 'sendmoney-complete',
              }"
            ></div>
          </div>

          <div class="hidden md:grid grid-cols-4">
            <div
              class="col"
              :class="{ 'text-gray-800': $route.name == 'sendmoney-recipient' }"
            >
              Recipient
            </div>
            <div
              class="col"
              :class="{ 'text-gray-800': $route.name == 'sendmoney-amount' }"
            >
              Amount
            </div>
            <div
              class="col"
              :class="{ 'text-gray-800': $route.name == 'sendmoney-review' }"
            >
              Review
            </div>
            <div
              class="col"
              :class="{
                'text-gray-800': $route.name == 'sendmoney-complete',
              }"
            >
              Complete
            </div>
          </div>
        </div>
        <div
          class="w-24 pl-6 text-right cursor-pointer"
          @click="$router.push({ name: 'home' })"
        >
          <svg
            version="1.1"
            class="inline-block"
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 492 492"
            fill="#aaa"
            xml:space="preserve"
          >
            <path
              d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="max-w-xl md:max-w-xl mx-auto my-4 md:my-0 px-6 py-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>
