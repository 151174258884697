import axios from 'axios'
import moment from 'moment'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL, veriffSessionURL } = definedConstants()

const state = () => ({
  goToVeriff: false,
  kycSession: null,
  veriffURL: null,
  error: '',
  verifyIsLoading: false,
})

const actions = {
  // Veriff
  async setVeriffSession({ commit }, payload) {
    const headers = {
      'Content-Type': 'application/json',
      'X-AUTH-CLIENT': process.env.VUE_APP_VERIFF_API_KEY,
    }
    const person = payload.person
    const callback = payload.redirectUrl
    const timestamp = moment().utc().format()
    const body = JSON.stringify({
      verification: {
        callback,
        person,
        timestamp,
      },
    })

    try {
      const response = await axios.post(veriffSessionURL, body, { headers })
      if (response.data.status === 'success') {
        commit('setVeriffURL', response.data.verification.url)
        commit('setKYCSession', response.data.verification.id)
      }
    } catch (error) {
      commit('error')
      console.log(error)
    }
  },

  async setKYCSession({ commit }, payload) {
    const { body, config } = payload
    const url = `${apiBaseURL}/v1/customers/kyc/session`
    try {
      await axios.post(url, body, config)
      setTimeout(() => {
        commit('setVerifyIsLoadingFalse')
      }, 4000)
    } catch (error) {
      commit('error')
      console.log(error)
    }
  },
  setVerifyIsLoadingTrue({ commit }) {
    commit('setVerifyIsLoadingTrue')
  },
  setVerifyIsLoadingFalse({ commit }) {
    commit('setVerifyIsLoadingFalse')
  },
  setGoToVeriffTrue({ commit }) {
    commit('setGoToVeriffTrue')
  },
  setGoToVeriffFalse({ commit }) {
    commit('setGoToVeriffFalse')
  },
}
const mutations = {
  setVeriffURL(state, payload) {
    state.veriffURL = payload
  },
  setKYCSession(state, payload) {
    state.kycSession = payload
  },
  setVerifyIsLoadingTrue(state) {
    state.verifyIsLoading = true
  },
  setVerifyIsLoadingFalse(state) {
    state.verifyIsLoading = false
  },
  setGoToVeriffTrue(state) {
    state.goToVeriff = true
  },
  setGoToVeriffFalse(state) {
    state.goToVeriff = false
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
