<template>
  <div v-show="beneficiaries.length" class="block mb-8">
    <div class="text-sm text-gray-500 mb-3">RECENTLY ADDED RECIPIENTS</div>
    <div class="block border-t">
      <div
        v-for="(beneficiary, index) in sliceBeneficiaries()"
        :key="index"
        class="flex py-5 border-b gap-4 items-center cursor-pointer"
        @click="
          selectBeneficiary(
            beneficiary.account,
            beneficiary.benefitiaryID,
            beneficiary.country,
            beneficiary.names,
            beneficiary.payoutType,
          )
        "
      >
        <div
          class="
            w-14
            h-11
            relative
            pb-2/3
            rounded-md
            border
            bg-gray-200
            overflow-hidden
          "
        >
          <img
            :src="setCountryFlagURL(beneficiary.country)"
            class="absolute block h-full w-full object-cover"
          />
        </div>
        <div class="flex-grow">
          <div class="text-md text-gray-700">{{ beneficiary.names }}</div>
          <div class="text-xs text-gray-400 mt-1">
            {{ getPaymentChannel(beneficiary.payoutType) }} ending
            {{ getLastXStringCharacters(beneficiary.account, 4) }}
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.967"
          height="14.009"
          viewBox="0 0 15.967 14.009"
        >
          <path
            id="Arrow_Left"
            data-name="Arrow Left"
            d="M9.7,16.707V16.7a1.008,1.008,0,0,1-1.42,0l-6-6h0a1,1,0,0,1-.01-1.42l6-6v-.01a.99.99,0,0,1,1.4,1.4l-4.3,4.29H16.946a.988.988,0,0,1,1,.99,1,1,0,0,1-1,1H5.35l4.29,4.29-.01-.01a1,1,0,0,1,0,1.41Z"
            transform="translate(17.946 16.99) rotate(180)"
            fill="#6c6c6c"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const userDetails = computed(() => store.state.auth.userDetails)
    const currencies = computed(() => store.state.countries.currencies)

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )
    const sliceBeneficiaries = () => {
      if (beneficiaries.value.length > 2) {
        return beneficiaries.value.slice(0, 2)
      }
      return beneficiaries.value
    }

    const {
      getLastXStringCharacters,
      getPaymentMode,
      setCountryFlagURL,
      getBeneficiaryExchangeRate,
      isSameCountry,
    } = utils()

    const getPaymentChannel = (payoutType) => {
      if (getPaymentMode(payoutType) == 1) {
        return 'Mobile phone'
      } else if (getPaymentMode(payoutType) == 2) {
        return 'Bank account'
      }
    }

    const buildTransactionObject = (
      userID,
      benefitiaryID,
      sourceExchangeRate,
      destinationExchangeRate,
      exchangeRate,
    ) => {
      const transactionOnCreation = {
        userID,
        benefitiaryID,
        sourceExchangeRate,
        destinationExchangeRate,
        exchangeRate,
        purposeforFunds: 'Test',
        sourceOfFunds: 'Test',
        remarks: 'Test',
      }
      store.dispatch(
        'transactions/buildTransactionObject',
        transactionOnCreation,
      )
    }

    const setBeneficiaryCurrencyDetails = (country, currencies) => {
      const payload = getBeneficiaryExchangeRate(country, currencies)
      store.dispatch('beneficiary/setBeneficiaryCurrencyDetails', payload)
    }

    const selectBeneficiary = (
      account,
      benefitiaryID,
      country,
      names,
      payoutType,
    ) => {
      const flag = setCountryFlagURL(country)
      const beneficiaryDetails = {
        account,
        benefitiaryID,
        country,
        flag,
        names,
        payoutType,
      }
      setBeneficiaryCurrencyDetails(country, currencies.value)
      store.dispatch('beneficiary/setSelectedBeneficiary', beneficiaryDetails)
      const { calculateExchangeRate } = createTransaction()
      const countryIsSame = isSameCountry(
        senderCurrencyDetails.value.name,
        beneficiaryCurrencyDetails.value.name,
      )
      const exchangeRate = calculateExchangeRate(
        countryIsSame,
        senderCurrencyDetails.value.marketRates,
        beneficiaryCurrencyDetails.value.rates,
      )

      const senderMarketRates = countryIsSame
        ? senderCurrencyDetails.value.rates
        : senderCurrencyDetails.value.marketRates

      buildTransactionObject(
        userDetails.value.userID,
        benefitiaryID,
        senderMarketRates,
        beneficiaryCurrencyDetails.value.rates,
        exchangeRate,
      )
      router.push({ name: 'sendmoney-amount' })
    }

    return {
      beneficiaries,
      setCountryFlagURL,
      getPaymentChannel,
      selectBeneficiary,
      sliceBeneficiaries,
      getLastXStringCharacters,
    }
  },
}
</script>
