<template>
  <div class="block py-6 border-b" v-show="true">
    <div class="text-xs pb-2 text-gray-400 font-bold font-montserrat">
      CARD INFORMATION
    </div>

    <div class="flex items-center">
      <div class="w-16 h-12 rounded-md bg-gray-100 grid place-content-center">
        <img
          v-if="paymentInfo.cardType == 'MasterCard'"
          class="w-full"
          src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/card-mastercard.svg"
        />
        <img
          v-if="paymentInfo.cardType == 'Visa'"
          class="w-full"
          src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/card-visa.svg"
        />
      </div>
      <div class="pl-4 flex-grow">
        <div class="text-lg mb-1">{{ userDetails.name }}</div>
        <div class="text-sm text-gray-500">
          {{ getFirstXStringCharacters(paymentInfo.cardNumber, 4) }} **** ****
          {{ getLastXStringCharacters(paymentInfo.cardNumber, 4) }}
        </div>
      </div>
      <div class="flex-shrink">
        <button
          class="
            hidden
            md:inline-block
            px-4
            py-2
            text-xs
            bg-gray-200
            rounded-md
            border-gray-300 border
            text-gray-700
          "
          @click="openAddCardModal()"
        >
          New card
        </button>
        <button
          class="
            inline-block
            md:hidden
            px-4
            py-2
            text-xs
            bg-gray-200
            rounded-md
            border-gray-300 border
            text-gray-700
          "
          @click="goToAddCard()"
        >
          New card
        </button>
      </div>
    </div>
  </div>
  <AddCardModal v-if="showAddCardModal" />
</template>

<script>
import utils from '@/composables/utils'
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import AddCardModal from '@/components/desktop/AddCardModal.vue'
import { useRouter } from 'vue-router'
export default {
  components: { AddCardModal },
  setup() {
    const store = useStore()
    const router = useRouter()
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )
    const userDetails = computed(() => store.state.auth.userDetails)

    const { getFirstXStringCharacters, getLastXStringCharacters } = utils()

    const openAddCardModal = () => {
      store.dispatch('paymentInfo/openAddCardModal')
    }
    const goToAddCard = () => {
      const currentRoutePath = router.currentRoute.value.path
      router.push({
        name: 'add-paymentInfo',
        params: { redirectPath: currentRoutePath },
      })
    }
    return {
      userDetails,
      paymentInfo,
      goToAddCard,
      openAddCardModal,
      showAddCardModal,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
