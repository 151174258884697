<template>
  <AuthWrapper>
    <div class="block my-6">
      <div class="font-montserrat text-2xl font-bold">Resend Code</div>
      <div class="text-sm text-gray-700 my-4">
        Don't have an account?
        <router-link :to="{ name: 'signup' }" class="text-blue-500"
          >Sign up</router-link
        >
      </div>
    </div>
    <!-- form -->
    <form @submit.prevent="getCode()" autocomplete="off" class="block py-4">
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">PHONE NUMBER</div>
        <div class="flex">
          <div class="pr-4 sm:pr-1">
            <CallingCode />
          </div>
          <div class="">
            <input
              v-model="phone"
              type="tel"
              required
              class="outline-none w-full"
              placeholder="Phone number"
            />
          </div>
        </div>
      </div>
      <Alert alertType="error" v-if="getError()">{{ getError() }}</Alert>
      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Next
      </button>
    </form>
  </AuthWrapper>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import { computed, onBeforeUnmount } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import Alert from '@/components/partials/Alert.vue'
import CallingCode from '@/components/partials/CallingCode.vue'
import AuthWrapper from './AuthWrapper'

export default {
  components: {
    Alert,
    CallingCode,
    AuthWrapper,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const phone = ref('')
    const token = computed(() => store.state.auth.token)
    const error = computed(() => store.state.auth.error)
    const success = computed(() => store.state.auth.success)
    const countryDetails = computed(() => store.state.countries.countryDetails)

    const getCode = async () => {
      const body = {
        phone: `+${countryDetails.value.prefix}${phone.value}`,
      }
      const { setRequestConfig } = utils()
      const config = setRequestConfig(token.value, null)
      const payload = {
        body,
        config,
      }
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/getCode', payload)
      if (success.value !== '') {
        if (success.value.component === 'forgotPassword') {
          router.push({ name: 'verify' })
        }
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'resendCode') {
          return error.value.data.message
        }
      }
    }

    onBeforeUnmount(() => {
      store.dispatch('auth/setError', '')
      store.dispatch('auth/setSuccess', '')
    })

    return {
      error,
      phone,
      getCode,
      getError,
    }
  },
}
</script>
