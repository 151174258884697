<template>
  <div class="block my-4 border-b">
    <div class="relative py-24">
      <div class="block text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block"
          height="70"
          viewBox="0 0 24 24"
          width="70"
          fill="#ccc"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
          />
        </svg>
      </div>
      <div class="block text-center">
        <div class="text-sm font-montserrat">
          You have no saved recipients.<br />Once you do, they will appear here.
        </div>
        <button
          class="
            hidden
            md:inline-block
            bg-yellow-300
            w-2/3
            px-4
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="openAddBeneficiaryModal()"
        >
          Add recipient</button
        ><button
          class="
            inline-block
            md:hidden
            bg-yellow-300
            w-2/3
            px-4
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="goToAddRecipient()"
        >
          Add recipient
        </button>
      </div>
    </div>
  </div>
  <AddRecipientModal v-if="showAddBeneficiaryModal" />
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AddRecipientModal from '@/components/desktop/AddRecipientModal.vue'
import { computed } from '@vue/reactivity'
export default {
  components: { AddRecipientModal },
  setup() {
    const store = useStore()
    const router = useRouter()
    const goToAddRecipient = () => {
      const currentRouteName = router.currentRoute.value.name
      return router.push({
        name: 'add-recipient',
        params: { returnUrl: currentRouteName },
      })
    }
    const showAddBeneficiaryModal = computed(
      () => store.state.beneficiary.showAddBeneficiaryModal,
    )
    const openAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/openAddBeneficiaryModal')
    }
    return {
      goToAddRecipient,
      showAddBeneficiaryModal,
      openAddBeneficiaryModal,
    }
  },
}
</script>
