<template>
  <router-link :to="{ name: 'refer' }" class="text-white cursor-pointer">
    <div
      class="
        relative
        block
        bg-purple-900
        text-white
        bg-opacity-80
        px-3
        py-3
        flex
        items-center
      "
    >
      <img
        class="mr-4"
        src="../../../assets/gift-box@2x.png"
        width="55"
        height="auto"
      />
      <div class="block">
        <div class="text-lg font-montserrat font-medium">Refer & Earn</div>
        <div class="text-xs opacity-70 mb-1">
          Invite friends and earn KES 100 when they sign up and send money.
          <span class="underline">Learn more</span>
        </div>
      </div>
      <svg
        class="ml-4 mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 15.967 14.009"
      >
        <path
          id="Arrow_Left"
          data-name="Arrow Left"
          d="M9.7,16.707V16.7a1.008,1.008,0,0,1-1.42,0l-6-6h0a1,1,0,0,1-.01-1.42l6-6v-.01a.99.99,0,0,1,1.4,1.4l-4.3,4.29H16.946a.988.988,0,0,1,1,.99,1,1,0,0,1-1,1H5.35l4.29,4.29-.01-.01a1,1,0,0,1,0,1.41Z"
          transform="translate(17.946 16.99) rotate(180)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </router-link>

  <!-- <div
    class="bg-black pt-2 pb-4 px-4 text-white cursor-pointer"
    @click="openReferralModal()"
  >
    <div
      class="
        relative
        block
        rounded-xl
        bg-purple-900 bg-opacity-80
        px-3
        py-2
        flex
        items-center
      "
    >
      <img
        class="mr-4"
        src="../../../assets/gift-box@2x.png"
        width="55"
        height="auto"
      />
      <div class="block">
        <div class="text-lg font-montserrat font-medium">Refer & Earn</div>
        <div class="text-xs opacity-70 mb-1">
          Invite friends and earn KES 100 when they sign up and send money.
          <span class="underline">Learn more</span>
        </div>
      </div>
      <svg
        class="ml-4"
        mr-3
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 15.967 14.009"
      >
        <path
          id="Arrow_Left"
          data-name="Arrow Left"
          d="M9.7,16.707V16.7a1.008,1.008,0,0,1-1.42,0l-6-6h0a1,1,0,0,1-.01-1.42l6-6v-.01a.99.99,0,0,1,1.4,1.4l-4.3,4.29H16.946a.988.988,0,0,1,1,.99,1,1,0,0,1-1,1H5.35l4.29,4.29-.01-.01a1,1,0,0,1,0,1.41Z"
          transform="translate(17.946 16.99) rotate(180)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </svg>
    </div>
  </div> -->
</template>

<script>
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const openReferralModal = () => {
      store.dispatch('referral/openReferralModal')
    }
    return {
      openReferralModal,
    }
  },
}
</script>
