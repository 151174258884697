<template>
  <button
    class="block p-2 tracking-wider rounded-md font-medium"
    @click="closeAlert()"
  >
    <slot>OK</slot>
  </button>
</template>
<script>
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const closeAlert = () => {
      store.dispatch('alerts/closeAlert')
    }
    return {
      closeAlert,
    }
  },
}
</script>
