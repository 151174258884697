<template>
  <div class="relative max-w-xl mx-auto my-4">
    <div class="block px-6 py-4 mb-2" @click="$router.go(-1)">
      <svg
        version="1.1"
        width="18"
        height="18"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 443.52 443.52"
        style="enable-background: new 0 0 443.52 443.52"
        xml:space="preserve"
      >
        <path
          d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712L143.492,221.863z"
        />
      </svg>
    </div>

    <div class="relative">
      <div class="block px-6">
        <div class="font-montserrat text-2xl font-bold">Help & Support</div>
      </div>

      <div class="block mt-6">
        <div class="flex px-6 py-3 border-b gap-4 items-center">
          <div class="w-10 h-10 rounded-3xl bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-md text-gray-700">Frequently asked questions</div>
            <div class="text-xs text-gray-400">Get solutions quickly</div>
          </div>
          <div class="opacity-50">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
        <div class="flex px-6 py-3 border-b gap-4 items-center">
          <div class="w-10 h-10 rounded-3xl bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-md text-gray-700">Live chat</div>
            <div class="text-xs text-gray-400">Chat with our support team</div>
          </div>
          <div class="opacity-50">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="block mt-6">
        <div class="text-xs px-6 py-2 text-gray-400 font-bold">REACH US</div>
        <div class="flex px-6 py-2 border-b gap-4 items-center">
          <div class="w-5 h-5 rounded-full bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-sm text-gray-700">
              Follow @helasendke on Twitter
            </div>
          </div>
          <div class="opacity-25">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
        <div class="flex px-6 py-2 border-b gap-4 items-center">
          <div class="w-5 h-5 rounded-full bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-sm text-gray-700">Like us on Facebook</div>
          </div>
          <div class="opacity-25">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
        <div class="flex px-6 py-2 border-b gap-4 items-center">
          <div class="w-5 h-5 rounded-full bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-sm text-gray-700">Message us on Whatsapp</div>
          </div>
          <div class="opacity-25">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
        <div class="flex px-6 py-2 border-b gap-4 items-center">
          <div class="w-5 h-5 rounded-full bg-gray-300"></div>
          <div class="flex-grow">
            <div class="text-sm text-gray-700">Call +44740123418</div>
          </div>
          <div class="opacity-25">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              style="enable-background: new 0 0 256 256"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
