import utils from './utils'
const { roundToTwo } = utils()
const createTransaction = () => {
  const buildTransactionObject = (
    transaction,
    payoutAmount,
    payAmount,
    sendAmount,
    ourFee,
    cardFee,
    totalFee,
  ) => {
    const transactionOnCreation = {
      ...transaction,
      payoutAmount: parseFloat(payoutAmount),
      payAmount: parseFloat(payAmount),
      sendAmount: parseFloat(sendAmount),
      ourFee,
      cardFee,
      totalFee,
    }
    return transactionOnCreation
  }

  const calculateExchangeRate = (
    isSameCountry,
    senderMarketRate,
    recipientExchangeRate,
  ) => {
    if (isSameCountry) {
      return 1
    }
    senderMarketRate = parseFloat(senderMarketRate)
    recipientExchangeRate = parseFloat(recipientExchangeRate)
    const result = recipientExchangeRate / senderMarketRate
    return roundToTwo(result)
  }

  const calculateCardFee = (payAmount, cardTransactionFeePercent) => {
    if (payAmount === null || payAmount === undefined) {
      return 0
    }
    const result = (cardTransactionFeePercent / 100) * payAmount
    return roundToTwo(result)
  }

  const calculateTotalFee = (cardFee, ourFee) => {
    return cardFee + ourFee
  }

  const calculateSendAmount = (payoutAmount, rate) => {
    const sendAmount = parseFloat(payoutAmount) / rate
    return roundToTwo(sendAmount)
  }

  const calculatePayoutAmount = (sendAmount, rate) => {
    const payoutAmount = parseFloat(sendAmount) * rate
    return roundToTwo(payoutAmount)
  }

  const calculatePayAmount = (rate, payoutAmount, senderCurrencyDetails) => {
    const cardTransactionFeePercent =
      senderCurrencyDetails.cardTransactionFeePercent
    const oneMinusFee = 1 - cardTransactionFeePercent / 100
    const feeCheckAgainstValue = payoutAmount / rate / oneMinusFee
    let ourFee = null
    const guessFee = getFee(feeCheckAgainstValue, senderCurrencyDetails)
    if (
      guessFee ===
      getFee(feeCheckAgainstValue + guessFee, senderCurrencyDetails)
    ) {
      ourFee = guessFee
    } else {
      ourFee = getFee(feeCheckAgainstValue + guessFee, senderCurrencyDetails)
    }
    const payAmount = (payoutAmount / rate + ourFee) / oneMinusFee
    return payAmount
  }

  //TODO rename this to more meaningful name
  const getBeneficiaryExchangeRate = (country, currencies) => {
    const beneficiaryExchangeRate = currencies.filter((item) => {
      return item.isoCode === country
    })
    return beneficiaryExchangeRate[0]
  }

  const setRecipientExchangeRates = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (null !== beneficiaryCurrencyDetails) {
      return beneficiaryCurrencyDetails.rates
    }
    return defaultCountryDetails.rates
  }

  const setRecipientExchangeCountryName = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (null !== beneficiaryCurrencyDetails) {
      return beneficiaryCurrencyDetails.name
    }
    return defaultCountryDetails.name
  }
  const setBeneficiaryCurrencyDetails = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (beneficiaryCurrencyDetails !== null) {
      return beneficiaryCurrencyDetails
    }
    return defaultCountryDetails
  }

  const setSendAmount = (
    isSameCountry,
    senderCurrencyDetails,
    payoutAmount,
    beneficiaryCurrencyDetails,
    transactionOnCreation,
  ) => {
    let sendAmount,
      payAmount = null
    const rate = calculateExchangeRate(
      isSameCountry,
      senderCurrencyDetails.marketRates,
      beneficiaryCurrencyDetails.rates,
    )
    if (payoutAmount) {
      sendAmount = calculateSendAmount(payoutAmount, rate)
      payAmount = calculatePayAmount(rate, payoutAmount, senderCurrencyDetails)
    } else {
      payAmount = 0
      sendAmount = null
    }

    const cardTransactionFeePercent =
      senderCurrencyDetails.cardTransactionFeePercent
    const cardFee = calculateCardFee(payAmount, cardTransactionFeePercent)
    const ourFee = getFee(payAmount, senderCurrencyDetails)
    const totalFee = calculateTotalFee(cardFee, ourFee)

    const transactionObject = buildTransactionObject(
      transactionOnCreation,
      payoutAmount,
      payAmount,
      sendAmount,
      ourFee,
      cardFee,
      totalFee,
    )
    return {
      amountToPay: payAmount,
      AmountToSend: sendAmount,
      transactionObject,
      ourSetFee: ourFee,
      calculatedCardFee: cardFee,
      calculatedTotalFee: totalFee,
    }
  }

  const getFee = (sendAmount, senderCurrencyDetails) => {
    let fee = 0
    const feeRateCap = senderCurrencyDetails.feeRateCap
    if (!sendAmount || sendAmount === 0) {
      fee = 0
    } else if (!feeRateCap.length) {
      fee = senderCurrencyDetails.fee
    } else {
      let feeObject = feeRateCap.filter((fee) => {
        if (sendAmount >= fee.minimum && fee.maximum === -1) {
          return true
        } else {
          return sendAmount > fee.minimum && sendAmount <= fee.maximum
        }
      })
      fee = feeObject[0].amount
    }
    return fee
  }

  const setPayoutAmount = (
    isSameCountry,
    payAmount,
    senderCurrencyDetails,
    beneficiaryCurrencyDetails,
    transactionOnCreation,
  ) => {
    const rate = calculateExchangeRate(
      isSameCountry,
      senderCurrencyDetails.marketRates,
      beneficiaryCurrencyDetails.rates,
    )
    const ourFee = getFee(payAmount, senderCurrencyDetails)
    const cardFee = calculateCardFee(
      payAmount,
      senderCurrencyDetails.cardTransactionFeePercent,
    )
    const totalFee = calculateTotalFee(cardFee, ourFee)
    let payoutAmount,
      sendAmount = null
    if (payAmount) {
      sendAmount = payAmount - totalFee
      payoutAmount = calculatePayoutAmount(sendAmount, rate)
    } else {
      sendAmount = 0
      payoutAmount = null
    }

    const transactionObject = buildTransactionObject(
      transactionOnCreation,
      payoutAmount,
      payAmount,
      sendAmount,
      ourFee,
      cardFee,
      totalFee,
    )
    return {
      AmountToPayout: payoutAmount,
      transactionObject,
      ourSetFee: ourFee,
      calculatedCardFee: cardFee,
      calculatedTotalFee: totalFee,
      amountToSend: sendAmount,
    }
  }

  return {
    getFee,
    setSendAmount,
    setPayoutAmount,
    calculateCardFee,
    calculateTotalFee,
    calculateExchangeRate,
    buildTransactionObject,
    setRecipientExchangeRates,
    getBeneficiaryExchangeRate,
    setBeneficiaryCurrencyDetails,
    setRecipientExchangeCountryName,
  }
}
export default createTransaction
