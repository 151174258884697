<template>
  <div class="relative -mt-4 md:col md:mt-8 md:px-4 md:hidden">
    <div
      class="
        relative
        bg-white
        overflow-auto
        md:mx-auto
        border-b
        md:border-none
        px-6
        pb-6
      "
    >
      <div class="">
        <img src="../assets/gift-box@2x.png" class="mx-auto" width="60" />

        <div
          class="
            text-xl
            leading-6
            font-montserrat font-bold
            text-center
            my-4
            opacity-80
          "
        >
          Earn when your<br />friend joins Helasend
        </div>
        <div class="text-xs text-center my-2 opacity-70">
          Refer your friends and earn when they send at least AED 100 with
          Helasend. Use your referral code below or click the links to share.
        </div>

        <div class="block text-center my-4">
          <div
            class="
              rounded-md
              inline-block
              mx-auto
              bg-yellow-100
              px-4
              py-2
              text-xl
              font-mono font-bold
            "
          >
            {{ userDetails.country.affiliateShareCode }}
          </div>
        </div>

        <div class="grid grid-cols-4 gap-4 mt-4 place-content-center">
          <div class="col text-center" @click="shareOnWhatsApp()">
            <div class="inline-block h-10 w-10 p-1">
              <svg
                fill="#25D366"
                viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M723.99 360c-13.24 0-24 10.76-24 23.99 0 5.24 1.69 10.11 4.57 14.06l-3 8.91 9.22-2.95c3.79 2.51 8.32 3.96 13.2 3.96 13.23 0 23.99-10.77 23.99-24 0-13.24-10.77-24-24-24h-.02v-.01Zm-6.71 12.19c-.47-1.12-.82-1.16-1.53-1.19 -.25-.02-.51-.03-.81-.03 -.92 0-1.88.26-2.46.86 -.71.71-2.46 2.39-2.46 5.84 0 3.44 2.51 6.77 2.84 7.23 .35.46 4.89 7.63 11.94 10.55 5.51 2.28 7.15 2.07 8.4 1.8 1.83-.4 4.13-1.76 4.71-3.39 .57-1.64.57-3.04.4-3.34 -.17-.3-.64-.47-1.34-.82 -.71-.36-4.14-2.05-4.79-2.28 -.64-.24-1.25-.16-1.73.52 -.68.94-1.34 1.9-1.88 2.48 -.43.45-1.12.5-1.7.26 -.78-.33-2.95-1.09-5.63-3.48 -2.08-1.85-3.49-4.15-3.9-4.84 -.41-.71-.05-1.12.28-1.5 .35-.44.69-.75 1.04-1.16 .35-.41.55-.63.77-1.11 .24-.47.07-.95-.1-1.3 -.17-.36-1.58-3.8-2.16-5.2Z"
                  transform="translate(-700-360)"
                />
              </svg>
            </div>
            <div class="block text-xs opacity-50">Whatsapp</div>
          </div>
          <div class="col text-center" @click="shareOnFacebook()">
            <div class="inline-block h-10 w-10 p-1">
              <svg
                fill="#00B2FF"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C5.373 0 0 4.974 0 11.11c0 3.49 1.744 6.61 4.469 8.65v4.23l4.088-2.25c1.09.3 2.24.46 3.44.46 6.62 0 12-4.98 12-11.11 0-6.14-5.38-11.12-12-11.12Zm1.19 14.96l-3.06-3.26 -5.963 3.26 6.55-6.97 3.13 3.25 5.88-3.26 -6.561 6.96Z"
                />
              </svg>
            </div>
            <div class="block text-xs opacity-50">Messenger</div>
          </div>
          <div class="col text-center" @click="shareOnTwitter()">
            <div class="inline-block h-10 w-10 p-1">
              <svg viewBox="0 0 48 40" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#00AAEC"
                  fill-rule="evenodd"
                  d="M348 168.73c-1.77.8-3.67 1.34-5.66 1.58 2.03-1.25 3.59-3.23 4.33-5.59 -1.91 1.15-4.01 1.99-6.26 2.44 -1.8-1.97-4.36-3.19-7.19-3.19 -5.44 0-9.85 4.52-9.85 10.09 0 .79.08 1.56.25 2.29 -8.19-.43-15.44-4.44-20.3-10.56 -.85 1.49-1.34 3.23-1.34 5.08 0 3.5 1.73 6.59 4.38 8.4 -1.62-.05-3.14-.51-4.47-1.26v.12c0 4.89 3.39 8.97 7.9 9.9 -.83.23-1.7.35-2.6.35 -.64 0-1.26-.07-1.86-.18 1.25 4.01 4.88 6.93 9.19 7 -3.37 2.71-7.62 4.32-12.24 4.32 -.8 0-1.59-.05-2.35-.14 4.35 2.86 9.53 4.53 15.09 4.53 18.11 0 28.01-15.39 28.01-28.73 0-.44-.01-.88-.03-1.31 1.92-1.43 3.59-3.21 4.91-5.23"
                  transform="translate(-300-164)"
                />
              </svg>
            </div>
            <div class="block text-xs opacity-50">Twitter</div>
          </div>
          <div class="col text-center" @click="copyToClipboard()">
            <div class="inline-block rounded-full h-10 w-10 bg-gray-200 p-2">
              <svg
                fill="#888"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="M4.22 19.778c.97.97 2.255 1.46 3.535 1.46 1.28-.01 2.56-.49 3.53-1.47l2.82-2.83 -1.42-1.42 -2.83 2.82c-1.17 1.16-3.08 1.16-4.243 0 -1.17-1.17-1.17-3.08 0-4.243l2.82-2.83 -1.42-1.42 -2.83 2.82c-1.95 1.94-1.95 5.122-.001 7.07Zm15.556-8.485c1.94-1.95 1.94-5.13 0-7.08 -1.95-1.95-5.13-1.948-7.08 0l-2.83 2.82 1.41 1.41 2.82-2.83c1.17-1.17 3.07-1.17 4.243 0 1.16 1.17 1.16 3.07 0 4.24l-2.83 2.82 1.41 1.41 2.82-2.83Z"
                  />
                  <path
                    d="M11 5.99h2v12.001h-2Z"
                    transform="rotate(-135 11.999 11.999) skewX(0)"
                  />
                </g>
              </svg>
            </div>
            <div class="block text-xs opacity-50">Copy link</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import definedConstants from '@/composables/definedConstants'
export default {
  setup() {
    const store = useStore()
    const userDetails = computed(() => store.state.auth.userDetails)
    const { appBaseURL } = definedConstants()
    const affiliateShareCode = userDetails.value.country.affiliateShareCode
    const shareURL = `${appBaseURL}/signup/${affiliateShareCode}`
    const shareText = `${userDetails.value.name} has invited you to join Helasend today.`

    const shareOnWhatsApp = () => {
      const whatsappUrl = `https://api.whatsapp.com/send?text=${shareURL}`
      window.open(whatsappUrl, '_blank')
    }

    const shareOnFacebook = () => {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}&t=${shareText}`
      window.open(facebookUrl, '_blank')
    }

    // const shareOnMessenger = () => {
    //   const messengerUrl = `https://www.messenger.com/t/${encodeURIComponent(
    //     createShareLink(),
    //   )}`
    //   window.open(messengerUrl, '_blank')
    // }

    const shareOnTwitter = () => {
      const twitterUrl = `https://twitter.com/share?url=${shareURL}&text=${shareText}`
      window.open(twitterUrl, '_blank')
    }

    const copyToClipboard = () => {
      const textArea = document.createElement('textarea')
      textArea.value = shareURL
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
    }

    return {
      userDetails,
      shareOnTwitter,
      shareOnWhatsApp,
      shareOnFacebook,
      copyToClipboard,
    }
  },
}
</script>
