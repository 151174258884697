<template>
  <div class="block">
    <div class="relative p-8">
      <div class="block text-center">
        <svg
          class="inline-block w-16"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="#ccc"
        >
          <g>
            <path
              d="M21.5 4h-19C1.122 4 0 5.12 0 6.5v11C0 18.87 1.122 20 2.5 20h19c1.37 0 2.5-1.13 2.5-2.5v-11C24 5.12 22.87 4 21.5 4ZM23 17.5c0 .82-.68 1.5-1.5 1.5h-19c-.827 0-1.5-.68-1.5-1.5v-11C1 5.673 1.673 5 2.5 5h19c.82 0 1.5.673 1.5 1.5v11Z"
            />
            <path
              d="M3.5 15h4c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5h-4c-.28 0-.5.22-.5.5 0 .27.22.5.5.5Z"
            />
            <path
              d="M12.5 16h-9c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h9c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"
            />
            <path
              d="M3.5 12h2c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5h-2c-.28 0-.5.22-.5.5 0 .27.22.5.5.5Z"
            />
            <path
              d="M7.5 12h2c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5h-2c-.28 0-.5.22-.5.5 0 .27.22.5.5.5Z"
            />
            <path
              d="M13.5 11h-2c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h2c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"
            />
            <path
              d="M19 13c-.37 0-.71.1-1 .27 -.31-.18-.65-.28-1.01-.28 -1.11 0-2 .89-2 2 0 1.1.89 2 2 2 .35 0 .69-.1 1-.28 .29.17.63.27.99.27 1.1 0 2-.9 2-2 0-1.11-.9-2-2-2Zm-3 2c0-.56.44-1 1-1 .08 0 .17.01.25.03 -.01.01-.02.03-.02.04 -.06.11-.11.22-.15.35 -.01.03-.03.06-.04.09 -.04.15-.07.3-.07.46 0 .16.02.31.06.46 0 .03.02.06.03.09 .03.12.08.24.14.35 0 .01.01.03.01.04 -.62.16-1.26-.33-1.26-.97Zm3 1c-.27 0-.5-.11-.68-.27 -.1-.09-.17-.2-.23-.31 -.01-.01-.02-.02-.02-.03 -.06-.125-.09-.26-.09-.4s.03-.28.08-.4c0-.01.01-.02.01-.03 .05-.12.12-.22.22-.31 .17-.17.41-.27.67-.27 .55 0 1 .44 1 1 0 .55-.45 1-1 1Z"
            />
          </g>
        </svg>
      </div>
      <div class="block text-center">
        <div class="text-sm font-montserrat">
          You have no card.<br />Once you do, it will appear here.
        </div>
        <div class="text-xs mt-6 text-gray-500">Accepted cards</div>
        <div class="text-center mb-4 mt-2">
          <img
            class="inline-block h-5 mx-1"
            src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/mastercard.svg"
          />
          <img
            class="inline-block h-5 mx-1"
            src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/visa.svg"
          />
        </div>
        <button
          class="
            md:inline-block
            hidden
            bg-yellow-300
            w-full
            px-4
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="openAddCardModal()"
        >
          Add a card
        </button>
        <button
          class="
            md:hidden
            block
            bg-yellow-300
            w-full
            px-4
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="goToAddPayment()"
        >
          Add a card
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()

    const goToAddPayment = () => {
      store.dispatch('paymentInfo/closeCVVModal')
      const currentRoutePath = router.currentRoute.value.path
      router.push({
        name: 'add-paymentInfo',
        params: { redirectPath: currentRoutePath },
      })
    }

    const openAddCardModal = () => {
      store.dispatch('paymentInfo/closeCVVModal')
      store.dispatch('paymentInfo/openAddCardModal')
    }

    return {
      goToAddPayment,
      openAddCardModal,
    }
  },
}
</script>
