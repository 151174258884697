<template>
  <div class="md:hidden block">
    <RecipientsComponent />
  </div>
  <div class="hidden md:block">
    <DashboardWrapper><RecipientsComponent /></DashboardWrapper>
  </div>
</template>

<script>
import DashboardWrapper from '../desktop/DashboadWrapper.vue'
import RecipientsComponent from '@/components/dashboard/Recipients'
import { useStore } from 'vuex'

export default {
  components: { DashboardWrapper, RecipientsComponent },
  setup() {
    const store = useStore()
    //TODO check the use of this!
    store.dispatch('paymentInfo/unsetCVC')
  },
}
</script>
