<template>
  <div
    class="
      left-0
      top-0
      w-full
      h-full
      mx-auto
      transition
      duration-700
      ease-in
      transform
    "
    style="z-index: 2147483004"
  >
    <div
      :class="{
        'bg-red-100': alertType === 'error',
        'bg-green-100': alertType === 'success',
        'bg-blue-100': alertType === 'info',
      }"
      class="text-gray-700 text-sm rounded-md px-3 py-2"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  props: ['alertType'],
  setup() {
    const showAlert = ref(true)
    const show = () => {
      // Show alert
      var el = document.getElementById('alert')
      el.classList.add('animate__fadeInDown')
      setTimeout(() => {
        //   Remove alert
        el.classList.remove('animate__fadeInDown')
        el.classList.add('animate__fadeOutUp')
      }, 3000)

      setTimeout(() => {
        // Reset
        el.classList.remove('animate__fadeOutUp')
        showAlert.value = false
      }, 1000)
    }

    return {
      show,
      showAlert,
    }
  },
}
</script>
