import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import { ref } from '@vue/reactivity'

const veriff = () => {
  const status = ref(null)
  const setRequestData = (userID, kycSession, config) => {
    const body = {
      userID,
      kycSession,
    }
    const payload = {
      body,
      config,
    }
    return payload
  }

  const createFrame = (url) => {
    let loading = true
    createVeriffFrame({
      url,
      onEvent: function (msg) {
        switch (msg) {
          case MESSAGES.STARTED:
            console.log('started')
            break
          case MESSAGES.CANCELED:
            console.log('cancelled')
            break
          case MESSAGES.FINISHED:
            console.log('FINISHED')
            status.value = 'FINISHED'
            break
        }
      },
    })
    return loading
  }

  const setVeriffPerson = (userNames) => {
    const namesArray = userNames.split(' ')
    const firstName = namesArray[0]
    const lastName = namesArray[1]
    const person = {
      firstName,
      lastName,
    }
    return person
  }
  return {
    status,
    createFrame,
    setRequestData,
    setVeriffPerson,
  }
}
export default veriff
