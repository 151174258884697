<template>
  <svg
    v-if="showPassword"
    @click="toggleInputType()"
    class="h-5 w-5 text-gray-500 cursor-pointer"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
    ></path>
  </svg>

  <svg
    v-else
    @click="toggleInputType()"
    xmlns="http://www.w3.org/2000/svg"
    class="h-5 w-5 text-gray-500 cursor-pointer"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
      clip-rule="evenodd"
    />
    <path
      d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
    />
  </svg>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
  setup() {
    const showPassword = ref(false)
    const toggleInputType = () => {
      showPassword.value = !showPassword.value
      const passwordElement = document.getElementById('password')
      const inputType = passwordElement.type
      inputType === 'text'
        ? (passwordElement.type = 'password')
        : (passwordElement.type = 'text')
    }
    return {
      showPassword,
      toggleInputType,
    }
  },
}
</script>
