<template>
  <ul class="list-none border-t">
    <li
      v-for="beneficiary in beneficiaries"
      :key="beneficiary.benefitiaryID"
      class="flex px-4 py-3 border-b gap-4 items-center"
    >
      <div
        @click="
          selectBeneficiary(
            beneficiary.account,
            beneficiary.benefitiaryID,
            beneficiary.country,
            beneficiary.names,
            beneficiary.payoutType,
          )
        "
        class="relative pb-2/3 rounded-sm w-10 h-7 bg-gray-200 overflow-hidden"
      >
        <img
          :src="setCountryFlagURL(beneficiary.country)"
          class="absolute block h-full w-full object-cover"
        />
      </div>
      <div
        @click="
          selectBeneficiary(
            beneficiary.account,
            beneficiary.benefitiaryID,
            beneficiary.country,
            beneficiary.names,
            beneficiary.payoutType,
          )
        "
        class="flex-grow"
      >
        <div class="text-md">{{ beneficiary.names }}</div>
        <div class="text-xs">{{ beneficiary.account }}</div>
      </div>
      <div
        class="h-4 w-4 text-red-500 cursor-pointer"
        @click="removeBeneficiary(beneficiary.benefitiaryID)"
        title="Remove This Recipient"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            fill="currentColor"
            d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
          />
        </svg>
      </div>
    </li>
  </ul>
  <Spin v-if="loading" />
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'
import Spin from '@/components/partials/Spin.vue'

export default {
  components: { Spin },
  setup() {
    const store = useStore()
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const loading = computed(() => store.state.beneficiary.loading)
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)
    // const currencies = computed(() => store.state.countries.currencies)
    const destinationCurrencies = computed(
      () => store.state.countries.destinationCurrencies,
    )
    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )

    const closeBeneficiariesModal = () =>
      store.dispatch('beneficiary/closeBeneficiariesModal')

    const {
      setCountryFlagURL,
      getBeneficiaryExchangeRate,
      setRequestConfig,
      isSameCountry,
    } = utils()

    const setBeneficiaryCurrencyDetails = (country, currencies) => {
      const payload = getBeneficiaryExchangeRate(country, currencies)
      store.dispatch('beneficiary/setBeneficiaryCurrencyDetails', payload)
    }

    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const buildTransactionObject = (
      userID,
      benefitiaryID,
      sourceExchangeRate,
      destinationExchangeRate,
      exchangeRate,
    ) => {
      const transactionInBuild = {
        ...transactionOnCreation.value,
        userID,
        benefitiaryID,
        sourceExchangeRate,
        destinationExchangeRate,
        exchangeRate,
        purposeforFunds: 'Test',
        sourceOfFunds: 'Test',
        remarks: 'Test',
      }
      store.dispatch('transactions/buildTransactionObject', transactionInBuild)
    }

    const selectBeneficiary = (
      account,
      benefitiaryID,
      country,
      names,
      payoutType,
    ) => {
      const flag = setCountryFlagURL(country)
      const beneficiaryDetails = {
        account,
        benefitiaryID,
        country,
        flag,
        names,
        payoutType,
      }
      setBeneficiaryCurrencyDetails(country, destinationCurrencies.value)
      store.dispatch('beneficiary/setSelectedBeneficiary', beneficiaryDetails)
      closeBeneficiariesModal()

      const { calculateExchangeRate } = createTransaction()
      const countryIsSame = isSameCountry(
        senderCurrencyDetails.value.name,
        beneficiaryCurrencyDetails.value.name,
      )

      const exchangeRate = calculateExchangeRate(
        countryIsSame,
        senderCurrencyDetails.value.marketRates,
        beneficiaryCurrencyDetails.value.rates,
      )

       const senderMarketRates = countryIsSame
        ? senderCurrencyDetails.value.rates
        : senderCurrencyDetails.value.marketRates

      buildTransactionObject(
        userDetails.value.userID,
        benefitiaryID,
        senderMarketRates,
        beneficiaryCurrencyDetails.value.rates,
        exchangeRate,
      )
    }

    const removeBeneficiary = (beneficiaryID) => {
      const config = setRequestConfig(token.value, userDetails.value.jwt)
      const body = { beneficiaryID }
      const payload = {
        body,
        config,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/removeBeneficiary', payload)
    }

    const fetchBeneficiaries = () => {
      const payload = {
        userID: userDetails.value.userID,
        jwt: userDetails.value.jwt,
        token: token.value,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/fetchBeneficiaries', payload)
    }
    fetchBeneficiaries()

    return {
      loading,
      beneficiaries,
      setCountryFlagURL,
      selectBeneficiary,
      removeBeneficiary,
      closeBeneficiariesModal,
    }
  },
}
</script>
