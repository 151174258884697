<template>
  <TransactionsWrapper>
    <div class="relative">
      <div class="relative">
        <div v-if="selectedBeneficiary === null" class="block">
          <div class="grid grid-cols-2 items-center text-center gap-4">
            <div
              v-if="beneficiaries.length"
              class="
                col
                p-12
                border
                bg-gray-50
                rounded-md
                hover:border-blue-300 hover:bg-gray-100
                cursor-pointer
                transition
              "
              @click="openBeneficiaryModal()"
            >
              <svg
                fill="#333"
                width="48"
                height="48"
                class="inline-block mb-3 opacity-50"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 6v0c0 1.65-1.35 3-3 3C4.34 9 3 7.65 3 6l0 0c-.01-1.66 1.34-3.01 2.99-3.01 1.65-.01 3 1.34 3 2.99 0 0 0 0 0 0Zm8 0v0c0 1.65-1.35 3-3 3 -1.66 0-3-1.35-3-3l0 0c-.01-1.66 1.34-3.01 2.99-3.01 1.65-.01 3 1.34 3 2.99 0 0 0 0 0 0Zm-4.07 11c.04-.33.07-.66.07-1l0-.01c0-1.58-.53-3.1-1.51-4.33l-.01 0c2.39-1.39 5.44-.57 6.83 1.83 .43.76.66 1.62.66 2.49v1H12.9ZM6 11l-.01 0c2.76-.01 5 2.23 5 4.99v1h-10v-1l0 0c-.01-2.77 2.23-5.01 4.99-5.01Z"
                />
              </svg>
              <div class="text-md font-montserrat">Select recipient</div>
            </div>
            <!-- for mobile -->
            <div
              @click="goToAddBeneficiary()"
              class="
                md:hidden
                col
                p-12
                border
                bg-gray-50
                rounded-md
                hover:border-blue-300 hover:bg-gray-100
                cursor-pointer
                transition
              "
            >
              <svg
                fill="#00f"
                width="48"
                height="48"
                class="inline-block mb-3 opacity-50"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 9l-.01-.01c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm0 2h-.01c3.31-.01 6 2.68 6 6h-12v0c-.01-3.32 2.68-6.01 5.99-6.01Zm8-4v0c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v1h-1 -.01c-.56 0-1 .44-1 1 0 .55.44 1 1 .99h1v1 -.01c-.01.55.44 1 .99 1s1-.45 1-1c0-.01 0-.01 0-.01v-1h1l-.01-.01c.55 0 1-.45 1-1 0-.56-.45-1-1-1.01h-1V6.93Z"
                />
              </svg>
              <div class="text-md font-montserrat">Add recipient</div>
            </div>
            <!-- for desktop -->
            <div
              @click="openAddBeneficiaryModal()"
              class="
                hidden
                md:block
                col
                p-12
                border
                bg-gray-50
                rounded-md
                hover:border-blue-300 hover:bg-gray-100
                cursor-pointer
                transition
              "
            >
              <svg
                fill="#00f"
                width="48"
                height="48"
                class="inline-block mb-3 opacity-50"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 9l-.01-.01c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm0 2h-.01c3.31-.01 6 2.68 6 6h-12v0c-.01-3.32 2.68-6.01 5.99-6.01Zm8-4v0c0-.56-.45-1-1-1 -.56 0-1 .44-1 1v1h-1 -.01c-.56 0-1 .44-1 1 0 .55.44 1 1 .99h1v1 -.01c-.01.55.44 1 .99 1s1-.45 1-1c0-.01 0-.01 0-.01v-1h1l-.01-.01c.55 0 1-.45 1-1 0-.56-.45-1-1-1.01h-1V6.93Z"
                />
              </svg>
              <div class="text-md font-montserrat">Add recipient</div>
            </div>
          </div>
        </div>
        <div v-if="selectedBeneficiary !== null" class="block my-4">
          <Alert alertType="success" v-if="success" class="mb-4">
            {{ success.data.message }}
          </Alert>
          <div class="flex items-top gap-6">
            <div>
              <div class="p-4 bg-gray-200 border border-gray-300 rounded-full">
                <svg
                  width="36"
                  height="36"
                  fill="#333"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9l-.01-.01c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3h-.01c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9v0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                  />
                </svg>
              </div>
            </div>
            <div class="block mt-2">
              <div class="text-xl font-montserrat text-gray-600">
                {{ selectedBeneficiary.names }}
              </div>
              <div class="text-xs text-gray-500 flex items-center space-x-2">
                <img :src="selectedBeneficiary.flag" class="h-5 w-5" />
                <div>{{ selectedBeneficiary.country }}</div>
              </div>
              <div class="border-t my-3"></div>
              <div class="text-xs font-bold text-gray-400">ACCOUNT DETAILS</div>
              <div
                v-if="selectedBeneficiary.payoutType !== 'Mobile Payments'"
                class="flex items-center my-1"
              >
                <div class="">
                  <svg
                    class="inline-block"
                    fill="#777"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 1L2 6v2h19V6m-5 4v7h3v-7M2 22h19v-3H2m8-9v7h3v-7m-9 0v7h3v-7H4Z"
                    />
                  </svg>
                </div>
                <div class="ml-2 mt-1 text-md">
                  <span class="text-gray-700">Bank</span>
                  <span class="text-gray-500 ml-4"
                    >***********{{
                      getLastXStringCharacters(selectedBeneficiary.account, 3)
                    }}</span
                  >
                </div>
              </div>
              <div v-else class="flex items-center my-1">
                <div class="">
                  <svg
                    class="inline-block"
                    fill="#777"
                    width="16"
                    height="16"
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32 2H16c-3.31 0-6 2.69-6 6v32c0 3.31 2.69 6 6 6h16c3.31 0 6-2.69 6-6V8c0-3.31-2.69-6-6-6Zm-4 40h-8v-2h8v2Zm6.5-6h-21V8h21v28Z"
                    />
                    <path fill="none" d="M0 0h48v48H0Z" />
                  </svg>
                </div>
                <div class="ml-2 mt-1 text-md">
                  <span class="text-gray-700">Mobile Money</span>
                  <span class="text-gray-500 ml-4"
                    >+{{
                      getFirstXStringCharacters(selectedBeneficiary.account, 4)
                    }}*******{{
                      getLastXStringCharacters(selectedBeneficiary.account, 3)
                    }}</span
                  >
                </div>
              </div>
              <div class="block mt-8">
                <button
                  class="
                    inline-block
                    mr-2
                    px-4
                    py-2
                    text-xs
                    bg-gray-200
                    rounded-md
                    border-gray-300 border
                    text-gray-700
                  "
                  @click="openBeneficiaryModal()"
                >
                  Change
                </button>
                <button
                  class="
                    inline-block
                    mr-2
                    px-4
                    py-2
                    text-xs
                    bg-gray-200
                    rounded-md
                    border-gray-300 border
                    text-gray-700
                  "
                  @click="deselectBeneficiary()"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="block py-4">
          <button
            v-if="selectedBeneficiary !== null"
            class="
              w-full
              bg-yellow-300
              px-4
              py-2
              rounded-md
              font-montserrat font-medium
            "
            @click="goToNextRoute()"
          >
            Continue
          </button>
        </div>
      </div>
      <RecipientsModal v-if="showBeneficiariesModal" />
      <AddRecipientModal v-if="showAddBeneficiaryModal" />
    </div>
  </TransactionsWrapper>
</template>

<script type="text/javascript">
import AddRecipientModal from '@/components/desktop/AddRecipientModal.vue'
import Alert from '@/components/partials/Alert.vue'
import RecipientsModal from '@/components/RecipientsModal.vue'
import TransactionsWrapper from './TransactionsWrapper.vue'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import utils from '@/composables/utils'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    TransactionsWrapper,
    AddRecipientModal,
    RecipientsModal,
    Alert,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const showAddBeneficiaryModal = computed(
      () => store.state.beneficiary.showAddBeneficiaryModal,
    )
    const showBeneficiariesModal = computed(
      () => store.state.beneficiary.showBeneficiariesModal,
    )
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const success = computed(() => store.state.beneficiary.success)

    const selectedBeneficiary = computed(
      () => store.state.beneficiary.selectedBeneficiary,
    )

    // const transactionOnCreation = computed(
    //   () => store.state.transactions.transactionOnCreation,
    // )
    // const sendAmount = ref(
    //   transactionOnCreation.value === null
    //     ? null
    //     : transactionOnCreation.value.sendAmount,
    // )

    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)
    //desktop
    const openAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/openAddBeneficiaryModal')
    }
    //mobile
    const goToAddBeneficiary = () => {
      const currentRouteName = route.name
      return router.push({
        name: 'add-recipient',
        params: { returnUrl: currentRouteName },
      })
    }

    const openBeneficiaryModal = () => {
      store.dispatch('beneficiary/openBeneficiariesModal')
    }

    const fetchBeneficiaries = () => {
      const payload = {
        userID: userDetails.value.userID,
        jwt: userDetails.value.jwt,
        token: token.value,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/fetchBeneficiaries', payload)
    }
    fetchBeneficiaries()

    const { getFirstXStringCharacters, getLastXStringCharacters } = utils()

    const deselectBeneficiary = () => {
      store.dispatch('beneficiary/deselectBeneficiary')
    }

    const goToNextRoute = () => {
      // if (
      //   sendAmount.value !== null &&
      //   sendAmount.value !== undefined &&
      //   sendAmount !== 0
      // ) {
      //   return router.push({ name: 'sendmoney-review' })
      // }
      return router.push({ name: 'sendmoney-amount' })
    }

    return {
      success,
      goToNextRoute,
      beneficiaries,
      goToAddBeneficiary,
      deselectBeneficiary,
      selectedBeneficiary,
      openBeneficiaryModal,
      showBeneficiariesModal,
      showAddBeneficiaryModal,
      openAddBeneficiaryModal,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
