const state = () => ({
  profileProgress: 0,
  accountActivated: false,
  accountVerified: false,
  paymentCardAdded: false,
})
const actions = {
  updateProfileProgress({ commit }, payload) {
    commit('updateProfileProgress', payload)
  },
}
const mutations = {
  updateProfileProgress(state, payload) {
    state.profileProgress = payload.profileProgress
    state.accountActivated = payload.accountActivated
    state.accountVerified = payload.accountVerified
    state.paymentCardAdded = payload.paymentCardAdded
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
