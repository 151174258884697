<template>
  <div class="block mb-8">
    <div class="text-sm mb-3 text-gray-500">QUICK ACTIONS</div>
    <div
      class="
        grid grid-cols-3
        sm:grid-cols-3
        md:grid-cols-3
        lg:grid-cols-4
        xl:grid-cols-6
        gap-10
      "
    >
      <div
        @click="openAddBeneficiaryModal()"
        class="inline-block text-center cursor-pointer"
      >
        <div
          class="
            mx-auto
            w-24
            h-24
            bg-yellow-50
            mb-4
            rounded-full
            grid
            place-content-center
            font-montserrat
            text-xl
            border-yellow-300 border
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 20 17.523"
          >
            <path
              id="User_Add"
              data-name="User Add"
              d="M9.512,10.51H9.5A3.755,3.755,0,0,0,9.5,3H9.487a3.755,3.755,0,1,0,0,7.509Zm0,2.5H9.5a7.5,7.5,0,0,1,7.509,7.509H1.99A7.5,7.5,0,0,1,9.487,13ZM19.524,8.006h0a1.252,1.252,0,0,0-2.5,0V9.258H15.757a1.245,1.245,0,1,0,0,2.491h1.252v1.239a1.237,1.237,0,0,0,1.239,1.252A1.255,1.255,0,0,0,19.5,12.988V11.724h1.239a1.258,1.258,0,0,0,0-2.516H19.487V7.931Z"
              transform="translate(-1.99 -2.999)"
              fill="rgba(0,0,0,0.65)"
            />
          </svg>
        </div>
        <div class="text-sm whitespace-nowrap">Add recipient</div>
      </div>
      <div
        @click="openAddCardModal()"
        class="inline-block text-center cursor-pointer"
      >
        <div
          class="
            mx-auto
            w-24
            h-24
            bg-yellow-50
            mb-4
            rounded-full
            grid
            place-content-center
            font-montserrat
            text-xl
            border-yellow-300 border
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 19.364 14.523"
          >
            <g id="credit-card" transform="translate(-2 -4)">
              <path
                id="Path_12"
                data-name="Path 12"
                d="M4.42,4A2.42,2.42,0,0,0,2,6.42v1.21H21.364V6.42A2.42,2.42,0,0,0,18.943,4Z"
                fill="#58554a"
              />
              <path
                id="Path_13"
                data-name="Path 13"
                d="M21.364,9H2v6.051a2.42,2.42,0,0,0,2.42,2.42H18.943a2.42,2.42,0,0,0,2.42-2.42ZM4.42,13.841a1.21,1.21,0,0,1,1.21-1.21h1.21a1.21,1.21,0,0,1,0,2.42H5.631A1.21,1.21,0,0,1,4.42,13.841Zm6.051-1.21a1.21,1.21,0,0,0,0,2.42h1.21a1.21,1.21,0,1,0,0-2.42Z"
                transform="translate(0 1.051)"
                fill="#58554a"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </div>
        <div class="text-sm whitespace-nowrap">Update card</div>
      </div>
      <div
        v-for="(beneficiary, index) in sliceBeneficiaries()"
        class="inline-block text-center cursor-pointer"
        :key="index"
        @click="
          selectBeneficiary(
            beneficiary.account,
            beneficiary.benefitiaryID,
            beneficiary.country,
            beneficiary.names,
            beneficiary.payoutType,
          )
        "
      >
        <div
          class="
            mx-auto
            w-24
            h-24
            bg-yellow-100
            mb-4
            rounded-full
            grid
            place-content-center
            font-montserrat
            text-xl
            border-yellow-300 border
            uppercase
          "
        >
          {{ getUserInitials(beneficiary.names) }}
        </div>
        <div class="text-sm whitespace-nowrap">
          Send to <strong>{{ getFirstName(beneficiary.names) }}</strong>
        </div>
        <small class="text-xs opacity-50 whitespace-nowrap"
          >{{ getPaymentChannel(beneficiary.payoutType) }} ***
          {{ getLastXStringCharacters(beneficiary.account, 4) }}</small
        >
      </div>
    </div>
  </div>
  <AddCardModal v-if="showAddCardModal" />
  <AddRecipientModal v-if="showAddBeneficiaryModal" />
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'
import { useRouter } from 'vue-router'
import AddCardModal from '@/components/desktop/AddCardModal.vue'
import AddRecipientModal from '@/components/desktop/AddRecipientModal.vue'

export default {
  components: { AddCardModal, AddRecipientModal },
  setup() {
    const store = useStore()
    const router = useRouter()
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const userDetails = computed(() => store.state.auth.userDetails)
    const currencies = computed(() => store.state.countries.currencies)
    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )
    const showAddBeneficiaryModal = computed(
      () => store.state.beneficiary.showAddBeneficiaryModal,
    )

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )
    const sliceBeneficiaries = () => {
      if (beneficiaries.value.length > 2) {
        return beneficiaries.value.slice(0, 2)
      }
      return beneficiaries.value
    }

    const {
      getUserInitials,
      getLastXStringCharacters,
      getPaymentMode,
      getBeneficiaryExchangeRate,
      setCountryFlagURL,
      isSameCountry,
    } = utils()

    const getFirstName = (names) => {
      const namesArray = names.split(' ')
      return namesArray[0]
    }

    const getPaymentChannel = (payoutType) => {
      if (getPaymentMode(payoutType) == 1) {
        return 'Mobile'
      } else if (getPaymentMode(payoutType) == 2) {
        return 'Bank'
      }
    }

    const buildTransactionObject = (
      userID,
      benefitiaryID,
      sourceExchangeRate,
      destinationExchangeRate,
      exchangeRate,
    ) => {
      const transactionOnCreation = {
        userID,
        benefitiaryID,
        sourceExchangeRate,
        destinationExchangeRate,
        exchangeRate,
        purposeforFunds: 'Test',
        sourceOfFunds: 'Test',
        remarks: 'Test',
      }
      store.dispatch(
        'transactions/buildTransactionObject',
        transactionOnCreation,
      )
    }

    const setBeneficiaryCurrencyDetails = (country, currencies) => {
      const payload = getBeneficiaryExchangeRate(country, currencies)
      store.dispatch('beneficiary/setBeneficiaryCurrencyDetails', payload)
    }

    const selectBeneficiary = (
      account,
      benefitiaryID,
      country,
      names,
      payoutType,
    ) => {
      const flag = setCountryFlagURL(country)
      const beneficiaryDetails = {
        account,
        benefitiaryID,
        country,
        flag,
        names,
        payoutType,
      }
      setBeneficiaryCurrencyDetails(country, currencies.value)
      store.dispatch('beneficiary/setSelectedBeneficiary', beneficiaryDetails)
      const { calculateExchangeRate } = createTransaction()
      const countryIsSame = isSameCountry(
        senderCurrencyDetails.value.name,
        beneficiaryCurrencyDetails.value.name,
      )
      const exchangeRate = calculateExchangeRate(
        countryIsSame,
        senderCurrencyDetails.value.marketRates,
        beneficiaryCurrencyDetails.value.rates,
      )

      const senderMarketRates = countryIsSame
        ? senderCurrencyDetails.value.rates
        : senderCurrencyDetails.value.marketRates

      buildTransactionObject(
        userDetails.value.userID,
        benefitiaryID,
        senderMarketRates,
        beneficiaryCurrencyDetails.value.rates,
        exchangeRate,
      )
      router.push({ name: 'sendmoney-amount' })
    }

    const openAddCardModal = () => {
      store.dispatch('paymentInfo/openAddCardModal')
    }

    const openAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/openAddBeneficiaryModal')
    }

    return {
      getFirstName,
      getUserInitials,
      openAddCardModal,
      showAddCardModal,
      selectBeneficiary,
      getPaymentChannel,
      sliceBeneficiaries,
      showAddBeneficiaryModal,
      openAddBeneficiaryModal,
      getLastXStringCharacters,
    }
  },
}
</script>
