<template>
  <div class="relative mx-auto my-4">
    <GoBack class="md:hidden px-6 py-4 mb-2" />
    <div class="relative px-6">
      <div class="block">
        <div
          class="
            font-montserrat
            md:text-lg
            text-2xl
            font-bold
            md:font-medium md:text-center
          "
        >
          <!-- Payment information -->
        </div>
      </div>
      <div v-if="paymentInfo !== null" class="relative block md:hidden pt-4">
        <div class="block my-4 text-center">
          <div
            class="
              inline-block
              text-left
              relative
              bg-black
              rounded-2xl
              w-full
              p-4
              max-w-sm
            "
          >
            <div class="block text-xs text-white text-opacity-50">CARD</div>
            <div
              class="block text-2xl text-yellow-300 text-center py-8 font-mono"
            >
              {{ getFirstXStringCharacters(paymentInfo.cardNumber, 4) }} ****
              ****
              {{ getLastXStringCharacters(paymentInfo.cardNumber, 4) }}
            </div>
            <div class="flex items-center">
              <div class="flex-grow">
                <div class="flex items-center">
                  <div
                    class="
                      flex-shrink
                      text-white text-opacity-50 text-xs
                      leading-3
                    "
                  >
                    VALID<br />TILL
                  </div>
                  <div class="flex-grow text-lg text-white ml-2 font-medium">
                    {{
                      getFirstXStringCharacters(paymentInfo.expireDate, 2)
                    }}/{{ getLastXStringCharacters(paymentInfo.expireDate, 2) }}
                  </div>
                </div>
                <div class="block mt-2 text-white text-xs uppercase">
                  {{ userDetails.name }}
                </div>
              </div>
              <div class="flex-shrink w-16">
                <VisaSVG v-if="paymentInfo.cardType == 'Visa'" />
                <MastercardSVG v-if="paymentInfo.cardType == 'MasterCard'" />
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 md:max-w-sm mx-auto gap-4">
          <div
            @click="goToAddCard()"
            class="
              col-span-1
              text-blue-500 text-center
              px-3
              py-2
              bg-blue-100
              rounded-md
              cursor-pointer
            "
          >
            New Card
          </div>
          <button
            @click="goToSendMoney()"
            class="
              col-span-1
              bg-accent
              px-3
              py-2
              text-sm
              rounded-md
              font-montserrat font-medium
              ml-4
            "
          >
            Send money
          </button>
        </div>
      </div>
      <div class="hidden md:block pt-2">
        <div class="text-sm text-gray-600 pb-2">PAYMENT DETAILS</div>

        <div v-if="paymentInfo !== null" class="my-4 flex items-center">
          <div
            class="w-16 h-12 rounded-md bg-gray-100 grid place-content-center"
          >
            <img
              v-if="paymentInfo.cardType == 'MasterCard'"
              class="w-full"
              src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/card-mastercard.svg"
            />
            <img
              v-if="paymentInfo.cardType == 'Visa'"
              class="w-full"
              src="https://helasend.s3.eu-west-1.amazonaws.com/img/assets/card-visa.svg"
            />
          </div>
          <div class="pl-4 flex-grow">
            <div class="text-lg mb-1">{{ userDetails.name }}</div>
            <div class="text-sm text-gray-500">
              {{ getFirstXStringCharacters(paymentInfo.cardNumber, 4) }} ****
              ****
              {{ getLastXStringCharacters(paymentInfo.cardNumber, 4) }}
            </div>
          </div>
          <div class="flex-shrink">
            <button
              class="
                col-span-1
                text-blue-500 text-sm text-center
                px-3
                py-2
                bg-blue-100
                rounded-md
                ml-4
                font-montserrat
              "
              @click="openAddCardModal()"
            >
              New Card
            </button>
            <button
              @click="goToSendMoney()"
              class="
                bg-accent
                px-3
                py-2
                text-sm
                rounded-md
                font-montserrat font-medium
                ml-4
              "
            >
              Send money
            </button>
          </div>
        </div>
      </div>
      <!-- if no card is available -->
      <div class="block max-w-xl mx-auto my-4 px-3">
        <NoCard v-if="paymentInfo === null" />
      </div>

      <Spin v-if="loading" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import utils from '@/composables/utils'
import definedConstants from '@/composables/definedConstants'
import NoCard from './NoCard.vue'
import VisaSVG from '@/components/partials/VisaSVG.vue'
import MastercardSVG from '@/components/partials/MastercardSVG.vue'
import Spin from '@/components/partials/Spin.vue'
import GoBack from '@/components/partials/GoBack.vue'
import { onMounted } from '@vue/runtime-core'

export default {
  components: {
    NoCard,
    VisaSVG,
    MastercardSVG,
    Spin,
    GoBack,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const status = ref(route.query.status)
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const loading = computed(() => store.state.paymentInfo.loading)
    const token = computed(() => store.state.auth.token)
    const userDetails = computed(() => store.state.auth.userDetails)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)
    const showErrorAlert = computed(() => store.state.alerts.showErrorAlert)
    const {
      setRequestConfig,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    } = utils()
    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)

    const getPaymentInfo = () => {
      const userID = userDetails.value.userID
      const payload = {
        config,
        userID,
      }
      store.dispatch('paymentInfo/getPaymentInfo', payload)
    }

    getPaymentInfo()

    const openAddCardModal = () => {
      store.dispatch('paymentInfo/openAddCardModal')
    }

    const removePaymentInfo = () => {
      const paymentID = paymentInfo.value.paymentInfoID
      const body = { paymentID }
      const payload = {
        config,
        body,
      }
      store.dispatch('paymentInfo/setLoadingTrue')
      store.dispatch('paymentInfo/removePaymentInfo', payload)
    }

    onMounted(() => {
      if (status.value !== undefined) {
        if (status.value === 'success') {
          store.dispatch('alerts/openSuccessAlert')
        } else if (status.value === 'failed') {
          store.dispatch('alerts/openErrorAlert')
        }
      }
    })

    const goToSendMoney = () => {
      router.push({ name: 'sendmoney-recipient' })
    }

    const goToAddCard = () => {
      const currentRoutePath = router.currentRoute.value.path
      router.push({
        name: 'add-paymentInfo',
        params: { redirectPath: currentRoutePath },
      })
    }

    const { profilePaymentRoutesArray } = definedConstants()
    onBeforeRouteLeave((to) => {
      if (!profilePaymentRoutesArray.includes(to.name)) {
        router.push({ name: 'profile' })
      }
    })

    return {
      loading,
      goToAddCard,
      paymentInfo,
      userDetails,
      goToSendMoney,
      showErrorAlert,
      openAddCardModal,
      showSuccessAlert,
      removePaymentInfo,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
