<template>
  <div
    class="
      absolute
      top-0
      left-1
      right-1
      bg-white
      rounded-lg
      overflow-auto
      shadow-lg
      border
      max-w-xl
      mx-auto
      z-50
      animate__animated animate__fadeInDown
    "
  >
    <div class="text-sm w-full text-left px-3 py-3 bg-gray-50">
      <input
        autofocus
        type="text"
        autocomplete="false"
        @input="(evt) => (search = evt.target.value)"
        @keyup="searchCountries()"
        class="outline-none border-none bg-white w-full h-8 px-2 rounded-md"
        placeholder="Search country"
      />
    </div>
    <div class="py-2 text-xs border-t">
      <TinySpin v-if="loading" />
      <ul v-else class="block text-sm text-left max-h-56 overflow-auto">
        <li v-if="!searchCountries().length" class="py-2 px-3">No options</li>
        <li
          @click="setSendingCountryDetails(country)"
          v-for="(country, index) in searchCountries()"
          class="py-2 px-3 cursor-pointer border-b hover:bg-gray-50 flex"
          :key="index"
        >
          <div
            class="
              relative
              w-6
              h-4
              pb-2/3
              overflow-hidden
              mr-2
              rounded-sm
              shadow-md
              align-baseline
            "
          >
            <img
              :src="setCountryFlagURL(country.isoCode)"
              class="absolute h-full w-full border border-gray-200"
            />
          </div>
          <div
            class="
              flex
              w-full
              text-sm
              tracking-wider
              font-semibold
              text-gray-900
            "
          >
            <div class="flex justify-start w-11/12 pr-4">
              <div>{{ country.name }}</div>
            </div>
            <div class="flex justify-end w-1/12">
              <div>{{ country.currency }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import TinySpin from '@/components/partials/TinySpin'
import utils from '@/composables/utils'

export default {
  components: { TinySpin },
  setup() {
    const store = useStore()
    const search = ref('')
    const country = ref('')
    const isFocused = ref(false)

    const token = computed(() => store.state.auth.token)

    const sourceCurrencies = computed(
      () => store.state.countries.sourceCurrencies,
    )
    const loading = computed(() => store.state.countries.loading)

    const closeSendingCountriesDropDownMenu = () => {
      store.dispatch('countries/closeSendingCountriesDropDownMenu')
    }

    const setSendingCountryDetails = (country) => {
      store.dispatch('countries/setSendingCountryDetails', country)
      closeSendingCountriesDropDownMenu()
    }

    const fetchExchangeRates = async () => {
      await store.dispatch('auth/fetchToken')
      const payload = {
        token: token.value,
      }
      if (sourceCurrencies.value === null) {
        store.dispatch('countries/setLoadingTrue', payload)
        store.dispatch('countries/fetchExchangeRates', payload)
      }
    }

    fetchExchangeRates()

    const { setCountryFlagURL } = utils()

    const searchCountries = () => {
      if (sourceCurrencies.value === null) {
        return []
      }
      if (search.value === '') {
        return sourceCurrencies.value
      }
      return sourceCurrencies.value.filter((currency) => {
        return currency.name.toLowerCase().includes(search.value)
      })
    }

    return {
      search,
      loading,
      country,
      isFocused,
      searchCountries,
      sourceCurrencies,
      setCountryFlagURL,
      setSendingCountryDetails,
    }
  },
}
</script>
