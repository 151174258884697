<template>
  <TransactionsWrapper>
    <div class="relative">
      <div class="text-lg text-gray-600 font-montserrat font-medium">
        Review transaction
      </div>
      <SelectedRecipient />
      <Amount />
      <PaymentInfo v-if="paymentInfo !== null" />
      <Pay />
    </div>
  </TransactionsWrapper>
  <CVVModal v-if="showCVVModal" />
  <AddCardModal v-if="showAddCardModal" />
  <Spin v-if="loading" />
  <ErrorAlert v-if="showErrorAlert && error !== null">{{
    getError()
  }}</ErrorAlert>
  <AlertSuccess v-if="showSuccessAlert" style="z-index: 2147483004"
    >Card added successfully</AlertSuccess
  >
  <ErrorAlert
    v-if="showErrorAlert && error === null"
    style="z-index: 2147483004"
    >We are unable to verify your card. Please try again or contact
    support</ErrorAlert
  >
  <CustomAlert v-if="goToVeriff" :alertType="'info'">
    <template v-slot:message>
      <div class="text-center">Please verify your account to continue</div>
    </template>
    <template v-slot:button>
      <div class="w-full flex justify-center my-4 gap-2">
        <AlertButton
          @click="closeVeriffAlert()"
          class="flex-shrink bg-gray-200 text-gray-500"
          >Cancel</AlertButton
        >
        <AlertButton @click="verify()" class="flex-grow bg-accent text-gray-700"
          >Get Verified</AlertButton
        >
      </div>
    </template>
  </CustomAlert>
  <ThinSpin v-if="verifyIsLoading" />
</template>

<script>
import TransactionsWrapper from './TransactionsWrapper'
import SelectedRecipient from '@/components/sendmoney-review/SelectedRecipient'
import Amount from '@/components/sendmoney-review/Amount'
import PaymentInfo from '@/components/sendmoney-review/PaymentInfo'
import Pay from '@/components/sendmoney-review/Pay'
import CVVModal from '@/components/sendmoney-review/CVVModal'
import AddCardModal from '@/components/desktop/AddCardModal'
import Spin from '@/components/partials/Spin'
import AlertButton from '@/components/partials/AlertButton.vue'
import CustomAlert from '@/components/partials/CustomAlert.vue'
import AlertSuccess from './AlertSuccess'
import ErrorAlert from './ErrorAlert'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import veriff from '@/composables/veriff'
import definedConstants from '@/composables/definedConstants'
import utils from '@/composables/utils'
import ThinSpin from '@/components/partials/ThinSpin.vue'

export default {
  components: {
    Pay,
    Spin,
    Amount,
    CVVModal,
    ThinSpin,
    ErrorAlert,
    PaymentInfo,
    AlertButton,
    CustomAlert,
    AlertSuccess,
    AddCardModal,
    SelectedRecipient,
    TransactionsWrapper,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const status = ref(route.query.status)
    const showCVVModal = computed(() => store.state.paymentInfo.showCVVModal)
    const loading = computed(() => store.state.transactions.loading)
    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const showErrorAlert = computed(() => store.state.alerts.showErrorAlert)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)
    const cardAddUnsuccessful = computed(
      () => store.state.alerts.showErrorAlert,
    )
    const verifyIsLoading = computed(() => store.state.verify.verifyIsLoading)
    const error = computed(() => store.state.transactions.error)
    const goToVeriff = computed(() => store.state.verify.goToVeriff)
    const token = computed(() => store.state.auth.token)
    const userDetails = computed(() => store.state.auth.userDetails)
    const kycSession = computed(() => store.state.verify.kycSession)
    const { setRequestConfig } = utils()
    const config = setRequestConfig(token.value, userDetails.value.jwt)

    const getError = () => {
      if (error.value !== null) {
        if (error.value.component === 'createTransaction') {
          return error.value.data.message
        }
      }
    }

    if (status.value !== undefined) {
      if (status.value === 'success') {
        store.dispatch('alerts/openSuccessAlert')
      } else if (status.value === 'failed') {
        store.dispatch('alerts/openErrorAlert')
      }
    }

    const closeVeriffAlert = () => {
      store.dispatch('verify/setGoToVeriffFalse')
    }

    const veriffURL = computed(() => store.state.verify.veriffURL)
    const { setRequestData, setVeriffPerson, createFrame } = veriff()

    const { appBaseURL } = definedConstants()
    const currentRoute = router.currentRoute.value.path
    const redirectUrl = `${appBaseURL}${currentRoute}`

    const verify = async () => {
      const person = setVeriffPerson(userDetails.value.name)
      const body = {
        redirectUrl,
        person,
      }
      closeVeriffAlert()
      store.dispatch('verify/setVerifyIsLoadingTrue')
      await store.dispatch('verify/setVeriffSession', body)
      await store.dispatch(
        'verify/setKYCSession',
        setRequestData(userDetails.value.userID, kycSession.value, config),
      )
      await createFrame(veriffURL.value)
    }

    return {
      error,
      verify,
      loading,
      getError,
      goToVeriff,
      paymentInfo,
      showCVVModal,
      showErrorAlert,
      verifyIsLoading,
      showSuccessAlert,
      showAddCardModal,
      cardAddUnsuccessful,
      closeVeriffAlert,
    }
  },
}
</script>
