<template>
  <TransactionsWrapper>
    <CalculateAmounts />
  </TransactionsWrapper>
</template>

<script type="text/javascript">
import TransactionsWrapper from './TransactionsWrapper.vue'
import CalculateAmounts from '@/components/desktop/CalculateAmounts.vue'
import { useStore } from 'vuex'

export default {
  components: { TransactionsWrapper, CalculateAmounts },
  setup() {
    const store = useStore()
    //TODO check the use of this!
    store.dispatch('paymentInfo/unsetCVC')
  },
}
</script>
