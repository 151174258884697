<template>
  <div class="block mb-8">
    <div class="text-sm text-gray-500 mb-2">CURRENT RATES</div>
    <div class="rounded-md shadow-lg border">
      <div class="flex pl-4 pr-2 py-3 border-b items-center">
        <div class="">
          <div class="text-xs font-bold text-gray-400">YOU SEND</div>
          <CurrencyInput
            v-model="sendAmount"
            @keyup="
              handleCalculatePayoutAmount(
                isSameCountry(
                  senderCurrencyDetails.name,
                  getRecipientExchangeCountryName(),
                ),
              )
            "
            :options="setCurrencyOptionsForSender()"
            class="
              text-gray-600
              block
              w-full
              text-xl
              font-montserrat
              border-none
              outline-none
            "
            placeholder="0.00"
          />
        </div>
        <div
          class="
            w-20
            text-center
            bg-gray-100
            text-lg
            font-montserrat
            px-2
            py-2
            text-gray-600
            rounded-md
          "
        >
          {{ userDetails.country.currency }}
        </div>
      </div>
      <div class="flex pl-4 pr-2 py-3 border-b items-center">
        <div class="">
          <div class="text-xs font-medium text-gray-400">THEY RECEIVE</div>
          <CurrencyInput
            required
            class="
              text-gray-600
              block
              w-full
              text-xl
              font-montserrat
              border-none
              outline-none
            "
            placeholder="0.00"
            v-model="payoutAmount"
            @keyup="
              handleCalculateSendAmount(
                isSameCountry(
                  senderCurrencyDetails.name,
                  getRecipientExchangeCountryName(),
                ),
              )
            "
            :options="setCurrencyOptionsForRecipient()"
          />
        </div>
        <div
          v-if="beneficiaryCurrencyDetails !== null"
          class="
            w-20
            text-center
            bg-gray-100
            text-lg
            font-montserrat
            px-2
            py-2
            text-gray-600
            rounded-md
          "
        >
          {{ beneficiaryCurrencyDetails.currency }}
        </div>
        <div
          v-if="beneficiaryCurrencyDetails === null"
          class="
            w-20
            text-center
            bg-gray-100
            text-lg
            font-montserrat
            px-2
            py-2
            text-gray-600
            rounded-md
          "
        >
          KES
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import utils from '@/composables/utils'
import definedConstants from '@/composables/definedConstants'
import createTransaction from '@/composables/createTransaction'
import CurrencyInput from '@/components/partials/CurrencyInput'

import { useStore } from 'vuex'
export default {
  components: { CurrencyInput },
  setup() {
    const store = useStore()
    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )

    //amount beneficiary receives in beneficiary's currency
    const payoutAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payoutAmount,
    )
    const sendAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.sendAmount,
    )
    const userDetails = computed(() => store.state.auth.userDetails)

    const defaultCountryDetails = computed(
      () => store.state.countries.defaultCountryDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )
    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )

    const getRecipientExchangeCountryName = () => {
      if (null !== beneficiaryCurrencyDetails.value) {
        return beneficiaryCurrencyDetails.value.name
      }
      return defaultCountryDetails.value.name
    }

    const { isSameCountry } = utils()

    const { currencyOptions } = definedConstants()

    const getBeneficiaryCurrencyDetails = () => {
      if (beneficiaryCurrencyDetails.value !== null) {
        return beneficiaryCurrencyDetails.value
      }
      return defaultCountryDetails.value
    }

    const setCurrencyOptionsForSender = () => {
      return {
        ...currencyOptions,
        currency: userDetails.value.country.currency,
      }
    }
    const setCurrencyOptionsForRecipient = () => {
      return {
        ...currencyOptions,
        currency: getBeneficiaryCurrencyDetails().currency,
      }
    }

    const { setSendAmount, setPayoutAmount } = createTransaction()

    const handleCalculatePayoutAmount = (isSameCountry) => {
      const { AmountToPayout, transactionObject } = setPayoutAmount(
        isSameCountry,
        sendAmount.value,
        senderCurrencyDetails.value,
        getBeneficiaryCurrencyDetails(),
        transactionOnCreation.value,
      )
      payoutAmount.value = AmountToPayout
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    const handleCalculateSendAmount = (isSameCountry) => {
      const { AmountToSend, transactionObject } = setSendAmount(
        isSameCountry,
        senderCurrencyDetails.value,
        payoutAmount.value,
        getBeneficiaryCurrencyDetails(),
        transactionOnCreation.value,
      )
      sendAmount.value = AmountToSend
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    return {
      userDetails,
      sendAmount,
      payoutAmount,
      isSameCountry,
      senderCurrencyDetails,
      beneficiaryCurrencyDetails,
      handleCalculateSendAmount,
      setCurrencyOptionsForSender,
      handleCalculatePayoutAmount,
      setCurrencyOptionsForRecipient,
      getRecipientExchangeCountryName,
    }
  },
}
</script>
