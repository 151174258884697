import { createStore } from 'vuex'
import auth from './auth'
import transactions from './transactions'
import countries from './countries'
import beneficiary from './beneficiary'
import payouts from './payouts'
import verify from './verify'
import paymentInfo from './paymentInfo'
import alerts from './alerts'
import profile from './profile'
import referral from './referral'

export default createStore({
  modules: {
    auth,
    alerts,
    verify,
    payouts,
    profile,
    referral,
    countries,
    paymentInfo,
    beneficiary,
    transactions,
  },
})
