<template>
  <ul class="block">
    <li
      v-for="transaction in transactions"
      :key="transaction.transactionReference"
      class="flex py-4 mx-4 border-b gap-4 items-top"
      @click="storeTransaction(transaction)"
    >
      <div class="flex-grow">
        <div class="text-gray-700">
          {{ transaction.benefitiaryName }}
        </div>
        <div class="text-xs mt-1 gap-4">
          <small
            class="px-3 py-1 rounded-xl border mr-2"
            :class="setCssClass(transaction.transactionStatus)"
            >{{ transaction.transactionStatus }}</small
          >
          <span class="text-gray-400">
            {{ formatDateTime(transaction.createdDate, 'Do MMM YYYY, h:mm a') }}
          </span>
        </div>
      </div>
      <div class="flex-shrink text-right">
        <div class="text-gray-700">
          {{ transaction.senderCurrency }} {{ transaction.sendAmount }}
        </div>
        <div class="text-xs text-gray-400 mt-1">
          {{ transaction.recipientCurrency }}
          {{ transaction.payoutAmount }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { computed } from '@vue/runtime-core'
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const userDetails = computed(() => store.state.auth.userDetails)
    const transactions = computed(() => store.state.transactions.transactions)
    const token = computed(() => store.state.auth.token)

    const { formatDateTime } = utils()

    const payload = {
      userID: userDetails.value.userID,
      jwt: userDetails.value.jwt,
      token: token.value,
    }
    store.dispatch('transactions/fetchAllCustomerTransactions', payload)

    const setCssClass = (status) => {
      let css = ''
      switch (status) {
        case 'pending':
          css = `bg-gray-100 text-gray-500`
          break
        case 'Delivered':
          css = `bg-green-100 text-green-600 border-green-500`
          break
        case 'Failed':
          css = `bg-red-100 text-gray-600 border-red-300`
          break
        default:
          css = `bg-yellow-100 text-yellow-700 border-yellow-300`
          break
      }
      return css
    }

    const storeTransaction = (transaction) => {
      store.dispatch('transactions/storeTransaction', transaction)
      router.push({ name: 'transaction' })
    }

    return {
      setCssClass,
      transactions,
      formatDateTime,
      storeTransaction,
    }
  },
}
</script>
