<template>
  <TinySpin v-if="loading" class="my-4 px-3" />

  <div v-else class="block px-3">
    <div v-if="beneficiaries.length" class="flex items-center">
      <GoBack class="w-1/2 md:hidden mt-3" />
      <div class="flex w-1/2 md:w-full justify-end">
        <button
          class="
            hidden
            md:block
            text-sm
            bg-yellow-200
            px-2
            mx-3
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="openAddBeneficiaryModal()"
        >
          New recipient
        </button>
        <button
          class="
            block
            md:hidden
            text-sm
            bg-yellow-200
            px-1
            py-2
            rounded-md
            font-montserrat font-medium
            mt-4
          "
          @click="goToAddRecipient()"
        >
          New recipient
        </button>
      </div>
    </div>
    <div v-if="beneficiaries.length" class="block my-4">
      <div
        class="
          block
          md:hidden
          w-1/2
          text-sm
          px-1
          pb-2
          font-montserrat font-medium
          text-gray-500
          uppercase
        "
      >
        All Recipients
      </div>
      <div
        v-for="(beneficiary, index) in beneficiaries"
        :key="beneficiary.benefitiaryID"
        class="
          border-b
          cursor-pointer
          hover:rounded-md
          transition
          hover:bg-white hover:shadow-md hover:border
        "
        :class="{
          '-mt-1 mb-1 bg-white border shadow-md rounded-md':
            showAccordion(index),
        }"
        @click="toggleAccordion(index)"
      >
        <div class="py-4 px-3 flex items-center">
          <div
            class="
              w-16
              h-16
              inline-flex
              items-center
              border
              bg-white
              rounded-full
              align-middle
              font-montserrat font-medium
            "
            :class="{
              'bg-yellow-50 border-yellow-300 text-gray-700 font-medium font-montserrat':
                showAccordion(index),
            }"
          >
            <div class="text-center w-full">
              {{ getUserInitials(beneficiary.names) }}
            </div>
          </div>
          <div class="px-4 flex-grow">
            <div class="text-md mb-1 font-montserrat font-medium">
              {{ beneficiary.names }}
            </div>
            <div class="text-xs text-gray-500">
              {{ beneficiary.payoutType }} account ending
              {{ getLastXStringCharacters(beneficiary.account, 4) }}
            </div>
          </div>
          <div class="p-2">
            <svg
              class="inline-block ml-2 align-middle opacity-60"
              width="21"
              height="21"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7L5 9"
              />
            </svg>
          </div>
        </div>
        <div class="block" v-if="showAccordion(index)">
          <div class="grid grid-cols-2 gap-6 border-t mt-3 p-6">
            <div class="col">
              <div class="text-xs text-gray-500 font-montserrat font-medium">
                {{ beneficiary.payoutType }} account
              </div>
              <div class="text-md text-gray-700">
                Account ending
                {{ getLastXStringCharacters(beneficiary.account, 4) }}
              </div>
            </div>
            <div class="col">
              <div class="text-xs text-gray-500 font-montserrat font-medium">
                Account Name
              </div>
              <div class="text-md text-gray-700">{{ beneficiary.names }}</div>
            </div>
            <div class="col">
              <div class="text-xs text-gray-500 font-montserrat font-medium">
                {{ beneficiary.payoutType }}
              </div>
              <div class="text-md text-gray-700">
                {{ getFirstXStringCharacters(beneficiary.account, 6) }}***{{
                  getLastXStringCharacters(beneficiary.account, 3)
                }}
              </div>
            </div>
          </div>
          <div class="mx-6 py-6 border-t">
            <button
              @click="
                selectBeneficiary(
                  beneficiary.account,
                  beneficiary.benefitiaryID,
                  beneficiary.country,
                  beneficiary.names,
                  beneficiary.payoutType,
                )
              "
              class="bg-yellow-300 px-4 py-2 rounded-md text-sm"
            >
              Send money
            </button>
            <button
              @click="removeBeneficiary(beneficiary.benefitiaryID)"
              class="
                bg-red-100
                border-red-300 border
                text-red-700
                px-4
                py-2
                rounded-md
                text-sm
                float-right
              "
            >
              Delete recipient
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="block max-w-xl mx-auto my-4 px-3">
      <GoBack class="w-1/2 md:hidden mt-3" />
      <NoRecipient />
    </div>
  </div>
  <AddRecipientModal v-if="showAddBeneficiaryModal" />
</template>

<script type="text/javascript">
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import AddRecipientModal from '@/components/desktop/AddRecipientModal.vue'
import NoRecipient from '@/components/NoRecipient.vue'
import TinySpin from '@/components/partials/TinySpin.vue'
import GoBack from '@/components/partials/GoBack.vue'
import createTransaction from '@/composables/createTransaction'
import utils from '@/composables/utils'
import { useRouter } from 'vue-router'
export default {
  components: { AddRecipientModal, NoRecipient, TinySpin, GoBack },
  setup() {
    const store = useStore()
    const router = useRouter()
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const loading = computed(() => store.state.beneficiary.loading)
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)

    const accordionIsOpenArray = computed(
      () => store.state.beneficiary.accordionIsOpenArray,
    )
    const showAddBeneficiaryModal = computed(
      () => store.state.beneficiary.showAddBeneficiaryModal,
    )
    const currencies = computed(() => store.state.countries.currencies)
    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )

    const fetchBeneficiaries = () => {
      const payload = {
        userID: userDetails.value.userID,
        jwt: userDetails.value.jwt,
        token: token.value,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/fetchBeneficiaries', payload)
    }
    fetchBeneficiaries()

    const {
      setCountryFlagURL,
      setRequestConfig,
      getUserInitials,
      getLastXStringCharacters,
      getFirstXStringCharacters,
      getBeneficiaryExchangeRate,
      isSameCountry,
    } = utils()

    const removeBeneficiary = (beneficiaryID) => {
      const config = setRequestConfig(token.value, userDetails.value.jwt)
      const body = { beneficiaryID }
      const payload = {
        body,
        config,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/removeBeneficiary', payload)
    }

    const setBeneficiaryCurrencyDetails = (country, currencies) => {
      const payload = getBeneficiaryExchangeRate(country, currencies)
      store.dispatch('beneficiary/setBeneficiaryCurrencyDetails', payload)
    }

    const buildTransactionObject = (
      userID,
      benefitiaryID,
      sourceExchangeRate,
      destinationExchangeRate,
      exchangeRate,
    ) => {
      const transactionOnCreation = {
        userID,
        benefitiaryID,
        sourceExchangeRate,
        destinationExchangeRate,
        exchangeRate,
        purposeforFunds: 'Test',
        sourceOfFunds: 'Test',
        remarks: 'Test',
      }
      store.dispatch(
        'transactions/buildTransactionObject',
        transactionOnCreation,
      )
    }

    const selectBeneficiary = (
      account,
      benefitiaryID,
      country,
      names,
      payoutType,
    ) => {
      const flag = setCountryFlagURL(country)
      const beneficiaryDetails = {
        account,
        benefitiaryID,
        country,
        flag,
        names,
        payoutType,
      }
      setBeneficiaryCurrencyDetails(country, currencies.value)
      store.dispatch('beneficiary/setSelectedBeneficiary', beneficiaryDetails)
      const { calculateExchangeRate } = createTransaction()
      const countryIsSame = isSameCountry(
        senderCurrencyDetails.value.name,
        beneficiaryCurrencyDetails.value.name,
      )

      const exchangeRate = calculateExchangeRate(
        countryIsSame,
        senderCurrencyDetails.value.marketRates,
        beneficiaryCurrencyDetails.value.rates,
      )
      const senderMarketRates = countryIsSame
        ? senderCurrencyDetails.value.rates
        : senderCurrencyDetails.value.marketRates

      buildTransactionObject(
        userDetails.value.userID,
        benefitiaryID,
        senderMarketRates,
        beneficiaryCurrencyDetails.value.rates,
        exchangeRate,
      )
      router.push({ name: 'sendmoney-amount' })
    }

    const showAccordion = (index) => {
      if (accordionIsOpenArray.value.length) {
        if (accordionIsOpenArray.value[index] !== undefined) {
          if (
            accordionIsOpenArray.value[index]['accordionIsOpen'] !== undefined
          ) {
            return accordionIsOpenArray.value[index].accordionIsOpen
          }
        }
      }
    }

    const toggleAccordion = (index) => {
      const openAccordion = !showAccordion(index)
      //reset first
      store.dispatch('beneficiary/setAccordionIsOpenArray', beneficiaries.value)
      //then toggle
      store.dispatch('beneficiary/toggleAccordion', { index, openAccordion })
    }

    const goToAddRecipient = () => {
      const currentRouteName = router.currentRoute.value.name
      return router.push({
        name: 'add-recipient',
        params: { returnUrl: currentRouteName },
      })
    }

    const openAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/openAddBeneficiaryModal')
    }

    return {
      loading,
      beneficiaries,
      showAccordion,
      toggleAccordion,
      getUserInitials,
      goToAddRecipient,
      removeBeneficiary,
      selectBeneficiary,
      showAddBeneficiaryModal,
      openAddBeneficiaryModal,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
