<template>
  <div class="block py-6">
    <div class="text-xs text-gray-400 mb-4">
      By confirming this transaction, you agree to our Terms and Conditions,
      Privacy Policy and Refund Policy agreements.
    </div>
    <button
      class="
        w-full
        bg-yellow-300
        px-4
        py-2
        rounded-md
        font-montserrat font-medium
      "
      @click="createTransaction()"
    >
      Confirm & pay
    </button>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const CVC = computed(() => store.state.paymentInfo.CVC)
    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const takeMeToVerify = computed(
      () => store.state.transactions.takeMeToVerify,
    )
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)
    const error = computed(() => store.state.transactions.error)
    const success = computed(() => store.state.transactions.success)
    const { setRequestConfig } = utils()
    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)

    const getPaymentInfo = () => {
      const userID = userDetails.value.userID
      const payload = {
        config,
        userID,
      }
      store.dispatch('paymentInfo/getPaymentInfo', payload)
    }
    getPaymentInfo()

    const buildTransactionObject = (transaction) => {
      const transactionOnCreation = {
        ...transaction,
        cvc: CVC.value,
        paymentInfoID: paymentInfo.value.paymentInfoID,
      }
      store.dispatch(
        'transactions/buildTransactionObject',
        transactionOnCreation,
      )
    }

    const createTransaction = async () => {
      if (CVC.value === null) {
        return store.dispatch('paymentInfo/openCVVModal')
      }
      if (paymentInfo.value !== null) {
        buildTransactionObject(transactionOnCreation.value)
        const jwt = userDetails.value.jwt
        const config = setRequestConfig(token.value, jwt)
        const body = transactionOnCreation.value
        const payload = {
          body,
          config,
        }
        store.dispatch('transactions/setLoadingTrue')
        await store.dispatch('transactions/createTransaction', payload)
        store.dispatch('paymentInfo/unsetCVC')
        if (success.value) {
          router.push({ name: 'sendmoney-complete' })
        } else if (error.value !== null) {
          store.dispatch('alerts/openErrorAlert')
        }
        if (takeMeToVerify.value) {
          store.dispatch('verify/setGoToVeriffTrue')
        }
      }
    }

    return { createTransaction }
  },
}
</script>
