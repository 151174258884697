<template>
  <TransactionsWrapper>
    <div class="relative">
      <div class="relative py-6">
        <div class="block text-center">
          <svg
            class="inline-block mb-4"
            width="48"
            height="48"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M16 8v0c0 4.41-3.59 8-8 8 -4.42 0-8-3.59-8-8v0C-.01 3.58 3.58-.01 7.99-.01c4.41-.01 8 3.58 8 7.99 0 0 0 0 0 0Zm-3.97-3.03l-.01-.01c-.3-.3-.77-.3-1.07 0 -.01 0-.02.01-.02.02L7.45 9.4l-2.1-2.1v0c-.31-.29-.78-.27-1.06.03 -.27.28-.27.73-.01 1.02l2.64 2.64v0c.29.29.76.29 1.06-.01 0-.01.01-.02.01-.02l3.99-4.99v-.01c.28-.3.27-.77-.02-1.05Z"
            />
          </svg>
        </div>
        <div class="block text-center mb-4">
          <div class="text-xl font-montserrat font-medium">Thank you</div>
          <div class="text-sm font-montserrat">
            Your request has been received and is being<br />processed. We will
            send you a confirmation.
          </div>
        </div>
        <div class="block max-w-sm mx-auto">
          <div class="block text-center">
            <button
              class="
                block
                md:hidden
                bg-yellow-300
                w-full
                px-4
                py-2
                rounded-md
                font-montserrat font-medium
                mt-4
              "
              @click="$router.push({ name: 'home' })"
            >
              Done
            </button>
            <button
              class="
                hidden
                md:block
                bg-yellow-300
                w-full
                px-4
                py-2
                rounded-md
                font-montserrat font-medium
                mt-4
              "
              @click="$router.push({ name: 'dashboard-transactions' })"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  </TransactionsWrapper>
</template>

<script>
import TransactionsWrapper from './TransactionsWrapper.vue'

export default {
  components: { TransactionsWrapper },
}
</script>
