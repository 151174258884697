<template>
  <div
    class="
      fixed
      flex
      z-10
      top-0
      left-0
      w-full
      h-full
      items-center items-middle
      bg-black bg-opacity-75
      text-center
      animate__animated animate__fadeIn
    "
  >
    <div class="flex-grow">
      <div
        class="
          p-20
          justify-center
          items-center
          bg-white
          rounded-md
          inline-block
          border
        "
      >
        <div
          class="
            animate-spin
            rounded-full
            h-32
            w-32
            border-t-2 border-b-2 border-black
          "
        ></div>
      </div>
    </div>
  </div>
</template>
