<template>
  <form @submit.prevent="updateProfile()" class="block py-4">
    <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">COUNTRY OF RESIDENCE</div>
      <select
        required
        @change="setPhonePrefix($event)"
        v-model="country"
        class="w-full text-gray-700 m-0 outline-none appearance-none"
      >
        <option
          v-for="country in filteredCurrencies()"
          :key="country.isoCode"
          :data-prefix="country.prefix"
        >
          {{ country.name }}
        </option>
      </select>
    </div>

    <div class="mb-4">
      <div class="grid grid-cols-2 space-x-4">
        <div class="col-span-1">
          <div
            class="border px-4 pt-2 pb-2 rounded-md"
            :class="{
              'border-red-300 bg-red-50': getFormErrorsByField('name', false)
                .length,
            }"
          >
            <div class="text-xs text-gray-400">FIRST NAME</div>
            <input
              required
              class="outline-none w-full text-gray-700"
              @change="getFormErrorsByField('name', true)"
              @keyup="getFormErrorsByField('name', true)"
              :class="{
                'bg-red-50': getFormErrorsByField('name', false).length,
              }"
              placeholder="James"
              v-model="firstName"
            />
          </div>
        </div>
        <div class="col-span-1">
          <div
            class="border px-4 pt-2 pb-2 rounded-md"
            :class="{
              'border-red-300 bg-red-50': getFormErrorsByField('name', false)
                .length,
            }"
          >
            <div class="text-xs text-gray-400">LAST NAME</div>
            <input
              required
              class="outline-none w-full text-gray-700"
              :class="{
                'bg-red-50': getFormErrorsByField('name', false).length,
              }"
              placeholder="Doe"
              v-model="lastName"
            />
          </div>
        </div>
        <div
          v-for="(error, index) in getFormErrorsByField('name', false)"
          :key="index"
          class="text-sm text-red-500"
        >
          {{ error }}
        </div>
      </div>
    </div>

    <div class="mb-4">
      <div
        class="border px-4 pt-2 pb-2 rounded-md"
        :class="{
          'border-red-300 bg-red-50': getFormErrorsByField('email', false)
            .length,
        }"
      >
        <div class="text-xs text-gray-400">EMAIL</div>
        <input
          required
          class="outline-none w-full text-gray-700"
          :class="{
            'bg-red-50': getFormErrorsByField('email', false).length,
          }"
          @change="getFormErrorsByField('email', true)"
          @keyup="getFormErrorsByField('email', true)"
          placeholder="joe@example.com"
          type="email"
          v-model="email"
          autocomplete="off"
        />
      </div>
      <div
        v-for="(error, index) in getFormErrorsByField('email', false)"
        :key="index"
        class="text-sm text-red-500"
      >
        {{ error }}
      </div>
    </div>

    <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
      <div class="text-xs text-gray-400">PHONE NUMBER</div>
      <div class="flex">
        <div class="flex-shrink pr-2">
          <div v-if="phone" class="text-md text-gray-700">
            {{ getPhonePrefix(userDetails.phone) }}
          </div>
          <div v-else class="text-md text-gray-700">{{ phonePrefix }}</div>
        </div>
        <div class="flex-grow">
          <input
            required
            class="outline-none w-full"
            placeholder="Phone number"
            type="number"
            autocomplete="off"
            v-model="phone"
          />
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="
        mt-12
        block
        px-4
        py-2
        w-full
        rounded-md
        bg-yellow-300
        text-gray-900
        font-medium
        text-center
        font-montserrat
      "
    >
      Save details
    </button>
  </form>
  <Spin v-if="loading" />
</template>

<script>
import { computed, onBeforeUnmount, ref } from '@vue/runtime-core'
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import Spin from '@/components/partials/Spin.vue'
export default {
  components: { Spin },
  setup() {
    const store = useStore()
    // const router = useRouter()
    const firstName = ref('')
    const lastName = ref('')
    const email = ref(null)
    const phone = ref('')

    //calling code
    const phonePrefix = ref('')
    const userDetails = computed(() => store.state.auth.userDetails)
    const profileDetails = computed(() => store.state.auth.profileDetails)
    const currencies = computed(() => store.state.countries.currencies)
    const loading = computed(() => store.state.auth.loading)
    const formErrors = computed(() => store.state.auth.formErrors)
    const success = computed(() => store.state.auth.success)
    const country = ref(userDetails.value.country.name)

    const token = computed(() => store.state.auth.token)
    const { setRequestConfig, setFormErrors } = utils()
    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }
    fetchProfileDetails()
    const callingCode = ref(null)

    //get initial values
    const getFirstName = (names) => {
      const namesArray = names.split(' ')
      return namesArray[0]
    }

    const getLastName = (names) => {
      const namesArray = names.split(' ')
      return namesArray[1]
    }

    const getPhonePrefix = (phoneNumber) => {
      phonePrefix.value = phoneNumber.slice(0, phoneNumber.length - 9)
      return phonePrefix.value
    }

    const getPhonePostfix = (phoneNumber) => {
      return phoneNumber.slice(phoneNumber.length - 9)
    }

    //initial values
    firstName.value = getFirstName(profileDetails.value.name)
    lastName.value = getLastName(profileDetails.value.name)
    email.value = profileDetails.value.email
    phone.value = getPhonePostfix(profileDetails.value.phone)

    const filteredCurrencies = () => {
      return currencies.value.filter((currency) => {
        return currency.isSourceCountry === 1
      })
    }

    //when user changes the country
    const setPhonePrefix = (event) => {
      phone.value = ''
      const prefix =
        event.target.options[event.target.options.selectedIndex].dataset.prefix
      phonePrefix.value = prefix
    }

    const updateProfile = async () => {
      const name = `${firstName.value} ${lastName.value}`
      const completePhonNumber = `${phonePrefix.value}${phone.value}`
      const body = {
        name,
        email: email.value,
        phone: completePhonNumber,
        userID: userDetails.value.userID,
      }

      const payload = {
        body,
        config,
      }

      store.dispatch('auth/setLoadingTrue')
      store.dispatch('auth/closeProfileDetailsModal')
      await store.dispatch('auth/updateProfile', payload)
      if (success.value !== '') {
        store.dispatch('alerts/openSuccessAlert')
      }
      fetchProfileDetails()
    }

    const getFormErrorsByField = (field, isToClearErrors) => {
      if (isToClearErrors) {
        store.dispatch('auth/clearFormFieldError', field)
      } else if (formErrors.value !== null && !isToClearErrors) {
        if (formErrors.value.component === 'profileDetails') {
          return setFormErrors(formErrors.value, field)
        } else {
          return []
        }
      } else {
        return []
      }
    }

    onBeforeUnmount(() => {
      fetchProfileDetails()
    })

    return {
      email,
      phone,
      country,
      loading,
      lastName,
      firstName,
      currencies,
      callingCode,
      phonePrefix,
      getLastName,
      userDetails,
      getFirstName,
      updateProfile,
      getPhonePrefix,
      setPhonePrefix,
      getPhonePostfix,
      filteredCurrencies,
      getFormErrorsByField,
    }
  },
}
</script>
