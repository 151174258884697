<template>
  <div class="md:block hidden">
    <div class="block relative grid grid-cols-4 px-6 py-6">
      <div class="col-span-3 pr-12">
        <AccountStatus v-if="!isAccountVerified()" />
        <QuickActions />
        <RecentRecipients />
      </div>
      <div class="col">
        <RatesCalculator />
        <HowToSend />
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import AccountStatus from '@/components/AccountStatus.vue'
import QuickActions from './QuickActions.vue'
import RecentRecipients from './RecentRecipients.vue'
import RatesCalculator from './RatesCalculator.vue'
import HowToSend from './HowToSend.vue'

export default {
  components: {
    AccountStatus,
    QuickActions,
    RecentRecipients,
    RatesCalculator,
    HowToSend,
  },
  setup() {
    const store = useStore()
    const userDetails = computed(() => store.state.auth.userDetails)

    const profileDetails = computed(() => store.state.auth.profileDetails)

    const token = computed(() => store.state.auth.token)

    const { setRequestConfig } = utils()

    const config = setRequestConfig(token.value, userDetails.value.jwt)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }
    fetchProfileDetails()

    const isAccountVerified = () => {
      if (profileDetails.value !== null) {
        return profileDetails.value.approved === 2
      }
      return false
    }

    return {
      isAccountVerified,
    }
  },
}
</script>
