<template>
  <div
    class="flex items-center px-2 py-2 bg-black text-white sticky top-0 z-10"
  >
    <a
      href="http://help.helasend.com"
      target="_blank"
      class="flex-shrink p-2 opacity-90 cursor-pointer"
      @click="goToHelp"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        fill="#fff"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
        />
      </svg>
    </a>
    <div class="flex-grow text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110.685"
        height="17.676"
        viewBox="0 0 110.685 17.676"
        class="inline-block"
      >
        <g id="Group_6" data-name="Group 6" transform="translate(-57 -47)">
          <path
            id="Path_14"
            data-name="Path 14"
            d="M358.838,253.776H348.729c.268,1.661,1.62,2.677,3.657,2.677a4.814,4.814,0,0,0,3.434-1.289l1.921,2.231a7.448,7.448,0,0,1-5.65,2.156c-4.62,0-7.443-2.9-7.193-6.866a7.33,7.33,0,0,1,7.56-6.867c3.924,0,6.789,2.628,6.52,6.916-.019.3-.095.719-.14,1.041m-10.016-2.256H355.4a2.941,2.941,0,0,0-3.1-2.776,3.414,3.414,0,0,0-3.478,2.776"
            transform="translate(-266.317 -194.912)"
            fill="#ffd200"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M422.706,251.743l-.479,7.61H418.6l.1-1.661a4.689,4.689,0,0,1-4.19,1.859c-3.13,0-4.883-1.735-4.739-4.04.148-2.355,1.916-3.991,5.989-3.991h3.08c.1-1.661-.828-2.627-2.915-2.627a6.78,6.78,0,0,0-3.928,1.24l-1.222-2.7a10.791,10.791,0,0,1,5.864-1.611c4.073,0,6.314,1.884,6.061,5.924m-4.089,3.4.086-1.364h-2.658c-1.813,0-2.427.669-2.483,1.562-.061.966.719,1.611,2.085,1.611a3.075,3.075,0,0,0,2.97-1.809"
            transform="translate(-311.084 -194.912)"
            fill="#ffd200"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M512.25,255.9h-9.864c.238,1.833,1.694,3.007,3.883,3.007a4.794,4.794,0,0,0,3.5-1.363l1.464,1.81a6.789,6.789,0,0,1-5.191,2.044c-4.214,0-6.774-2.7-6.539-6.438a6.735,6.735,0,0,1,6.9-6.414c3.672,0,6.195,2.561,5.948,6.485-.015.234-.06.587-.1.869m-9.765-1.95h7.133a3.17,3.17,0,0,0-3.362-3.055,3.752,3.752,0,0,0-3.771,3.055"
            transform="translate(-372.985 -196.797)"
            fill="#fff"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M555.584,254.048l-.452,7.19h-2.943l.429-6.814c.14-2.232-.9-3.313-2.782-3.313-2.095,0-3.587,1.268-3.745,3.783l-.4,6.343h-2.943l.789-12.546h2.8l-.1,1.621a5.822,5.822,0,0,1,4.442-1.763c3.013,0,5.143,1.716,4.9,5.5"
            transform="translate(-402.832 -196.797)"
            fill="#fff"
          />
          <path
            id="Path_18"
            data-name="Path 18"
            d="M275.329,253.222l2.743,3.706.412-7.2Z"
            transform="translate(-218.329 -197.608)"
            fill="#ffd200"
          />
          <path
            id="Path_19"
            data-name="Path 19"
            d="M301.94,240.349h-7.9l.431-6.842h-4.024l-1.092,17.352h4.024l.447-7.115h7.9l-.448,7.115H305.3l1.091-17.352H302.37Z"
            transform="translate(-228.007 -186.418)"
            fill="#ffd200"
          />
          <path
            id="Path_20"
            data-name="Path 20"
            d="M392.519,250.661h3.875l1.1-17.441h-3.875Z"
            transform="translate(-299.183 -186.22)"
            fill="#ffd200"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M459.87,240.44c.078-1.245,1.176-2.232,3.719-2.232a8.744,8.744,0,0,1,4.462,1.292l1.115-2.374a9.963,9.963,0,0,0-5.394-1.456c-4.52,0-6.78,2.255-6.947,4.91-.385,6.109,9.555,3.784,9.332,7.331-.077,1.221-1.218,2.138-3.784,2.138a8.65,8.65,0,0,1-5.324-1.809l0,.008-.211,3.057a11.363,11.363,0,0,0,5.379,1.281c4.52,0,6.8-2.256,6.97-4.888.38-6.038-9.533-3.759-9.313-7.259"
            transform="translate(-343.542 -187.911)"
            fill="#fff"
          />
          <path
            id="Path_22"
            data-name="Path 22"
            d="M597.657,239.681a4.837,4.837,0,0,0-3.943-1.692,6.565,6.565,0,0,0-6.759,6.414,5.683,5.683,0,0,0,5.95,6.438,5.53,5.53,0,0,0,4.28-1.785l-.1,1.621h2.825l1.1-17.434h-2.942Zm-.25,4.722a3.956,3.956,0,0,1-3.99,3.924,3.413,3.413,0,0,1-3.5-3.924,3.956,3.956,0,0,1,3.991-3.923,3.413,3.413,0,0,1,3.5,3.923"
            transform="translate(-433.32 -186.236)"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
    <router-link to="/account" class="flex-shrink p-2 opacity-90">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        fill="#fff"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
        />
      </svg>
    </router-link>
  </div>
</template>

<script>
export default {}
</script>
