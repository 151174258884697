<template>
  <div class="block pl-2">
    <div class="text-lg text-gray-500 mb-3">How to send money</div>
    <div class="flex mb-4">
      <div class="block">
        <div
          class="
            w-8
            h-8
            rounded-full
            grid
            place-content-center
            bg-gray-200
            text-md
            font-montserrat
          "
        >
          1
        </div>
      </div>
      <div class="pl-4">
        <div class="text-sm text-gray-600">Choose recipient</div>
        <div class="text-xs text-gray-400 mt-1">
          Select from the list of contacts you have added in your Helasend
          account as your recipients
        </div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="block">
        <div
          class="
            w-8
            h-8
            rounded-full
            grid
            place-content-center
            bg-gray-200
            text-md
            font-montserrat
          "
        >
          2
        </div>
      </div>
      <div class="pl-4">
        <div class="text-sm text-gray-600">Enter amount</div>
        <div class="text-xs text-gray-400 mt-1">
          Enter the amount you wish to send to your recipient
        </div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="block">
        <div
          class="
            w-8
            h-8
            rounded-full
            grid
            place-content-center
            bg-gray-200
            text-md
            font-montserrat
          "
        >
          3
        </div>
      </div>
      <div class="pl-4">
        <div class="text-sm text-gray-600">Pay with your card</div>
        <div class="text-xs text-gray-400 mt-1">
          Pay for the transaction using your connected VISA or MasterCard
        </div>
      </div>
    </div>
    <div class="text-xs text-gray-400 pt-4">
      If you need help with sending, kindly chat us by clicking the chat icon
      below, or call us
      <a href="tel:" class="underline">+44123456789</a> or emailing us on
      <a href="mailto:" class="underline">support@helasend.com</a>
    </div>
  </div>
</template>

<script>
export default {}
</script>
