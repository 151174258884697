<template>
  <router-view />
  <OfflineAlert v-if="showOfflineAlert">No internet</OfflineAlert>

  <Alert alertType="info" v-if="warningZone" class="absolute md:left-80"
    >Your session has expired, securely logging you out...
  </Alert>
</template>
<script>
import { computed, onUpdated, ref, watchEffect } from '@vue/runtime-core'
import Alert from '@/components/partials/Alert'
import OfflineAlert from '@/components/partials/AlertDanger'
import autologout from '@/composables/autologout'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
  components: { OfflineAlert, Alert },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userDetails = computed(() => store.state.auth.userDetails)
    const showOfflineAlert = ref(false)

    //boot intercom
    const bootIntercom = () => {
      if (userDetails.value !== null) {
        window.Intercom('boot', {
          app_id: 'avv4lma1',
          email: userDetails.value.name,
          user_id: userDetails.value.userID,
          created_at: 1234567890,
        })
      } else {
        window.Intercom('boot', {
          app_id: 'avv4lma1',
        })
      }
    }
    watchEffect(() => {
      bootIntercom()
    })

    window.onoffline = () => {
      showOfflineAlert.value = true
    }
    window.ononline = () => {
      showOfflineAlert.value = false
    }

    const { warningZone, logoutZone, addEvents, clearEvents } = autologout()
    onUpdated(() => {
      setTimeout(() => {
        if (route.meta.requiresAuth) {
          addEvents()
        }
      }, 2000)
    })

    watchEffect(() => {
      if (logoutZone.value) {
        clearEvents()
        store.dispatch('auth/logout')
        window.location.href = 'https://helasend.com/'
      }
    })

    return {
      Alert,
      warningZone,
      userDetails,
      showOfflineAlert,
    }
  },
}
</script>
