<template>
  <div class="md:hidden block">
    <PaymentInfo />
  </div>
  <div class="hidden md:block">
    <DashboardWrapper><PaymentInfo /></DashboardWrapper>
  </div>
  <AddCardModal v-show="showAddCardModal" />
  <AlertSuccess v-if="showSuccessAlert" style="z-index: 2147483004"
    >Card added successfully</AlertSuccess
  >
  <ErrorAlert v-if="showErrorAlert" style="z-index: 2147483004"
    >We are unable to verify your card. Please try again or contact
    support</ErrorAlert
  >
</template>

<script>
import DashboardWrapper from '../desktop/DashboadWrapper.vue'
import PaymentInfo from '@/components/ProfilePayment'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import AddCardModal from '@/components/desktop/AddCardModal.vue'
import AlertSuccess from './AlertSuccess.vue'
import ErrorAlert from './ErrorAlert.vue'

export default {
  components: {
    DashboardWrapper,
    PaymentInfo,
    AddCardModal,
    AlertSuccess,
    ErrorAlert,
  },
  setup() {
    const store = useStore()
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)
    const showErrorAlert = computed(() => store.state.alerts.showErrorAlert)
    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )

    return {
      paymentInfo,
      showErrorAlert,
      showAddCardModal,
      showSuccessAlert,
    }
  },
}
</script>
