<template>
  <div
    class="fixed left-0 top-0 w-full h-full z-10 transition-all"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeProfileDetailsModal()"
    ></div>
    <div
      class="
        absolute
        bottom-0
        md:top-20 md:bottom-auto
        left-0
        right-0
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div
        class="
          block
          py-4
          px-4
          font-montserrat font-medium
          text-xl text-gray-600
        "
      >
        My profile
        <ProfileDetailsComponent />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileDetailsComponent from '@/components/ProfileDetails'
import { useStore } from 'vuex'
export default {
  components: { ProfileDetailsComponent },
  setup() {
    const store = useStore()
    const closeProfileDetailsModal = () =>
      store.dispatch('auth/closeProfileDetailsModal')
    return {
      closeProfileDetailsModal,
    }
  },
}
</script>
