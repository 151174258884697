<template>
  <div class="fixed left-0 top-0 w-full h-full z-10 transition-all">
    <div
      @click="closeSuccessAlert()"
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
    ></div>
    <div
      class="
        absolute
        top-52
        left-2
        right-2
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div class="flex w-full justify-center py-4">
        <div
          class="
            flex
            items-center
            bg-green-100
            justify-center
            h-16
            w-16
            rounded-full
          "
        >
          <div class="text-green-500">
            <svg fill="currentColor" viewBox="0 0 20 20" class="h-14 w-14">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        class="
          flex
          w-full
          justify-center
          font-montserrat
          text-2xl
          font-medium
          text-gray-600
          tracking-wide
        "
      >
        Success!
      </div>
      <div class="font-montserrat text-xl text-gray-500 font-medium py-4">
        <div class="w-full flex justify-center">
          <slot></slot>
        </div>
        <div class="w-full flex justify-center my-4">
          <button
            class="
              block
              p-2
              tracking-wider
              bg-accent
              rounded-md
              text-gray-700
              font-medium
              shadow-lg
            "
            @click="closeSuccessAlert()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const closeSuccessAlert = () => {
      store.dispatch('alerts/closeSuccessAlert')
    }
    return {
      closeSuccessAlert,
    }
  },
}
</script>
