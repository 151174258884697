<template>
  <!-- chevron down -->
  <div>
    <svg
      v-if="name === 'chevron-down'"
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#212121"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M19 9l-7 7-7-7"
      />
    </svg>

    <!-- check -->
    <svg
      v-if="name === 'check'"
      class="inline-block"
      width="24"
      height="24"
      fill="#fff"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M16.707 5.29l-.01-.01c.39.39.39 1.02 0 1.41l-8 8 0-.01c-.4.39-1.03.39-1.42 0l-4-4 -.01-.01c-.39-.4-.38-1.04.02-1.42 .38-.38 1-.38 1.38 0l3.29 3.29 7.29-7.293 -.01 0c.39-.4 1.02-.4 1.41-.01Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ['name'],
}
</script>
