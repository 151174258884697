<template>
  <div
    class="fixed left-0 top-0 w-full h-full z-10 transition-all"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeCVVModal()"
    ></div>
    <div
      class="
        absolute
        bottom-0
        left-0
        right-0
        md:top-20 md:bottom-auto md:rounded-md
        bg-white
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeIn
      "
    >
      <NoCard v-if="paymentInfo === null" class="p-12" />
      <CVVForm v-else />
    </div>
  </div>
</template>

<script>
import NoCard from '@/components/NoCard'
import CVVForm from './CVVForm'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
export default {
  components: { NoCard, CVVForm },
  setup() {
    const store = useStore()
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)

    const closeCVVModal = () => {
      store.dispatch('paymentInfo/closeCVVModal')
    }
    return {
      paymentInfo,
      closeCVVModal,
    }
  },
}
</script>
