<template>
  <div class="w-72 absolute left-0 top-0 bottom-0 bg-black bg-opacity-90">
    <div class="block p-6 text-center">
      <svg
        class="inline-block"
        xmlns="http://www.w3.org/2000/svg"
        height="18"
        viewBox="0 0 87.664 14"
      >
        <g
          id="Group_3"
          data-name="Group 3"
          transform="translate(-275.329 -233.22)"
        >
          <path
            id="Path_3"
            data-name="Path 3"
            d="M355.935,252.121h-8.006a2.619,2.619,0,0,0,2.9,2.12,3.813,3.813,0,0,0,2.72-1.021l1.522,1.767a5.9,5.9,0,0,1-4.475,1.708c-3.659,0-5.895-2.3-5.7-5.438a5.805,5.805,0,0,1,5.988-5.438,4.868,4.868,0,0,1,5.164,5.478c-.015.235-.075.569-.111.824M348,250.334h5.213a2.329,2.329,0,0,0-2.458-2.2,2.7,2.7,0,0,0-2.755,2.2"
            transform="translate(-52.473 -9.505)"
            fill="#ffd200"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M420.015,250.511l-.379,6.028h-2.872l.083-1.316a3.713,3.713,0,0,1-3.319,1.472c-2.479,0-3.868-1.374-3.753-3.2.117-1.865,1.517-3.161,4.743-3.161h2.439c.083-1.316-.656-2.081-2.308-2.081a5.37,5.37,0,0,0-3.111.982l-.968-2.14a8.547,8.547,0,0,1,4.644-1.276c3.226,0,5,1.492,4.8,4.692m-3.238,2.69.068-1.08H414.74c-1.436,0-1.922.53-1.966,1.237-.048.765.569,1.276,1.651,1.276a2.435,2.435,0,0,0,2.352-1.433"
            transform="translate(-101.424 -9.505)"
            fill="#ffd200"
          />
          <path
            id="Path_5"
            data-name="Path 5"
            d="M509.6,254.374h-7.812a2.794,2.794,0,0,0,3.076,2.382,3.8,3.8,0,0,0,2.771-1.079l1.159,1.433a5.377,5.377,0,0,1-4.111,1.619c-3.338,0-5.365-2.14-5.179-5.1a5.334,5.334,0,0,1,5.465-5.08,4.511,4.511,0,0,1,4.711,5.136c-.011.186-.047.465-.08.688m-7.734-1.544h5.649a2.511,2.511,0,0,0-2.663-2.419,2.972,2.972,0,0,0-2.986,2.419"
            transform="translate(-169.111 -11.566)"
            fill="#eaeaea"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M552.915,252.9l-.358,5.695h-2.331l.34-5.4c.111-1.768-.711-2.624-2.2-2.624a2.84,2.84,0,0,0-2.966,3l-.316,5.024h-2.331l.625-9.937h2.219l-.081,1.284a4.611,4.611,0,0,1,3.518-1.4c2.387,0,4.073,1.359,3.884,4.354"
            transform="translate(-201.748 -11.566)"
            fill="#eaeaea"
          />
          <path
            id="Path_7"
            data-name="Path 7"
            d="M275.33,252.495l2.173,2.935.327-5.7Z"
            transform="translate(0 -12.453)"
            fill="#ffd200"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M299.323,238.926h-6.255l.341-5.419h-3.187l-.865,13.743h3.187l.354-5.635h6.256l-.355,5.635h3.187l.864-13.743h-3.187Z"
            transform="translate(-10.583 -0.217)"
            fill="#ffd200"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M392.519,247.033h3.069l.869-13.814h-3.069Z"
            transform="translate(-88.411 0)"
            fill="#ffd200"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M459.235,239.448c.062-.986.931-1.768,2.945-1.768a6.925,6.925,0,0,1,3.534,1.024l.883-1.88a7.891,7.891,0,0,0-4.272-1.153c-3.58,0-5.37,1.786-5.5,3.888-.3,4.839,7.567,3,7.391,5.807-.061.967-.964,1.693-3,1.693A6.85,6.85,0,0,1,457,245.626l0,.006-.167,2.421a9,9,0,0,0,4.26,1.015c3.58,0,5.389-1.787,5.52-3.871.3-4.782-7.55-2.977-7.376-5.75"
            transform="translate(-136.917 -1.849)"
            fill="#eaeaea"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M595.428,238.342A3.831,3.831,0,0,0,592.3,237a5.2,5.2,0,0,0-5.354,5.08,4.5,4.5,0,0,0,4.713,5.1,4.38,4.38,0,0,0,3.389-1.414l-.081,1.284h2.237l.869-13.808h-2.331Zm-.2,3.74a3.133,3.133,0,0,1-3.16,3.108,2.7,2.7,0,0,1-2.769-3.108,3.133,3.133,0,0,1,3.161-3.107,2.7,2.7,0,0,1,2.769,3.107"
            transform="translate(-235.086 -0.018)"
            fill="#eaeaea"
          />
        </g>
      </svg>
    </div>
    <div class="block">
      <div class="p-6">
        <router-link
          :to="{ name: 'sendmoney-recipient' }"
          class="
            w-full
            block
            px-4
            py-2
            text-sm text-center
            rounded-md
            font-montserrat font-bold
            bg-yellow-300
          "
        >
          Send money
        </router-link>
      </div>

      <div class="block mt-6">
        <router-link
          :to="{ name: 'home' }"
          class="block px-6 py-2 text-white opacity-50"
          :class="{
            'border-l-2 bg-black bg-opacity-40 text-yellow-300 border-yellow-400 opacity-100':
              setTitle() === 'Home',
          }"
          >Home</router-link
        >
        <router-link
          :to="{ name: 'dashboard-transactions' }"
          class="block px-6 py-2 text-white opacity-50"
          :class="{
            'border-l-2 bg-black bg-opacity-40 text-yellow-300 border-yellow-400 opacity-100':
              setTitle() === 'Transactions',
          }"
          >Transactions</router-link
        >
        <router-link
          :to="{ name: 'dashboard-recipients' }"
          class="block px-6 py-2 text-white opacity-50"
          :class="{
            'border-l-2 bg-black bg-opacity-40 text-yellow-300 border-yellow-400 opacity-100':
              setTitle() === 'Recipients',
          }"
          >Recipients</router-link
        >
        <router-link
          :to="{ name: 'profile-payment' }"
          class="block px-6 py-2 text-white opacity-50"
          :class="{
            'border-l-2 bg-black bg-opacity-40 text-yellow-300 border-yellow-400 opacity-100':
              setTitle() === 'Payment information',
          }"
          >Payment details</router-link
        >
        <a
          href="http://help.helasend.com"
          target="_blank"
          class="
            block
            px-6
            py-2
            border-l-2 border-opacity-0
            text-white
            border-yellow-400
            opacity-50
          "
          >Help</a
        >
      </div>

      <div class="absolute left-0 bottom-0 w-72 p-6">
        <div
          class="
            text-lg text-white text-sm
            font-montserrat
            leading-5
            text-center
          "
        >
          Need help?
        </div>
        <div class="text-white text-xs leading-4 text-center mt-2 opacity-50">
          Talk to us. Our support <br />team is available 24/7.
        </div>

        <div class="my-4 text-center">
          <a
            href="https://www.facebook.com/Helasend-102236551906170"
            target="_blank"
            class="
              inline-block
              w-8
              h-8
              opacity-60
              hover:opacity-100
              transition
              mx-2
            "
          >
            <svg
              class="w-full"
              fill="#FFD202"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.001 2.002C6.46 2.002 2 6.47 2 11.992c0 4.99 3.65 9.12 8.437 9.87v-6.99h-2.55v-2.9h2.54v-2.21c0-2.508 1.49-3.891 3.776-3.891 1.09 0 2.24.19 2.24.19v2.45h-1.27c-1.24 0-1.63.77-1.63 1.56v1.875h2.77l-.45 2.891h-2.33v6.98c4.78-.75 8.437-4.89 8.437-9.877 0-5.53-4.477-10-9.999-10Z"
              />
            </svg>
          </a>
          <a
            href="https://twitter.com/helasendapp"
            target="_blank"
            class="
              inline-block
              w-8
              h-8
              opacity-60
              hover:opacity-100
              transition
              mx-2
            "
          >
            <svg
              class="w-full"
              fill="#FFD202"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 .4C4.69.4.4 4.69.4 10c-.001 5.302 4.298 9.6 9.6 9.6s9.6-4.298 9.6-9.6c0-5.31-4.3-9.6-9.6-9.6Zm3.9 7.86c0 .08 0 .16 0 .24 0 2.5-1.91 5.381-5.379 5.381 -1.07 0-2.07-.32-2.9-.85 .14.01.29.02.45.02 .88 0 1.7-.31 2.348-.81 -.827-.02-1.53-.57-1.766-1.32 .11.02.23.03.35.03 .17 0 .34-.03.49-.07 -.87-.18-1.52-.94-1.52-1.86v-.03c.25.14.54.22.85.23 -.508-.34-.85-.92-.85-1.58 0-.35.09-.68.25-.96 .93 1.14 2.32 1.89 3.89 1.97 -.04-.14-.05-.29-.05-.44 0-1.05.84-1.891 1.891-1.891 .54 0 1.03.23 1.38.59 .43-.09.83-.242 1.2-.46 -.15.44-.44.81-.84 1.04 .38-.05.74-.15 1.08-.3 -.26.37-.58.71-.95.97Z"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/helasend/"
            target="_blank"
            class="
              inline-block
              w-8
              h-8
              opacity-60
              hover:opacity-100
              transition
              mx-2
            "
          >
            <svg
              class="w-full"
              fill="#FFD202"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M260.062 32c-121.47 0-219.94 97.701-219.94 218.232 -.01 41.23 11.53 79.79 31.55 112.68l-39.69 117.08 121.76-38.69c31.5 17.28 67.745 27.14 106.298 27.14 121.47 0 219.938-97.72 219.938-218.232 0-120.531-98.47-218.232-219.938-218.232Zm109.36 301.11c-5.18 12.82-28.58 24.53-38.899 25.07 -10.32.54-10.61 7.99-66.84-16.44 -56.23-24.44-90.06-83.844-92.719-87.67 -2.67-3.82-21.78-31.05-20.75-58.46 1.03-27.42 16.04-40.35 21.4-45.73 5.35-5.39 11.48-6.36 15.232-6.42 4.42-.08 7.29-.14 10.57-.02 3.27.12 8.19-.69 12.45 10.63 4.256 11.32 14.44 39.15 15.74 41.98 1.3 2.83 2.1 6.12.1 9.77 -2.02 3.65-3.05 5.93-5.97 9.08 -2.94 3.14-6.18 7.04-8.8 9.44 -2.92 2.66-5.97 5.57-2.9 11.26 3.06 5.69 13.65 24.35 29.77 39.73 20.72 19.77 38.59 26.32 44.09 29.31 5.51 3 8.8 2.67 12.22-.93 3.4-3.6 14.63-15.75 18.59-21.17 3.95-5.44 7.66-4.38 12.74-2.33 5.07 2.05 32.15 16.55 37.673 19.55 5.51 2.98 9.19 4.52 10.51 6.9 1.31 2.38.9 13.53-4.28 26.35Z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="
      absolute
      left-72
      top-0
      bottom-0
      max-w-6xl
      right-0
      border-r
      overflow-y-scroll
    "
  >
    <div
      class="
        hidden
        md:flex
        bg-white
        border-b
        items-center
        sticky
        top-0
        z-10
        shadow-md
      "
    >
      <div class="py-5 px-6 flex-grow">
        <div class="text-md font-medium font-montserrat">
          {{ setTitle() }}
        </div>
      </div>
      <div
        @click="openProfileDropDownMenu()"
        class="flex-shrink py-2 cursor-pointer"
      >
        <div
          class="
            relative
            mx-auto
            w-10
            h-10
            rounded-full
            bg-yellow-100
            border border-yellow-300
            items-center
            align-center
            text-center
            flex
          "
        >
          <span
            class="w-full font-montserrat font-medium text-sm cursor-pointer"
            >{{ getUserInitials(userDetails.name) }}</span
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="absolute right-0 top-0 cursor-pointer"
            width="13"
            height="13"
            viewBox="0 0 49 49"
          >
            <g
              v-if="userDetails.approved === 2"
              id="Group_30"
              data-name="Group 30"
              transform="translate(-2374 -3341)"
            >
              <circle
                id="Ellipse_17"
                data-name="Ellipse 17"
                cx="21.5"
                cy="21.5"
                r="21.5"
                transform="translate(2377 3344)"
                fill="#00e21a"
                stroke="#fff"
                stroke-width="6"
              />
              <path
                id="Check"
                d="M25.831,5.519,25.814,5.5a1.658,1.658,0,0,1,0,2.347L12.5,21.167v-.017a1.678,1.678,0,0,1-2.364,0L3.474,14.492l-.017-.017a1.663,1.663,0,0,1,.033-2.364,1.628,1.628,0,0,1,2.3,0l5.477,5.477L23.4,5.447h-.017a1.643,1.643,0,0,1,2.347-.017Z"
                transform="translate(2384.019 3352.054)"
                fill="#fff"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="relative py-5 pr-6 pl-3 flex-shrink cursor-pointer">
        <div
          class="
            text-md
            font-medium
            text-gray-500
            font-montserrat
            inline-block
            align-middle
            cursor-pointer
          "
          @click="openProfileDropDownMenu()"
        >
          {{ getUserFullFirstNameAndLastNameInitial(userDetails.name) }}
        </div>
        <svg
          @click="openProfileDropDownMenu()"
          class="inline-block ml-2 align-middle opacity-60"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7L5 9"
          />
        </svg>

        <ProfileDropDownMenu v-if="showProfileDropDownMenu" />
      </div>
      <AlertSuccess v-if="showSuccessAlert">
        <div class="w-full flex justify-center">{{ getSuccess() }}</div>
      </AlertSuccess>
    </div>
    <slot></slot>
  </div>
  <ProfileDetailsModal v-if="showProfileDetailsModal" />
</template>

<script>
import utils from '@/composables/utils'
import ProfileDetailsModal from '@/components/desktop/ProfileDetailsModal'
import ProfileDropDownMenu from '@/components/dashboard/ProfileDropDownMenu'
import AlertSuccess from '@/components/partials/AlertSuccess'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

export default {
  components: { ProfileDetailsModal, ProfileDropDownMenu, AlertSuccess },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userDetails = computed(() => store.state.auth.userDetails)
    const profileDetails = computed(() => store.state.auth.profileDetails)
    const success = computed(() => store.state.auth.success)
    const token = computed(() => store.state.auth.token)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)

    const showProfileDetailsModal = computed(
      () => store.state.auth.showProfileDetailsModal,
    )
    const showProfileDropDownMenu = computed(
      () => store.state.auth.showProfileDropDownMenu,
    )
    const {
      getUserInitials,
      getUserFullFirstNameAndLastNameInitial,
      setRequestConfig,
    } = utils()

    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }
    fetchProfileDetails()

    const openProfileDropDownMenu = () => {
      store.dispatch('auth/openProfileDropDownMenu')
    }

    const setTitle = () => {
      if (route.name === 'home') {
        return 'Home'
      } else if (route.name === 'dashboard-transactions') {
        return 'Transactions'
      } else if (route.name === 'dashboard-recipients') {
        return 'Recipients'
      } else if (route.name === 'profile-payment') {
        return 'Payment information'
      }
    }

    const getSuccess = () => {
      if (success.value !== '') {
        if (success.value.component === 'updateProfile') {
          return success.value.data.message
        }
      }
    }

    return {
      setTitle,
      getSuccess,
      userDetails,
      profileDetails,
      getUserInitials,
      showSuccessAlert,
      openProfileDropDownMenu,
      showProfileDetailsModal,
      showProfileDropDownMenu,
      getUserFullFirstNameAndLastNameInitial,
    }
  },
}
</script>
