<template>
  <div class="relative max-w-xl mx-auto">
    <div class="block bg-black bg-opacity-90 py-4 mb-4">
      <GoBack class="px-6 py-4 text-white fill-current" />

      <div class="block relative mb-6">
        <div
          class="
            relative
            mx-auto
            w-20
            h-20
            rounded-full
            bg-yellow-300
            items-center
            align-center
            text-center
            flex
          "
        >
          <span class="w-full font-montserrat font-medium text-xl">{{
            getUserInitials(userDetails.name)
          }}</span>
          <svg
            v-if="profileDetails.approved === 2"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute right-0 top-0"
            width="24"
            height="24"
            viewBox="0 0 49 49"
          >
            <g
              id="Group_30"
              data-name="Group 30"
              transform="translate(-2374 -3341)"
            >
              <circle
                cx="21.5"
                cy="21.5"
                r="21.5"
                transform="translate(2377 3344)"
                fill="#00e21a"
                stroke="#000"
                stroke-width="6"
              />
              <path
                id="Check"
                d="M25.831,5.519,25.814,5.5a1.658,1.658,0,0,1,0,2.347L12.5,21.167v-.017a1.678,1.678,0,0,1-2.364,0L3.474,14.492l-.017-.017a1.663,1.663,0,0,1,.033-2.364,1.628,1.628,0,0,1,2.3,0l5.477,5.477L23.4,5.447h-.017a1.643,1.643,0,0,1,2.347-.017Z"
                transform="translate(2384.019 3352.054)"
                fill="#fff"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </div>
        <div class="text-lg text-gray-200 font-montserrat text-center mt-2">
          {{ userDetails.name }}
        </div>
        <div
          v-if="profileDetails.approved === 2"
          class="text-xs text-gray-400 text-center"
        >
          Verified
        </div>
        <div v-else class="text-xs text-gray-400 text-center">Not verified</div>
      </div>
    </div>

    <div class="block mb-6">
      <ul v-if="profileDetails.approved !== 2">
        <li class="flex mx-4">
          <AccountStatus />
        </li>
      </ul>
      <div class="text-xs mx-4 border-b pb-2 text-gray-400 font-bold">
        MY ACCOUNT
      </div>
      <ul class="list-none">
        <li
          class="flex py-4 mx-4 border-b gap-4 items-center cursor-pointer"
          @click="$router.push({ name: 'profile-details' })"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            />
          </svg>
          <div class="flex-grow font-medium font-montserrat text-blue-400">
            Account Details
          </div>
          <div class="flex-shrink">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              fill="#aaa"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </li>
        <li
          class="flex py-4 mx-4 border-b gap-4 items-center cursor-pointer"
          @click="$router.push({ name: 'profile-payment' })"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"
            />
          </svg>
          <div class="flex-grow font-medium font-montserrat text-blue-400">
            Payment Information
          </div>
          <div class="flex-shrink">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              fill="#aaa"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </li>
        <li
          @click="goToRecipient()"
          class="cursor-pointer flex py-4 mx-4 border-b gap-4 items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
            />
          </svg>
          <div class="flex-grow font-medium font-montserrat text-blue-400">
            Recipients
          </div>
          <div class="flex-shrink">
            <svg
              version="1.1"
              width="13"
              height="13"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 256 256"
              fill="#aaa"
              xml:space="preserve"
            >
              <polygon
                points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"
              />
            </svg>
          </div>
        </li>
      </ul>
    </div>

    <div class="block mb-6 hidden">
      <div class="text-xs mx-4 border-b py-2 text-gray-400 font-bold">
        SECURITY
      </div>
      <ul class="list-none">
        <li class="flex py-4 mx-4 border-b gap-4 items-top">
          <div class="rounded-3xl bg-gray-200 w-6 h-6"></div>
          <div class="flex-grow">
            <div class="font-medium font-montserrat text-blue-400">
              Change Security PIN
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="mb-4">
      <div
        @click="logout()"
        class="flex py-4 mx-4 gap-4 items-center cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 0 24 24"
          width="18"
          fill="#f00"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"
          />
        </svg>
        <div class="flex-grow">
          <div class="font-medium font-montserrat text-red-400">Logout</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import AccountStatus from '@/components/AccountStatus.vue'
import GoBack from '@/components/partials/GoBack'
import utils from '@/composables/utils'
import { useRouter } from 'vue-router'

export default {
  components: { GoBack, AccountStatus },
  setup() {
    const store = useStore()
    const router = useRouter()
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)
    const profileDetails = computed(() => store.state.auth.profileDetails)
    const { setRequestConfig, getUserInitials } = utils()
    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }
    fetchProfileDetails()

    const logout = () => {
      store.dispatch('auth/logout')
      window.location.href = 'https://helasend.com/'
    }

    const goToRecipient = () => {
      router.push({ name: 'dashboard-recipients' })
    }

    //TODO check the use of this!
    store.dispatch('paymentInfo/unsetCVC')

    return {
      logout,
      userDetails,
      goToRecipient,
      profileDetails,
      getUserInitials,
    }
  },
}
</script>
