import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const state = () => ({
  token: sessionStorage.getItem('token'),
  authenticated:
    sessionStorage['authenticated'] === undefined
      ? false
      : sessionStorage.getItem('authenticated'),
  userDetails:
    sessionStorage['userDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['userDetails']),
  verifyUserDetails:
    sessionStorage['verifyUserDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['verifyUserDetails']),
  forgotPasswordUserDetails:
    sessionStorage['forgotPasswordUserDetails'] === undefined
      ? null
      : sessionStorage.getItem('forgotPasswordUserDetails'),
  error: '',
  formErrors: null,
  success: '',
  loading: false,
  showProfileDetailsModal: false,
  showProfileDropDownMenu: false,
  profileDetails:
    sessionStorage['profileDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['profileDetails']),
  rtosto:
    sessionStorage['rtosto'] === undefined
      ? null
      : JSON.parse(sessionStorage['rtosto']),
  goToVerify: false,
})

const actions = {
  async fetchToken({ commit }) {
    const formData = new FormData()
    formData.append('client_id', process.env.VUE_APP_CLIENT_ID)
    formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET)
    formData.append('grant_type', `client_credentials`)

    try {
      const response = await axios.post(`${apiBaseURL}/oauth/token`, formData)
      commit('setToken', response.data.access_token)
    } catch (error) {
      console.log(error)
    }
  },

  async signUp({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/create
        `,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.code === 202) {
        commit('setSuccess', {
          component: 'signUp',
          data: response.data,
        })
        commit('setVerifyUserDetails', {
          phone: payload.body.phone,
          userID: response.data.userID,
        })
      }
      if (response.data.code === 400) {
        commit('setError', {
          component: 'signUp',
          data: response.data,
        })
      }
      if (response.data.errors) {
        commit('setFormErrors', {
          component: 'signUp',
          errors: response.data.errors,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      if (error.response.data.errors) {
        commit('setFormErrors', {
          component: 'signUp',
          errors: error.response.data.errors,
        })
      } else {
        commit('setError', {
          component: 'signUp',
          data: {
            message: `Something went wrong, contact support`,
          },
        })
      }
    }
  },

  async login({ commit }, payload) {
    const token = payload.token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }

    const bodyParameters = {
      phone: payload.phone,
      password: payload.password,
    }
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/login`,
        bodyParameters,
        config,
      )
      commit('setLoadingFalse')
      commit('setGoToVerifyFalse')
      if (response.data.statusCode === 200) {
        commit('authenticate')
        commit('setUserDetails', response.data)
      } else if (response.data.statusCode === 403) {
        commit('setVerifyUserDetails', {
          phone: payload.phone,
          userID: response.data.customerID,
        })
        commit('setError', {
          component: 'verify',
          data: response.data,
        })
        commit('goToVerify', true)
      } else {
        commit('setError', {
          component: 'login',
          message: response.data.message,
          code: response.data.statusCode,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      commit('error')
      console.log(error)
      console.error
    }
  },

  async updateProfile({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/profile`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.errors) {
        commit('setFormErrors', {
          component: 'profileDetails',
          errors: response.data.errors,
        })
      }
      if (response.data.statusCode === 200) {
        commit('setSuccess', {
          component: 'updateProfile',
          data: response.data,
        })
        setTimeout(() => {
          commit('setSuccess', '')
        }, 5000)
      } else {
        commit('setError', {
          component: 'profile',
          data: response.data,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },

  async getCode({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/send_code`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.statusCode === 200) {
        commit('setSuccess', {
          component: 'forgotPassword',
          message: response.data.message,
          code: response.data.statusCode,
        })
        setTimeout(() => {
          commit('setSuccess', '')
        }, 5000)
        commit('setForgotPasswordUserDetails', payload.body.phone)
      } else {
        commit('setError', {
          component: 'forgotPassword',
          data: response.data,
        })
      }
      if (response.data.errors) {
        commit('setFormErrors', {
          component: 'forgotPassword',
          errors: response.data.errors,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },
  async resendCode({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/resend-verification`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.statusCode === 200) {
        commit('setSuccess', {
          component: 'resendCode',
          data: response.data,
        })
      } else {
        commit('setError', {
          component: 'resendCode',
          data: response.data,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },

  async verifyCustomer({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/verify`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.responseCode === 200) {
        commit('setSuccess', {
          component: 'verify',
          message: response.data.message,
          code: response.data.responseCode,
        })
        setTimeout(() => {
          commit('setSuccess', '')
        }, 5000)
      } else {
        commit('setError', {
          component: 'verify',
          message: response.data.message,
          code: response.data.responseCode,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },

  async changePassword({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/customers/forgot_password`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.statusCode === 200) {
        commit('setSuccess', {
          component: 'changePassword',
          data: response.data,
        })
      }
      if (response.data.errors) {
        commit('setFormErrors', {
          component: 'changePassword',
          errors: response.data.errors,
        })
      }
      if (response.data.statusCode === 100) {
        commit('setError', {
          component: 'changePassword',
          message: response.data.message,
          code: response.data.statusCode,
        })
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },

  async fetchProfileDetails({ commit }, payload) {
    const userID = payload.userID
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/customers/profile/${userID}`,
        payload.config,
      )
      if (response.data.statusCode === 200) {
        commit('setProfileDetails', response.data)
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },

  setRedirectToAndOTPChanell({ commit }, payload) {
    commit('setRedirectToAndOTPChanell', payload)
  },

  clearFormFieldError({ commit }, payload) {
    commit('clearFormFieldError', payload)
  },

  setLoadingTrue({ commit }) {
    commit('setLoadingTrue')
  },

  openProfileDetailsModal({ commit }) {
    commit('openProfileDetailsModal')
  },
  closeProfileDetailsModal({ commit }) {
    commit('closeProfileDetailsModal')
  },
  openProfileDropDownMenu({ commit }) {
    commit('openProfileDropDownMenu')
  },
  closeProfileDropDownMenu({ commit }) {
    commit('closeProfileDropDownMenu')
  },

  setSuccess({ commit }, payload) {
    commit('setSuccess', payload)
  },
  setError({ commit }, payload) {
    commit('setError', payload)
  },
  logout({ commit }) {
    commit('logout')
  },
  setGoToVerifyFalse({ commit }) {
    commit('setGoToVerifyFalse')
  },
}

const mutations = {
  setToken(state, payload) {
    sessionStorage.setItem('token', payload)
    state.token = sessionStorage.getItem('token')
  },
  authenticate(state) {
    sessionStorage.setItem('authenticated', true)
    state.authenticated = sessionStorage.getItem('authenticated')
  },
  setSignupSuccessful(state) {
    state.signupSuccessful = true
  },
  setSignupSuccessfulfalse(state) {
    state.signupSuccessful = false
  },
  setUserDetails(state, payload) {
    sessionStorage.setItem('userDetails', JSON.stringify(payload))
    state.userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
  },
  setError(state, payload) {
    state.error = payload
  },
  setVerifyUserDetails(state, payload) {
    //TODO remove from sessionStorage after successful verification
    sessionStorage.setItem('verifyUserDetails', JSON.stringify(payload))
    state.verifyUserDetails = JSON.parse(
      sessionStorage.getItem('verifyUserDetails'),
    )
  },
  setFormErrors(state, payload) {
    state.formErrors = payload
  },
  setProfileDetails(state, payload) {
    sessionStorage.setItem('profileDetails', JSON.stringify(payload))
    state.profileDetails = JSON.parse(sessionStorage.getItem('profileDetails'))
  },
  clearFormFieldError(state, payload) {
    if (state.formErrors !== null) {
      if (state.formErrors.errors !== undefined) {
        if (
          Object.prototype.hasOwnProperty.call(state.formErrors.errors, payload)
        ) {
          delete state.formErrors.errors[payload]
        }
      }
    }
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setForgotPasswordUserDetails(state, payload) {
    sessionStorage.setItem('forgotPasswordUserDetails', payload)
    state.forgotPasswordUserDetails = sessionStorage.getItem(
      'forgotPasswordUserDetails',
    )
  },
  setLoadingTrue(state) {
    state.loading = true
  },
  setLoadingFalse(state) {
    state.loading = false
  },
  logout(state) {
    sessionStorage.clear()
    state.authenticated = false
  },
  openProfileDetailsModal(state) {
    state.showProfileDetailsModal = true
  },
  closeProfileDetailsModal(state) {
    state.showProfileDetailsModal = false
  },
  openProfileDropDownMenu(state) {
    state.showProfileDropDownMenu = true
  },
  closeProfileDropDownMenu(state) {
    state.showProfileDropDownMenu = false
  },
  goToVerify(state, payload) {
    state.goToVerify = payload
  },
  setGoToVerifyFalse(state) {
    state.goToVerify = false
  },

  setRedirectToAndOTPChanell(state, payload) {
    sessionStorage.setItem('rtosto', JSON.stringify(payload))
    state.rtosto = JSON.parse(sessionStorage.getItem('rtosto'))
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
