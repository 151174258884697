<template>
  <div class="font-montserrat px-4 py-4 font-bold text-gray-700">
    Send money
  </div>
  <div class="flex px-4 py-2 border-b gap-4 items-center">
    <div
      @click="openBeneficiariesModal()"
      class="flex flex-grow gap-4 items-center"
    >
      <div
        class="
          relative
          pb-2/3
          rounded-md
          w-11
          h-8
          bg-gray-200
          border
          overflow-hidden
        "
      >
        <img
          v-if="selectedBeneficiary !== null"
          :src="setCountryFlagURL(selectedBeneficiary.country)"
          class="absolute block h-full w-full object-cover"
        />
        <img
          v-else
          :src="setCountryFlagURL('ken')"
          class="absolute block h-full w-full object-cover"
        />
      </div>
      <div v-if="selectedBeneficiary === null" class="flex-grow">
        <div class="text-md text-gray-400">Choose recipient</div>
      </div>
      <div v-if="selectedBeneficiary !== null" class="flex-grow">
        <div class="text-md text-gray-700">
          {{ selectedBeneficiary.names }}
        </div>
        <div class="text-xs text-gray-400">
          {{
            getFirstXStringCharacters(selectedBeneficiary.account, 3)
          }}xxxxxxxx{{
            getLastXStringCharacters(selectedBeneficiary.account, 2)
          }}
        </div>
      </div>
      <svg
        v-if="selectedBeneficiary === null"
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 fill-current text-gray-500"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </div>
    <div
      v-if="selectedBeneficiary !== null"
      @click="deselectBeneficiary()"
      class="flex justify-end text-md py-2 px-2 opacity-50"
    >
      <svg
        version="1.1"
        width="12"
        height="12"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 492 492"
        xml:space="preserve"
      >
        <path
          d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import utils from '@/composables/utils'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const selectedBeneficiary = computed(
      () => store.state.beneficiary.selectedBeneficiary,
    )
    const beneficiaries = computed(() => store.state.beneficiary.beneficiaries)
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)

    const fetchBeneficiaries = () => {
      const payload = {
        userID: userDetails.value.userID,
        jwt: userDetails.value.jwt,
        token: token.value,
      }
      store.dispatch('beneficiary/setLoadingTrue')
      store.dispatch('beneficiary/fetchBeneficiaries', payload)
    }
    fetchBeneficiaries()

    const openBeneficiariesModal = () => {
      if (!beneficiaries.value.length) {
        return router.push({
          name: 'add-recipient',
          params: { returnUrl: 'home' },
        })
      }
      return store.dispatch('beneficiary/openBeneficiariesModal')
    }

    const closeBeneficiariesModal = () => {
      store.dispatch('beneficiary/closeBeneficiariesModal')
    }

    const deselectBeneficiary = () => {
      store.dispatch('beneficiary/deselectBeneficiary')
    }

    const {
      getLastXStringCharacters,
      getFirstXStringCharacters,
      setCountryFlagURL,
    } = utils()

    return {
      setCountryFlagURL,
      selectedBeneficiary,
      deselectBeneficiary,
      openBeneficiariesModal,
      closeBeneficiariesModal,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
