<template>
  <div>
    <div class="hidden md:block absolute w-full h-full left-0 top-0">
      <DashboardWrapper><Home /></DashboardWrapper>
    </div>
    <div class="md:hidden block relative max-w-xl mx-auto">
      <HomeMobile />
    </div>
  </div>
</template>
<script>
import HomeMobile from '@/components/mobile/home/Home.vue'
import DashboardWrapper from '../desktop/DashboadWrapper.vue'
import Home from '@/components/desktop/home/Home.vue'
import { computed } from '@vue/reactivity'
import utils from '@/composables/utils'
import { useStore } from 'vuex'

export default {
  components: { HomeMobile, DashboardWrapper, Home },
  setup() {
    const store = useStore()
    const token = computed(() => store.state.auth.token)
    const userDetails = computed(() => store.state.auth.userDetails)
    const { setRequestConfig } = utils()
    const jwt = userDetails.value.jwt
    const config = setRequestConfig(token.value, jwt)
    const getPaymentInfo = () => {
      const userID = userDetails.value.userID
      const payload = {
        config,
        userID,
      }
      store.dispatch('paymentInfo/getPaymentInfo', payload)
    }
    getPaymentInfo()

    //TODO check the use of this!
    store.dispatch('paymentInfo/unsetCVC')
  },
}
</script>
