import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const state = () => ({
  error: '',
  payouts: [],
  payoutIsLoading: false,
  banksPayments: null,
  mobilePayments: null,
})

const actions = {
  async fetchPayouts({ commit }, payload) {
    const token = payload.token
    const isoCode = payload.isoCode
    const jwt = payload.jwt
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        JWTToken: jwt,
        Accept: 'application/json',
      },
    }
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/payouts/${isoCode}`,
        config,
      )
      commit('setPayoutsIsLoadingFalse')
      if (response.data) {
        if (response.data[0].statusCode === 200) {
          commit('setPayouts', response.data)
        } else {
          commit('setErrors', response.data[0])
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  setPayoutsIsLoadingTrue({ commit }) {
    commit('setPayoutsIsLoadingTrue')
  },
}
const mutations = {
  setPayouts(state, payload) {
    state.error = ''
    state.payouts = payload
    const mobilePayments = payload.filter((item) => {
      return item.name === 'Mobile Payments'
    })
    const banksPayments = payload.filter((item) => {
      return item.name === 'Banks Payments'
    })

    if (mobilePayments.length) {
      state.mobilePayments = mobilePayments[0]
    }
    if (banksPayments.length) {
      state.banksPayments = banksPayments[0]
    }
  },
  setErrors(state, payload) {
    state.error = payload
    state.mobileMoney = null
    state.banks = null
  },
  setPayoutsIsLoadingTrue(state) {
    state.payoutIsLoading = true
  },
  setPayoutsIsLoadingFalse(state) {
    state.payoutIsLoading = false
  },
}
export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
