<template>
  <div class="md:hidden block">
    <HomeMobile />
  </div>
  <div class="hidden md:block">
    <DashboardWrapper><Transactions /></DashboardWrapper>
  </div>
</template>

<script>
import DashboardWrapper from '../desktop/DashboadWrapper.vue'
import Transactions from '@/components/desktop/Transactions.vue'
import HomeMobile from '@/components/mobile/home/Home.vue'

export default {
  components: { DashboardWrapper, Transactions, HomeMobile },
}
</script>
