<template>
  <div class="relative max-w-xl mx-auto">
    <div class="absolute top-0 left-0 right-0 flex px-6 py-6 mb-2 z-10">
      <div class="" @click="$router.go(-1)">
        <svg
          version="1.1"
          width="18"
          height="18"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 443.52 443.52"
          fill="#fff"
          xml:space="preserve"
        >
          <path
            d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712L143.492,221.863z"
          />
        </svg>
      </div>
      <div class="flex-grow"></div>
      <div class="" @click="$router.push({ name: 'help' })">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          fill="#fff"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
          />
        </svg>
      </div>
    </div>
    <div
      class="relative w-full z-0 pt-10 px-6 py-4"
      :class="{
        'bg-green-500': transaction.transactionStatus === 'Delivered',
        'bg-yellow-400': transaction.transactionStatus === 'Pending',
        'bg-red-400': transaction.transactionStatus === 'Failed',
      }"
    >
      <div class="py-1 text-center text-white space-y-2">
        <div class="rounded-full w-12 h-12 inline-block">
          <svg
            v-if="transaction.transactionStatus === 'Delivered'"
            xmlns="http://www.w3.org/2000/svg"
            class="w-12 h-12 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>

          <svg
            v-if="transaction.transactionStatus === 'Failed'"
            xmlns="http://www.w3.org/2000/svg"
            class="w-12 h-12 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-if="transaction.transactionStatus === 'Pending'"
            xmlns="http://www.w3.org/2000/svg"
            className="w-12 h-12"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="{2}"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="font-montserrat text-xs font-bold">
          {{ transaction.transactionStatus }}
        </div>
        <div class="font-montserrat text-lg">
          {{ transaction.recipientCurrency }} {{ transaction.payoutAmount }}
        </div>
        <div class="font-montserrat text-sm">
          to {{ transaction.benefitiaryName }}
        </div>
      </div>
    </div>

    <div class="relative px-6 py-4">
      <div class="text-xs py-2 text-gray-400 font-bold font-montserrat">
        Transaction details
      </div>
      <table width="100%" class="w-full block">
        <tr class="flex w-full text-sm text-gray-500 py-1">
          <td class="flex-grow">Paid</td>

          <td class="text-right font-medium">
            {{ transaction.senderCurrency }}
            {{ transaction.sendAmount }}
          </td>
        </tr>
        <tr class="flex w-full text-sm text-gray-500 py-1">
          <td class="flex-grow">Fee</td>
          <td class="text-right font-medium">
            {{ transaction.fee }}
          </td>
        </tr>
        <tr class="flex w-full text-sm text-gray-500 py-1">
          <td class="flex-grow">Amount converted</td>
          <td class="text-right font-medium">
            {{ transaction.senderCurrency }}
            {{ transaction.sendAmount }}
          </td>
        </tr>
        <tr class="flex w-full text-sm text-gray-500 py-1">
          <td class="flex-grow">Exchange rate</td>
          <td class="text-right font-medium">
            1 {{ transaction.senderCurrency }} = {{ transaction.exchangeRate }}
            {{ transaction.recipientCurrency }}
          </td>
        </tr>
        <tr
          class="flex w-full text-sm text-gray-500 py-3 border-t border-b mt-2"
        >
          <td class="flex-grow">
            Amount
            <span v-if="transaction.transactionStatus == 'Pending'">to be</span>
            <span v-if="transaction.transactionStatus == 'Failed'"
              >that was to be</span
            >
            delivered
          </td>
          <td class="text-right font-medium">
            {{ transaction.recipientCurrency }}
            {{ transaction.payoutAmount }}
          </td>
        </tr>
      </table>
      <div class="py-6">
        <div class="text-sm text-gray-500">
          {{ transaction.transactionReference }}
        </div>
        <div class="text-xs text-gray-400">
          {{ formatDateTime(transaction.createdDate, 'Do MMMM YYYY, h:mm a') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'

export default {
  setup() {
    const store = useStore()
    const { formatDateTime } = utils()
    const transaction = computed(() => store.state.transactions.transaction)
    const userDetails = computed(() => store.state.auth.userDetails)
    const { calculateExchangeRate } = createTransaction()
    return {
      transaction,
      userDetails,
      formatDateTime,
      calculateExchangeRate,
    }
  },
}
</script>
