<template>
  <div class="relative max-w-xl mx-auto my-4">
    <GoBack class="px-6 py-4" />

    <div class="relative px-6">
      <div class="block">
        <div class="font-montserrat text-2xl font-bold">Account details</div>
      </div>

      <ProfileDetailsComponent />
    </div>
    <AlertSuccess v-if="showSuccessAlert">
      <div class="w-full flex justify-center">{{ getSuccess() }}</div>
    </AlertSuccess>
  </div>
</template>
<script>
import ProfileDetailsComponent from '@/components/ProfileDetails'
import AlertSuccess from '@/components/partials/AlertSuccess'
import GoBack from '@/components/partials/GoBack'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

export default {
  components: { ProfileDetailsComponent, AlertSuccess, GoBack },
  setup() {
    const store = useStore()
    const success = computed(() => store.state.auth.success)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)

    const getSuccess = () => {
      if (success.value !== '') {
        if (success.value.component === 'updateProfile') {
          return success.value.data.message
        }
      }
    }

    const closeSuccessAlert = () => {
      store.dispatch('alerts/closeSuccessAlert')
    }
    return {
      getSuccess,
      showSuccessAlert,
      closeSuccessAlert,
    }
  },
}
</script>
