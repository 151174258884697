<template>
  <div
    v-click-away="closeProfileDropDownMenu"
    class="
      rounded-sm
      border
      bg-white
      absolute
      top-14
      right-6
      w-56
      z-10
      shadow-md
      transition
      ease-in
    "
  >
    <div
      @click="openProfileDetailsModal()"
      class="px-4 py-3 text-sm border-b hover:bg-yellow-50"
    >
      <svg
        class="inline-block mr-4"
        width="16"
        height="16"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
        />
      </svg>
      <span to="/account" class="align-middle inline-block text-gray-600"
        >My Profile</span
      >
    </div>
    <router-link
      :to="{ name: 'profile-payment' }"
      class="block px-4 py-3 text-sm border-b hover:bg-yellow-50 cursor-pointer"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="inline-block mr-4"
      >
        <path
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12l-.01-.001c1.65 0 3-1.35 3-3v-8 0c0-1.66-1.35-3-3-3h-12l-.01 0c-1.66 0-3 1.34-3 3 0 0 0 0 0 0v8l0-.01c-.01 1.65 1.34 3 2.99 3Z"
        ></path></svg
      ><span class="align-middle inline-block text-gray-600"
        >Payment details</span
      >
    </router-link>
    <a
      href="http://help.helasend.com"
      target="_blank"
      class="block px-4 py-3 text-sm border-b hover:bg-yellow-50"
    >
      <svg
        class="inline-block mr-4"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12c0 5.51 4.48 10 10 10 5.51 0 10-4.49 10-10 0-5.52-4.49-10-10-10Zm1 16h-2v-2h2v2Zm.97-4.885c-.2.15-.39.3-.54.45 -.41.4-.44.77-.45.79v.13h-2v-.17c0-.12.02-1.18 1.02-2.18 .19-.2.43-.4.69-.6 .734-.6 1.21-1.029 1.21-1.63 0-1.07-.87-1.94-1.94-1.94 -1.07 0-1.94.86-1.94 1.93h-2c0-2.17 1.76-3.94 3.93-3.94 2.16 0 3.93 1.76 3.93 3.93 0 1.59-1.18 2.55-1.96 3.18Z"
        />
      </svg>
      <span class="align-middle inline-block text-gray-600">Help</span>
    </a>
    <div @click="logout()" class="px-4 py-3 text-sm hover:bg-yellow-50">
      <svg
        class="inline-block mr-4"
        width="16"
        height="16"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h48v48H0Z" />
        <path
          d="M26 6h-4v20h4V6Zm9.67 4.33l-2.83 2.83C35.98 15.73 38 19.62 38 24c0 7.73-6.27 14-14 14s-14-6.27-14-14c0-4.38 2.02-8.27 5.16-10.84l-2.83-2.83C8.47 13.63 6 18.52 6 24c0 9.94 8.06 18 18 18s18-8.06 18-18c0-5.48-2.47-10.37-6.33-13.67Z"
        />
      </svg>
      <span class="align-middle inline-block text-gray-600">Logout</span>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()

    const closeProfileDropDownMenu = () => {
      store.dispatch('auth/closeProfileDropDownMenu')
    }
    const openProfileDetailsModal = () => {
      closeProfileDropDownMenu()
      store.dispatch('auth/openProfileDetailsModal')
    }

    const logout = () => {
      store.dispatch('auth/logout')
      window.location.href = 'https://helasend.com/'
    }

    return {
      logout,
      openProfileDetailsModal,
      closeProfileDropDownMenu,
    }
  },
}
</script>
