<template>
  <div
    v-if="accountTerminated()"
    class="block bg-red-100 mb-8 rounded-lg px-4 py-3"
  >
    <div class="flex items-start">
      <svg
        height="60"
        class="mr-3"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0Z" />
        <path
          d="M14 14.252v7.74H4l-.01-.01c-.01-4.42 3.58-8.01 7.99-8.01 .67-.01 1.34.08 2 .25Zm-2-1.26c-3.32 0-6-2.69-6-6 0-3.32 2.68-6 6-6 3.31 0 6 2.685 6 6 0 3.31-2.69 6-6 6Zm7 3.58l2.12-2.122 1.41 1.41 -2.13 2.12 2.122 2.12 -1.42 1.41 -2.13-2.13 -2.13 2.122 -1.42-1.42 2.12-2.13 -2.122-2.13 1.41-1.42 2.12 2.12Z"
        />
      </svg>

      <div class="flex-grow">
        <div class="text-md font-montserrat font-medium">
          Verify your account
        </div>
        <div class="text-xs opacity-60">
          Increase your sending limits by verifying your account. Your maximum
          sending limit is $200.
        </div>

        <button
          class="
            px-5
            py-1.5
            font-montserrat font-medium
            text-sm
            mt-3
            bg-yellow-300
            rounded-md
          "
        >
          Start verification
        </button>
      </div>
      <div class="">
        <div class="text-md font-montserrat font-medium">
          Your account is terminated
        </div>
        <div class="text-xs opacity-60">
          We have found suspicious activity with your account. Please contact
          support to find out more. Do not attempt to create a new account as it
          will be terminated.
        </div>
      </div>
    </div>
  </div>
  <div class="block bg-gray-100 mb-8 rounded-lg px-4 py-3">
    <div class="flex items-start">
      <!-- Mobile -->
      <svg
        width="90"
        class="block sm:hidden mr-3 flex-shrink"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0Z" />
        <path
          d="M3.78 2.82L11.99.99l8.21 1.82 0 0c.45.1.78.5.78.97v9.987l0-.01c0 2-1.01 3.87-2.68 4.99l-6.33 4.21 -6.33-4.22 -.01-.01c-1.67-1.12-2.68-2.99-2.68-5v-9.99l-.01 0c0-.47.32-.88.78-.98Zm8.21 8.174l-.01-.001c1.38 0 2.5-1.12 2.5-2.5 0-1.39-1.12-2.5-2.5-2.51l-.01 0c-1.39 0-2.5 1.11-2.5 2.5 0 1.38 1.11 2.5 2.5 2.49Zm-4.48 5h8.946l0 0c-.28-2.48-2.5-4.26-4.97-3.99 -2.1.23-3.76 1.88-3.99 3.98Z"
        />
      </svg>

      <!-- Desktop -->
      <svg
        height="42"
        class="hidden sm:block mr-3"
        viewBox="0 0 24 24"
        fill="#333"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="none" d="M0 0h24v24H0Z" />
        <path
          d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4Zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8Z"
        />
      </svg>
      <div class="flex-grow">
        <div class="text-md font-montserrat font-medium">
          Verify your account
        </div>
        <div class="text-xs opacity-60">
          Increase your sending limits by verifying your account. Your maximum
          sending limit is $200.
        </div>

        <button
          class="
            px-5
            py-1.5
            font-montserrat font-medium
            text-sm
            mt-3
            bg-yellow-300
            rounded-md
          "
          @click="verify()"
        >
          Start verification
        </button>
      </div>
    </div>
  </div>
  <ThinSpin v-if="verifyIsLoading" />
</template>

<script>
import { computed } from '@vue/reactivity'
import ThinSpin from '@/components/partials/ThinSpin.vue'
import { useStore } from 'vuex'
import veriff from '@/composables/veriff'

import definedConstants from '@/composables/definedConstants'

import { useRouter } from 'vue-router'

import utils from '@/composables/utils'

import { watch } from '@vue/runtime-core'

export default {
  components: { ThinSpin },
  setup() {
    const store = useStore()
    const router = useRouter()
    const profileDetails = computed(() => store.state.auth.profileDetails)

    const userDetails = computed(() => store.state.auth.userDetails)

    const kycSession = computed(() => store.state.verify.kycSession)
    const token = computed(() => store.state.auth.token)
    const { setRequestConfig } = utils()
    const config = setRequestConfig(token.value, userDetails.value.jwt)

    const verifyIsLoading = computed(() => store.state.verify.verifyIsLoading)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }

    const veriffURL = computed(() => store.state.verify.veriffURL)
    const { setRequestData, setVeriffPerson, createFrame, status } = veriff()

    const { appBaseURL } = definedConstants()
    const currentRoute = router.currentRoute.value.path
    const redirectUrl = `${appBaseURL}${currentRoute}`

    const verify = async () => {
      const person = setVeriffPerson(userDetails.value.name)
      const body = {
        redirectUrl,
        person,
      }
      store.dispatch('verify/setVerifyIsLoadingTrue')
      await store.dispatch('verify/setVeriffSession', body)
      await store.dispatch(
        'verify/setKYCSession',
        setRequestData(userDetails.value.userID, kycSession.value, config),
      )
      await createFrame(veriffURL.value)
    }
    watch(
      () => status.value,
      () => {
        if (status.value === 'FINISHED') {
          fetchProfileDetails()
        }
      },
    )

    const accountTerminated = () => {
      if (profileDetails.value !== null) {
        return profileDetails.value.approved === -17
      }
      return false
    }

    return {
      verify,
      profileDetails,
      verifyIsLoading,
      accountTerminated,
    }
  },
}
</script>
