<template>
  <div class="block py-6 border-b">
    <div class="text-xs py-2 text-gray-400 font-bold font-montserrat">
      RECIPIENT
    </div>
    <div class="flex items-center">
      <div class="flex-grow block">
        <div class="text-xl font-montserrat text-gray-600 font-medium">
          {{ selectedBeneficiary.names }}
        </div>
        <div
          v-if="selectedBeneficiary.payoutType !== 'Mobile Payments'"
          class="flex items-center my-1 text-xs"
        >
          <div class="">
            <svg
              class="inline-block"
              fill="#777"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 1L2 6v2h19V6m-5 4v7h3v-7M2 22h19v-3H2m8-9v7h3v-7m-9 0v7h3v-7H4Z"
              />
            </svg>
          </div>
          <div class="ml-2 mt-1 text-md">
            <span class="text-gray-700">Bank</span>
            <span class="text-gray-500 ml-4"
              >***********{{
                getLastXStringCharacters(selectedBeneficiary.account, 3)
              }}</span
            >
          </div>
        </div>
        <div v-else class="flex items-center my-1 text-xs">
          <div class="">
            <svg
              class="inline-block"
              fill="#777"
              width="16"
              height="16"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32 2H16c-3.31 0-6 2.69-6 6v32c0 3.31 2.69 6 6 6h16c3.31 0 6-2.69 6-6V8c0-3.31-2.69-6-6-6Zm-4 40h-8v-2h8v2Zm6.5-6h-21V8h21v28Z"
              />
              <path fill="none" d="M0 0h48v48H0Z" />
            </svg>
          </div>
          <div class="ml-2 mt-1 text-md">
            <span class="text-gray-700">Mobile Money</span>
            <span class="text-gray-500 ml-4"
              >+{{
                getFirstXStringCharacters(selectedBeneficiary.account, 4)
              }}*******{{
                getLastXStringCharacters(selectedBeneficiary.account, 3)
              }}</span
            >
          </div>
        </div>
      </div>

      <div class="block">
        <button
          class="
            inline-block
            px-4
            py-2
            text-xs
            bg-gray-200
            rounded-md
            border-gray-300 border
            text-gray-700
          "
          @click="$router.push({ name: 'sendmoney-recipient' })"
        >
          Change
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
export default {
  setup() {
    const store = useStore()
    const selectedBeneficiary = computed(
      () => store.state.beneficiary.selectedBeneficiary,
    )
    const { getFirstXStringCharacters, getLastXStringCharacters } = utils()

    return {
      selectedBeneficiary,
      getLastXStringCharacters,
      getFirstXStringCharacters,
    }
  },
}
</script>
