<template>
  <div
    class="fixed left-0 top-0 w-full h-full z-10 transition-all"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeAddBeneficiaryModal"
    ></div>
    <div
      class="
        absolute
        bottom-0
        md:top-20 md:bottom-auto
        left-0
        right-0
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div
        class="
          block
          py-4
          px-4
          font-montserrat font-medium
          text-xl text-gray-600
        "
      >
        Add recipient
      </div>
      <AddBeneficiary />
    </div>
  </div>
</template>

<script>
import AddBeneficiary from '@/components/AddBeneficiary.vue'
import { useStore } from 'vuex'
export default {
  components: { AddBeneficiary },
  setup() {
    const store = useStore()
    const closeAddBeneficiaryModal = () => {
      store.dispatch('beneficiary/closeAddBeneficiaryModal')
    }
    return {
      closeAddBeneficiaryModal,
    }
  },
}
</script>
