<template>
  <div class="block py-6 border-b">
    <div class="text-xs pb-2 text-gray-400 font-bold font-montserrat">
      AMOUNT
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-500">You send</div>
      <div class="text-md flex-grow text-right text-gray-700 font-medium">
        {{ userDetails.country.currency }} {{ toLocaleFixed(payAmount) }}
      </div>
    </div>

    <div class="flex py-1">
      <div class="text-md">
        <div class="text-gray-500">Total Fee</div>
        <div class="text-xs text-gray-400">
          Includes debit/credit card fees & our fee
        </div>
      </div>
      <div class="text-md flex-grow text-right text-gray-700 font-medium">
        {{ userDetails.country.currency }} {{ toLocaleFixed(totalFee) }}
      </div>
    </div>
    <div class="my-2 border-t border-dashed"></div>
    <div class="flex py-1">
      <div class="text-md text-gray-500">Amount deducted</div>
      <div class="text-md flex-grow text-right text-gray-700 font-medium">
        {{ userDetails.country.currency }} {{ toLocaleFixed(payAmount) }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-700">They receive</div>
      <div
        class="
          text-md
          flex-grow
          text-right text-gray-700
          font-bold font-montserrat
        "
      >
        {{ beneficiaryCurrencyDetails.currency }}
        {{ toLocaleFixed(payoutAmount) }}
      </div>
    </div>
  </div>
</template>

<script>
import definedConstants from '@/composables/definedConstants'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'

export default {
  setup() {
    const store = useStore()

    const { calculateExchangeRate } = createTransaction()

    const userDetails = computed(() => store.state.auth.userDetails)

    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )

    const totalFee = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.totalFee,
    )

    const payAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payAmount,
    )
    //amount beneficiary receives in beneficiary's currency
    const payoutAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payoutAmount,
    )

    const sendAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.sendAmount,
    )

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )

    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )

    const { currencyOptions } = definedConstants()

    const setCurrencyOptionsForSender = () => {
      return {
        ...currencyOptions,
        currency: userDetails.value.country.currency,
        currencyDisplay: 'code',
      }
    }
    const setCurrencyOptionsForRecipient = () => {
      return {
        ...currencyOptions,
        currency: beneficiaryCurrencyDetails.value.currency,
        currencyDisplay: 'code',
      }
    }

    const getRecipientExchangeRates = () => {
      return beneficiaryCurrencyDetails.value.rates
    }

    const { isSameCountry, toLocaleFixed } = utils()

    return {
      totalFee,
      payAmount,
      sendAmount,
      userDetails,
      payoutAmount,
      isSameCountry,
      toLocaleFixed,
      calculateExchangeRate,
      senderCurrencyDetails,
      getRecipientExchangeRates,
      beneficiaryCurrencyDetails,
      setCurrencyOptionsForSender,
      setCurrencyOptionsForRecipient,
    }
  },
}
</script>
