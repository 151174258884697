<template>
  <div class="absolute w-full h-full left-0 top-0 bg-gray-50">
    <div class="relative mx-auto md:w-full md:mx-0 mb-4 mt-2 md:my-0">
      <div class="flex md:hidden px-6 py-4">
        <GoBack />
        <div class="flex-grow"></div>
        <div class="p-1">
          <svg
            width="24"
            height="24"
            fill="#aaa"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h48v48H0Z" />
            <path
              d="M22 34h4V22h-4v12Zm2-30C12.95 4 4 12.95 4 24c0 11.05 8.95 20 20 20s20-8.95 20-20 -8.95-20-20-20Zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16 -7.18 16-16 16Zm-2-22h4v-4h-4v4Z"
            />
          </svg>
        </div>
      </div>
      <Referral />
      <div class="relative md:col">
        <div
          class="
            grid grid-cols-3
            text-center
            border-b-2
            sticky
            top-0
            bg-gray-50
            md:bg-none
            z-2
          "
        >
          <a
            href="#"
            class="col px-4 py-3 text-gray-500 text-sm flex-grow"
            :class="{
              'border-b-2 border-gray-500 font-bold text-gray-900': setTab == 1,
            }"
            @click="setTab = 1"
            >Invited</a
          >
          <a
            href="#"
            class="col px-4 py-3 text-gray-500 text-sm flex-grow"
            :class="{
              'border-b-2 border-gray-500 font-bold text-gray-900': setTab == 2,
            }"
            @click="setTab = 2"
            >Completed</a
          >
          <a
            href="#"
            class="col px-4 py-3 text-gray-500 text-sm flex-grow"
            :class="{
              'border-b-2 border-gray-500 font-bold text-gray-900': setTab == 3,
            }"
            @click="setTab = 3"
            >Leaderboard</a
          >
        </div>
        <div class="block" v-show="setTab == 1">
          <div class="flex px-4 py-3 border-b">
            <div class="w-9 h-9 p-2 rounded-full bg-gray-200">
              <svg
                fill="#999"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                />
              </svg>
            </div>
            <div class="block px-4">
              <div class="text-sm">Kelly C</div>
              <div class="text-xs opacity-50">254712****212</div>
            </div>
          </div>
          <div class="flex px-4 py-3 border-b">
            <div class="w-9 h-9 p-2 rounded-full bg-gray-200">
              <svg
                fill="#999"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                />
              </svg>
            </div>
            <div class="block px-4">
              <div class="text-sm">Mike M</div>
              <div class="text-xs opacity-50">254712****212</div>
            </div>
          </div>
          <div class="block py-3 text-center">
            <button
              class="
                px-4
                py-2
                bg-gray-100
                inline-block
                rounded-md
                border
                text-xs
              "
            >
              Load more
            </button>
          </div>
        </div>
        <div class="block" v-show="setTab == 2">
          <div class="flex px-4 py-3 border-b items-center">
            <div class="w-9 h-9 p-2 rounded-full bg-gray-200">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                />
              </svg>
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Humphrey S</div>
              <div class="text-xs opacity-50">+91712****12</div>
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke="#00ce47"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="flex px-4 py-3 border-b items-center">
            <div class="w-9 h-9 p-2 rounded-full bg-gray-200">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                />
              </svg>
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Tony Stark H</div>
              <div class="text-xs opacity-50">+91712****12</div>
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke="#00ce47"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="flex px-4 py-3 border-b items-center">
            <div class="w-9 h-9 p-2 rounded-full bg-gray-200">
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  d="M10 9l-.01-.001c1.65 0 3-1.35 3-3 0-1.66-1.35-3-3-3l-.01 0c-1.66 0-3 1.34-3 3 0 1.65 1.34 3 3 3Zm-7 9l0 0c-.01-3.87 3.13-7.01 6.99-7.01 3.86-.01 7 3.13 7 6.99 0 0 0 0 0 0h-14Z"
                />
              </svg>
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Wilson G.</div>
              <div class="text-xs opacity-50">+91712****12</div>
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke="#00ce47"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="block py-3 text-center">
            <button
              class="
                px-4
                py-2
                bg-gray-100
                inline-block
                rounded-md
                border
                text-xs
              "
            >
              Load more
            </button>
          </div>
        </div>
        <div class="block" v-show="setTab == 3">
          <div class="flex px-4 py-3 border-b">
            <div
              class="
                w-8
                h-8
                rounded-full
                grid
                place-content-center
                bg-yellow-300
                font-montserrat font-medium
              "
            >
              1
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">James K</div>
              <div class="text-xs opacity-50">Earned AED 2,930.00</div>
            </div>
            <div class="block text-right">
              <div class="text-sm">123</div>
            </div>
          </div>
          <div class="flex px-4 py-3 border-b">
            <div
              class="
                w-8
                h-8
                rounded-full
                grid
                place-content-center
                bg-yellow-200
                font-montserrat font-medium
              "
            >
              2
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Susan M</div>
              <div class="text-xs opacity-50">Earned AED 2,120.00</div>
            </div>
            <div class="block text-right">
              <div class="text-sm">112</div>
            </div>
          </div>
          <div class="flex px-4 py-3 border-b">
            <div
              class="
                w-8
                h-8
                rounded-full
                grid
                place-content-center
                bg-yellow-100
                font-montserrat font-medium
              "
            >
              3
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Monicah C</div>
              <div class="text-xs opacity-50">Earned AED 1,120.00</div>
            </div>
            <div class="block text-right">
              <div class="text-sm">99</div>
            </div>
          </div>
          <div class="p-1 bg-gray-100 text-xs text-center">
            &bull;&bull;&bull;
          </div>
          <div class="flex px-4 py-3 border-b">
            <div
              class="
                w-8
                h-8
                rounded-full
                grid
                place-content-center
                bg-gray-100
                text-gray-500
                font-montserrat
              "
            >
              19
            </div>
            <div class="flex-grow px-4">
              <div class="text-sm">Me</div>
              <div class="text-xs opacity-50">Earned AED 100.00</div>
            </div>
            <div class="block text-right">
              <div class="text-sm">2</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { ref } from '@vue/reactivity'
import GoBack from '@/components/partials/GoBack.vue'
import Referral from '@/components/Referral.vue'
// import { useStore } from 'vuex'
export default {
  components: { GoBack, Referral },
  setup() {
    // const store = useStore()
    const setTab = ref(1)
    return {
      setTab,
    }
  },
}
</script>
