<template>
  <form @submit.prevent="createTransaction()" class="block p-6">
    <div class="text-xl font-montserrat font-medium pb-4">
      Enter card CVV / CVC
    </div>
    <div class="flex gap-6 items-center mb-2">
      <div class="flex-shrink">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="143"
          height="89"
          viewBox="0 0 143 89"
        >
          <g id="Group_75" data-name="Group 75" transform="translate(-31 -652)">
            <g
              id="Rectangle_84"
              data-name="Rectangle 84"
              transform="translate(31 652)"
              fill="#efefef"
              stroke="#c4c4c4"
              stroke-width="0.5"
            >
              <rect width="143" height="89" rx="4" stroke="none" />
              <rect
                x="0.25"
                y="0.25"
                width="142.5"
                height="88.5"
                rx="3.75"
                fill="none"
              />
            </g>
            <rect
              id="Rectangle_85"
              data-name="Rectangle 85"
              width="143"
              height="21"
              transform="translate(31 676)"
              fill="rgba(0,0,0,0.5)"
            />
            <circle
              id="Ellipse_22"
              data-name="Ellipse 22"
              cx="3"
              cy="3"
              r="3"
              transform="translate(131 684)"
              fill="#ffd202"
            />
            <circle
              id="Ellipse_23"
              data-name="Ellipse 23"
              cx="3"
              cy="3"
              r="3"
              transform="translate(139 684)"
              fill="#ffd202"
            />
            <circle
              id="Ellipse_24"
              data-name="Ellipse 24"
              cx="3"
              cy="3"
              r="3"
              transform="translate(147 684)"
              fill="#ffd202"
            />
            <rect
              id="Rectangle_86"
              data-name="Rectangle 86"
              width="71"
              height="9"
              transform="translate(53 682)"
              fill="rgba(255,255,255,0.24)"
            />
            <rect
              id="Rectangle_91"
              data-name="Rectangle 91"
              width="19"
              height="5"
              transform="translate(38 728)"
              fill="rgba(0,0,0,0.08)"
            />
            <rect
              id="Rectangle_92"
              data-name="Rectangle 92"
              width="50"
              height="5"
              transform="translate(62 728)"
              fill="rgba(0,0,0,0.08)"
            />
          </g>
        </svg>
      </div>
      <div class="flex-grow">
        <div class="text-md text-gray-600">
          This is the last 3 digit code displayed at the back of your VISA or
          Mastercard.
        </div>
      </div>
    </div>

    <div class="py-4">
      <input
        required
        v-model="cvc"
        type="tel"
        maxlength="3"
        class="
          outline-none
          px-4
          py-2
          border border-gray-300
          rounded-md
          bg-gray-50
          w-full
          font-montserrat font-medium
        "
        placeholder="Enter CVV"
      />
    </div>
    <div class="flex gap-4">
      <div
        class="
          flex-shrink
          bg-gray-200
          text-gray-500
          px-6
          py-2
          rounded-md
          font-montserrat font-medium
          mt-4
        "
        @click="closeCVVModal()"
      >
        Cancel
      </div>
      <button
        type="submit"
        class="
          flex-grow
          bg-yellow-300
          px-4
          py-2
          rounded-md
          font-montserrat font-medium
          mt-4
        "
      >
        Confirm
      </button>
    </div>
  </form>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const cvc = ref('')
    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const userDetails = computed(() => store.state.auth.userDetails)
    const token = computed(() => store.state.auth.token)
    const success = computed(() => store.state.transactions.success)
    const error = computed(() => store.state.transactions.error)
    const takeMeToVerify = computed(
      () => store.state.transactions.takeMeToVerify,
    )

    const buildTransactionObject = (transaction) => {
      const transactionOnCreation = {
        ...transaction,
        paymentInfoID: paymentInfo.value.paymentInfoID,
        cvc: cvc.value,
      }
      store.dispatch(
        'transactions/buildTransactionObject',
        transactionOnCreation,
      )
    }
    buildTransactionObject(transactionOnCreation.value)

    const closeCVVModal = () => {
      store.dispatch('paymentInfo/closeCVVModal')
    }

    const createTransaction = async () => {
      if (cvc.value) {
        buildTransactionObject(transactionOnCreation.value)
        const { setRequestConfig } = utils()
        const jwt = userDetails.value.jwt
        const config = setRequestConfig(token.value, jwt)
        const body = transactionOnCreation.value
        const payload = {
          body,
          config,
        }
        closeCVVModal()
        store.dispatch('transactions/setLoadingTrue')
        await store.dispatch('transactions/createTransaction', payload)
        store.dispatch('paymentInfo/unsetCVC')
        if (success.value) {
          // store.dispatch('beneficiary/deselectBeneficiary')
          router.push({ name: 'sendmoney-complete' })
        } else if (error.value !== null) {
          store.dispatch('alerts/openErrorAlert')
        }
        if (takeMeToVerify.value) {
          store.dispatch('verify/setGoToVeriffTrue')
        }
      }
    }
    return { cvc, closeCVVModal, createTransaction }
  },
}
</script>
