<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94.092"
    height="36"
    viewBox="0 0 94.092 36"
  >
    <g id="Group_74" data-name="Group 74" transform="translate(-2947 -4858)">
      <path
        id="Path_68"
        data-name="Path 68"
        d="M417.462,334.013l.953.286a1.617,1.617,0,0,1-.3.667,1.236,1.236,0,0,1-.508.4,1.842,1.842,0,0,1-.778.143,2.31,2.31,0,0,1-.937-.159,1.451,1.451,0,0,1-.62-.588,1.956,1.956,0,0,1-.27-1.064,1.863,1.863,0,0,1,.461-1.334,1.72,1.72,0,0,1,1.3-.461,1.772,1.772,0,0,1,1.033.27,1.451,1.451,0,0,1,.556.826l-.953.206a.555.555,0,0,0-.111-.238.646.646,0,0,0-.222-.191.623.623,0,0,0-.286-.064.613.613,0,0,0-.556.3,1.287,1.287,0,0,0-.143.683,1.361,1.361,0,0,0,.175.794.605.605,0,0,0,.492.222.586.586,0,0,0,.461-.175A1.083,1.083,0,0,0,417.462,334.013Z"
        transform="translate(2593.778 4544.435)"
        fill="#225e63"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M438.9,333.611a1.655,1.655,0,0,1,1.811-1.811,1.878,1.878,0,0,1,1.35.461,1.765,1.765,0,0,1,.477,1.318,2.037,2.037,0,0,1-.207,1,1.473,1.473,0,0,1-.6.6,1.986,1.986,0,0,1-.969.222,2.268,2.268,0,0,1-.985-.191,1.536,1.536,0,0,1-.635-.6A1.8,1.8,0,0,1,438.9,333.611Zm1.08,0a1.222,1.222,0,0,0,.191.762.645.645,0,0,0,.54.238.682.682,0,0,0,.54-.222,1.319,1.319,0,0,0,.191-.81.987.987,0,0,0-.206-.715.654.654,0,0,0-.54-.222.635.635,0,0,0-.524.238A1.062,1.062,0,0,0,439.98,333.611Z"
        transform="translate(2573.622 4544.519)"
        fill="#225e63"
      />
      <path
        id="Path_70"
        data-name="Path 70"
        d="M465.132,332.2h1.414l.54,2.129.54-2.129h1.414v3.495h-.89v-2.669l-.683,2.669h-.794l-.683-2.669v2.669h-.89V332.2Z"
        transform="translate(2551.524 4544.182)"
        fill="#225e63"
      />
      <path
        id="Path_71"
        data-name="Path 71"
        d="M494.3,332.2h1.795a1.181,1.181,0,0,1,.874.286,1.057,1.057,0,0,1,.286.794,1.088,1.088,0,0,1-.318.826,1.338,1.338,0,0,1-.969.3h-.588v1.3H494.3V332.2Zm1.08,1.493h.27a.68.68,0,0,0,.445-.111.364.364,0,0,0,.127-.27.383.383,0,0,0-.111-.286.56.56,0,0,0-.413-.111h-.3v.778Z"
        transform="translate(2526.911 4544.182)"
        fill="#225e63"
      />
      <path
        id="Path_72"
        data-name="Path 72"
        d="M516.5,332.2h1.08v2.637h1.684v.858H516.5Z"
        transform="translate(2508.009 4544.182)"
        fill="#225e63"
      />
      <path
        id="Path_73"
        data-name="Path 73"
        d="M537.3,332.2h1.08v3.495H537.3Z"
        transform="translate(2490.513 4544.182)"
        fill="#225e63"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M549.294,335.023h-1.223l-.175.572h-1.1l1.318-3.495h1.175l1.318,3.495h-1.128Zm-.222-.747-.381-1.255-.381,1.255Z"
        transform="translate(2482.522 4544.267)"
        fill="#225e63"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M572.9,332.2h1l1.318,1.938V332.2h1.017v3.495h-1.017l-1.3-1.922v1.922H572.9Z"
        transform="translate(2460.568 4544.182)"
        fill="#225e63"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M596.9,332.2h3.288v.858h-1.1v2.637h-1.08v-2.637h-1.1V332.2Z"
        transform="translate(2440.38 4544.182)"
        fill="#225e63"
      />
      <path
        id="Rectangle-3"
        d="M72.886,229.96l3.129-.972L75,226.762A25.482,25.482,0,0,0,72.886,229.96Zm-1.064-10.176L65.69,206.3,20.8,208.809,33.238,242.3l23.938-7.448c-1.525-2.164-2.081-4.735-.318-5.99,1.97-1.411,4.94.22,6.83,2.524C65.515,228.377,70.646,221.368,71.822,219.784Z"
        transform="translate(2926.2 4651.7)"
        fill="#225e63"
      />
      <path
        id="Oval-1"
        d="M174.6,193.117a3.214,3.214,0,1,0-3.4-3.209A3.306,3.306,0,0,0,174.6,193.117Zm-2.78,1.382h5.56v14.534h-5.56Z"
        transform="translate(2815.334 4674.755)"
        fill="#fefefe"
      />
      <path
        id="Path-3"
        d="M83.947,238.579c.048.016.079,0,.079-.048v-3.685a.162.162,0,0,0-.079-.127,6.723,6.723,0,0,0-2.955-.6,15.435,15.435,0,0,0-2.589,0c-8.1.651-8.4,6.513-8.4,6.767v1.43c0,.175,0,6.163,8.4,6.687.826.064,2.43,0,2.589,0a8.856,8.856,0,0,0,3.272-.588.119.119,0,0,0,.079-.111v-3.447c0-.048-.032-.064-.064-.032,0,0-.6.477-3.24.747a4.7,4.7,0,0,1-1.382,0c-3.749-.635-3.924-3.368-3.924-3.368,0-.048-.016-.127-.016-.159v-1.048a.49.49,0,0,1,.016-.159s.254-2.939,3.924-3.256H81.04A8.279,8.279,0,0,1,83.947,238.579Z"
        transform="translate(2900.652 4634.676)"
        fill="#fefefe"
      />
      <path
        id="Path-1"
        d="M-27.2,250.248a.075.075,0,0,0,.079.079H-21.7a.075.075,0,0,0,.079-.079v-4.225a.075.075,0,0,1,.079-.079s8.657.62,8.657-5.178c0-4.591-5.433-5.083-7.212-5h-7.021a.075.075,0,0,0-.079.079Zm5.48-7.529v-3.8h1.334s1.922.079,2.081,1.4a3.178,3.178,0,0,1,0,.778,2.055,2.055,0,0,1-1.922,1.62Z"
        transform="translate(2982.777 4633.251)"
        fill="#fefefe"
      />
      <path
        id="Path-7"
        d="M258.252,290.319a2.859,2.859,0,0,0,1.366-.254c1.97-1.033,8.625-17.283,15.646-22.286a.483.483,0,0,0,.127-.127.232.232,0,0,0,.048-.127s0-.334-1.033-.334c-6.243-.175-12.74,12.93-16.155,18.108-.048.064-.27,0-.27,0a18.735,18.735,0,0,0-4.273-3.733,1.36,1.36,0,0,0-.508-.079,2.881,2.881,0,0,0-1.509.635,1.688,1.688,0,0,0-.492,1.493,1.1,1.1,0,0,0,.1.381,32.661,32.661,0,0,0,4.527,5.591A3.084,3.084,0,0,0,258.252,290.319Z"
        transform="translate(2731.765 4600.012)"
        fill="#2bbc5d"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M414.3,248.5h5.194a6.283,6.283,0,0,1,2.478.413,4.044,4.044,0,0,1,1.557,1.191,4.832,4.832,0,0,1,.89,1.811,8.124,8.124,0,0,1,.286,2.192,7.722,7.722,0,0,1-.413,2.812,4.659,4.659,0,0,1-1.144,1.684,3.975,3.975,0,0,1-1.573.905,8.022,8.022,0,0,1-2.081.3H414.3Zm3.495,2.557v6.179h.858a3.635,3.635,0,0,0,1.557-.238,1.684,1.684,0,0,0,.731-.842,5.311,5.311,0,0,0,.27-1.97,3.765,3.765,0,0,0-.588-2.462,2.482,2.482,0,0,0-1.954-.667Z"
        transform="translate(2594.3 4614.853)"
        fill="#225e63"
      />
      <path
        id="Path_78"
        data-name="Path 78"
        d="M485.9,254.983l3.336-.206a2.631,2.631,0,0,0,.445,1.239,1.842,1.842,0,0,0,1.541.683,1.715,1.715,0,0,0,1.16-.349,1.009,1.009,0,0,0,.032-1.6,4.4,4.4,0,0,0-1.795-.651,7.73,7.73,0,0,1-3.288-1.382,2.744,2.744,0,0,1-.985-2.192,3.013,3.013,0,0,1,.508-1.652,3.314,3.314,0,0,1,1.525-1.223,7.006,7.006,0,0,1,2.78-.445,5.634,5.634,0,0,1,3.3.81,3.479,3.479,0,0,1,1.35,2.573l-3.3.191a1.809,1.809,0,0,0-.556-1.112,1.743,1.743,0,0,0-1.16-.349,1.387,1.387,0,0,0-.921.254.789.789,0,0,0-.3.635.632.632,0,0,0,.254.492,2.916,2.916,0,0,0,1.175.413,15.312,15.312,0,0,1,3.288,1,3.451,3.451,0,0,1,1.445,1.255,3.177,3.177,0,0,1,.445,1.684,3.572,3.572,0,0,1-.6,2,3.749,3.749,0,0,1-1.684,1.4,6.8,6.8,0,0,1-2.732.477c-1.922,0-3.256-.365-4-1.112A4.519,4.519,0,0,1,485.9,254.983Z"
        transform="translate(2533.748 4615.947)"
        fill="#225e63"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        d="M556.2,254.983l3.336-.206a2.632,2.632,0,0,0,.445,1.239,1.842,1.842,0,0,0,1.541.683,1.715,1.715,0,0,0,1.16-.349,1.009,1.009,0,0,0,.032-1.6,4.4,4.4,0,0,0-1.795-.651,7.73,7.73,0,0,1-3.288-1.382,2.794,2.794,0,0,1-.985-2.192,3.013,3.013,0,0,1,.508-1.652,3.314,3.314,0,0,1,1.525-1.223,7.006,7.006,0,0,1,2.78-.445,5.634,5.634,0,0,1,3.3.81,3.479,3.479,0,0,1,1.35,2.573l-3.3.191a1.809,1.809,0,0,0-.556-1.112,1.743,1.743,0,0,0-1.16-.349,1.387,1.387,0,0,0-.921.254.789.789,0,0,0-.3.635.632.632,0,0,0,.254.492,2.915,2.915,0,0,0,1.175.413,15.312,15.312,0,0,1,3.288,1,3.45,3.45,0,0,1,1.445,1.255,3.177,3.177,0,0,1,.445,1.684,3.573,3.573,0,0,1-.6,2,3.749,3.749,0,0,1-1.684,1.4,6.8,6.8,0,0,1-2.732.477c-1.922,0-3.256-.365-4-1.112A4.357,4.357,0,0,1,556.2,254.983Z"
        transform="translate(2474.615 4615.947)"
        fill="#225e63"
      />
    </g>
  </svg>
</template>
